import { Link } from "react-router-dom";
import { Mark } from "../shared-components/Mark";
import { Img } from "./Img";
import { ImageWithText } from "../shared-components/ImageText";
import { Title } from "../shared-components/Title";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../data/DataProvider";

export const Certifications = () => {
  const { websiteMedia } = useAppContext();

  const items = [
    {
      Title: (
        <>
          <Mark>JAVER</Mark> es <Mark>ESR</Mark> por séptimo año consecutivo.
        </>
      ),
      description:
        "En JAVER el valor social se desarrolla con diferentes instrumentos de gestión sostenible. Por séptimo año consecutivo, JAVER recibió el distintivo de Empresa Socialmente Responsable (ESR), otorgado por el Centro Mexicano para la Filantropía (CEMEFI)",
      img: "https://images.monoceros-dev.com/images/certificaciones/1699568860_ESR.jpeg",
      date: "1 marzo, 2023 por Javer",
      blogUrl: "https://javer.com.mx/blog/105",
    },
    {
      Title: (
        <>
          <Mark>JAVER</Mark> es <Mark>EDGE Champion</Mark>
        </>
      ),
      description:
        "JAVER busca estar siempre vigente en temas de sustentabilidad y que puedan tener un impacto positivo para sus clientes, por ello en 2022 certificó por primera vez 2 proyectos con EDGE (Excellence in Design for Greater Efficiencies, por sus siglas en inglés). Esta certificación asegura que las viviendas cuentan con equipamiento que generan ahorros en agua y electricidad que se refleja en los recibos del usuario final, así como que los materiales empleados generaron menos emisiones en su producción.",

      img: "https://images.monoceros-dev.com/images/certificaciones/1699565837_EDGE.jpeg",
      date: "13 noviembre, 2023 por Javer",
      blogUrl: "https://www.javer.com.mx/blog/38",
    },
    {
      Title: (
        <>
          <Mark>JAVER</Mark> obtiene mención honorífica por parte de la{" "}
          <Mark>CONCAMIN</Mark>
        </>
      ),
      description:
        "En JAVER, se vive a diario la responsabilidad social a través de los valores. Por ello, fuimos reconocidos con mención honorífica la categoría de Empresas Grandes por parte de la Confederación de Cámaras Industriales (CONCAMIN) en el proceso del Premio Ética y Valores.",
      img: "https://images.monoceros-dev.com/images/certificaciones/202302131608304.jpg",
      date: "31 agosto, 2021 por Javer",
      blogUrl: "/blog",
    },
    {
      Title: (
        <>
          <Mark>JAVER</Mark> se adhiere al <Mark>UN Global Compact</Mark>
        </>
      ),
      description:
        "El Pacto Mundial es un llamado a que las empresas del mundo para que adapten sus estándares y operaciones a 10 principios universales que permiten tomar medidas sobre acciones de derechos humanos, estándares laborales, medio ambiente y anticorrupción.",
      img: "https://images.monoceros-dev.com/images/certificaciones/202302131604193.png",
      date: "31 agosto, 2021 por Javer",
      blogUrl: "https://ri.javer.com.mx/contribucion-ods",
    },
  ];
  const { t } = useTranslation("translations");
  return (
    <section className="md:m-10 xl:mx-20">
      <Title>
        {t("certifications")} <Mark>JAVER</Mark>
      </Title>
      {websiteMedia.certs_image ? (
        <ImageWithText src={websiteMedia.certs_image}>
          <Mark>Javer</Mark> {t("certificationsTitle")}
        </ImageWithText>
      ) : null}

      {items.map(({ Title, description, img, date, blogUrl }) => (
        <div key={description} className="flex items-center p-5 flex-wrap">
          <div className="md:w-1/3 w-full bg-primary flex justify-center md:justify-normal rounded-md ">
            <Img
              className=" w-full  rounded-md "
              src={img}
              alt="imageTwentySix"
            />
          </div>
          <div className="md:w-2/3 w-full text-justify p-5 ">
            <h6 className="font-bold text-center md:text-left">{Title}</h6>
            <div className="flex justify-between">
              <p className="inline-block">
                {description}
                <a
                  href={blogUrl}
                  className=" inline-block underline text-primary"
                >
                  {" " + "[Continuar leyendo]"}
                </a>
              </p>
            </div>
            <p className="my-10 text-slate-400">{date}</p>
          </div>
        </div>
      ))}
    </section>
  );
};
