import { HiEye } from "react-icons/hi";

interface ImageWithModalProps
  extends React.ImgHTMLAttributes<HTMLImageElement> {
  isHoverable?: boolean;
}

export function HoverableImage({
  className,
  isHoverable = true,
  onClick,
  ...props
}: ImageWithModalProps) {
  return (
    <div
      className={`group relative select-none ${isHoverable ? "cursor-pointer" : ""}`}
      onClick={isHoverable ? onClick : undefined}
    >
      <img className={`w-full ${className}`} {...props} />
      {isHoverable && (
        <div className="absolute inset-0 group-hover:bg-black group-hover:bg-opacity-50 flex items-center justify-center text-white transition-colors ">
          <HiEye size={32} className="group-hover:block hidden" />
        </div>
      )}
    </div>
  );
}
