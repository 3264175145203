import { type Project } from "@/models/project";
import { type Property } from "@/models/property";
import { getResourceUrl } from "@/utils/image.utils";
import { useTranslation } from "react-i18next";
import { HiPlus } from "react-icons/hi";
import { Button } from "./ui/button";

interface AditionalInfoSectionProps {
  info: Project["additional_info"] | Property["additional_info"];
}

export function AditionalInfoSection({ info }: AditionalInfoSectionProps) {
  const { i18n, t } = useTranslation("translations");

  return (
    <section className="container xl:max-w-5xl mx-auto mt-16">
      <h4>{info.title?.[i18n.language as "en" | "es"]}</h4>
      <hr className="my-6 border-primary" />

      <p className="mt-4 whitespace-pre-wrap">
        {info.description?.[i18n.language as "en" | "es"]}
      </p>
      {info.more_info_url && (
        <Button className="mt-4" asChild variant="ghost">
          <a
            href={info.more_info_url}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row items-center"
          >
            <HiPlus className="mr-2" size={24} />
            {t("seeMore")}
          </a>
        </Button>
      )}
      {info.image_ulr && (
        <img
          src={getResourceUrl(info.image_ulr)}
          alt={info.title?.[i18n.language as "en" | "es"]}
          className="w-full rounded-md mt-4"
        />
      )}
    </section>
  );
}
