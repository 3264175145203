import { useTranslation } from "react-i18next";
import "./Nosotros.css";
import { useAppContext } from "../data/DataProvider";
import { useEffect, useRef } from "react";

function Nosotros() {
  const { t } = useTranslation("translations");
  const { websiteMedia } = useAppContext();

  const mainBackgroundImgRef = useRef();

  useEffect(() => {
    if (websiteMedia.nosotros_1_image) {
      mainBackgroundImgRef.current.style.backgroundImage = `url(${websiteMedia.nosotros_1_image})`;
    }
  }, [websiteMedia]);

  return (
    <div>
      <div className="conoce-window cw0">
        <div ref={mainBackgroundImgRef} className="conoce-img-main "></div>
        <h1>{t("aboutUsTitle")}</h1>
      </div>
      <div className="conoce-window flex mb-2 -mt-28 md:mt-0 justify-center flex-wrap md:flex-nowrap">
        <img
          className="w-full md:w-1/2 object-cover"
          src={websiteMedia.nosotros_2_image}
        />
        <div className="conoce-text-ronnie p-10  w-full md:w-1/2">
          <h1 className="mb-6">
            {t("aboutUsSubtitle1")} <span>JAVER</span>
          </h1>
          <p>
            <span>JAVER</span>
            {t("aboutUs1")}
          </p>
          <br />
          <p>{t("aboutUs2")}</p>
          <br />
          <p>{t("aboutUs3")}</p>
          <br />
          <p>
            {t("aboutUs4Start")} <span>JAVER</span> {t("aboutUs4End")}
            <span>JAVER</span>
          </p>
        </div>
      </div>
      <div className="conoce-window  flex flex-wrap md:flex-nowrap">
        <img
          className="w-full md:hidden md:w-1/2 object-cover"
          src={websiteMedia.nosotros_3_image}
        />
        <div className="conoce-text-team p-10  w-full md:w-1/2">
          <h2 className="mb-6"> {t("aboutUsSubtitle2")}</h2>
          <h4 className="my-6">{t("mision")}</h4>
          <p>{t("misionContent")}</p>
          <h4 className="my-6">{t("vision")}</h4>
          <p>{t("visionContent")}</p>
          <h4 className="my-6">{t("values")}</h4>
          <p className="whitespace-pre-line">{t("valuesContent")}</p>
        </div>

        <img
          className="w-full hidden md:block md:w-1/2 object-cover"
          src="https://images.monoceros-dev.com/images/website/nosotros-3.jpeg"
        />
        {/* <div className="conoce-image-final w-full md:w-1/2"></div> */}
      </div>
    </div>
  );
}

export default Nosotros;
