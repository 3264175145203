import { useQuery } from "react-query";
import { getBlogArticles } from "../../utils/api";
import { Img } from "../Img";
import { Link, useParams } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import { Title } from "../../shared-components/Title";

const blogSections = {
  "hogar-javer": "Hogar Javer",
  noticias: "Noticias",
  responsabilidad: "Responsabilidad",
  finanzas: "Finanzas",
  archivo: "Archivo",
};
export const BlogList = () => {
  const { data, isLoading } = useQuery("blogs", getBlogArticles);
  const { section } = useParams();
  return (
    <div className="m-20 min-h-[60vh]">
      <Title className="text-neutral-400">{blogSections[section]}</Title>
      {!data?.filter((v) =>
        section === "archivo" ? v : v.section === blogSections[section]
      ).length && !isLoading ? (
        <p className="text-center   text-neutral-400">
          No se encontraron resultados
        </p>
      ) : (
        <div>
          {data
            ?.filter((v) =>
              section === "archivo" ? v : v.section === blogSections[section]
            )
            .map(({ id, title, content, image, date }) => (
              <Link
                key={title}
                to={"/blog/" + id}
                className="flex items-center  border-t-2 border-gray-100 flex-wrap"
              >
                <div className="md:w-2/3 w-full  p-5 ">
                  <h6 className="font-bold text-lg md:text-xl text-center md:text-left">
                    {title}
                  </h6>
                  <div className="flex justify-between">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: content.slice(0, 300),
                      }}
                    />
                  </div>
                  <p className="my-10 text-slate-400">{formatDate(date)}</p>
                </div>
                <div className="md:w-1/3 w-full bg-primary flex justify-center md:justify-normal rounded-md ">
                  <Img
                    className=" w-full  rounded-md "
                    src={image}
                    alt="imageTwentySix"
                  />
                </div>
              </Link>
            ))}
        </div>
      )}
    </div>
  );
};
