import { useQuery } from "react-query";
import { getBlogByID } from "../../utils/api";
import { useParams } from "react-router-dom";
import { BlogMenu } from "./BlogMenu";
import { Img } from "../Img";
import { Title } from "../../shared-components/Title";
import { formatDate } from "../../utils/formatDate";
import "./BlogArticle.css";

const BlogArticle = () => {
  const { id } = useParams();
  const { data } = useQuery("blog", async () => await getBlogByID(id));
  return (
    <div className="p-5 text-sm">
      <div className="h-[740px] md:h-[793px] relative w-full">
        <div className="sm:h-[740px] h-[793px] md:h-[828px] m-auto w-full">
          <div className="absolute flex flex-col inset-x-[0] items-center justify-start mx-auto top-[0] w-full">
            <div className="relative w-full">
              <Img
                className="h-[740px] m-auto object-cover rounded-lg w-full"
                src={data?.post_image1}
                alt="rectangleSixtyNine"
              />
              <BlogMenu />
              <div className="absolute bottom-48 w-full p-5  flex flex-col items-center justify-center ">
                <Title className="!text-gray-50">{data?.post_title}</Title>
                <section className="flex text-gray-50 gap-1">
                  <p>{formatDate(data?.post_date) + " | "} </p>
                  <p>
                    {data?.post_author.length <= 1
                      ? " Por Javer"
                      : data?.post_author}
                  </p>
                  {data?.post_section && <p>{data?.post_section}</p>}
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex my-5 m-5 md:mx-20 gap-5 flex-col md:flex-row items-center text-justify">
        <div
          className="blog-article-styles"
          dangerouslySetInnerHTML={{ __html: data?.post_content_filtered }}
        />
        {data?.post_image2 && (
          <Img
            className="w-full md:w-1/3 rounded-md h-auto p-5"
            src={data?.post_image2}
          />
        )}
      </div>
    </div>
  );
};

export default BlogArticle;
