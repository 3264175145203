import { useEffect, useRef, useState } from "react";
import { useAppContext } from "../data/DataProvider";
import "./Lotes.css";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";
import { postLotesForm } from "../utils/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const initialFormData = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  company: "",
  state: "",
  squareMeters: "",
  message: "",
  acceptPolicy: false,
  recaptcha: "",
};

function Lotes() {
  const { dataDesarrollos, dataAuth, websiteMedia } = useAppContext();

  const [form, setForm] = useState(initialFormData);

  const navigate = useNavigate();
  const titleRef = useRef();

  const [openMenuStates, setOpenMenuStates] = useState(false);
  const [openMenuMeters, setOpenMenuMeters] = useState(false);

  const handleChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setForm({
      ...form,
      [e.target.name]: value,
    });
  };

  const onChangeRecaptcha = (value) => {
    setForm((prevState) => ({
      ...prevState,
      recaptcha: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dataParse = {
      first_name: form.firstName,
      last_name: form.lastName,
      email: form.email,
      phone: form.phone,
      company: form.company,
      state: form.state,
      square_meters: form.squareMeters,
      message: form.message,
      recaptchaToken: form.recaptcha,
    };

    postLotesForm(dataParse)
      .then(() => {
        toast.success("Formulario enviado");
        setForm(initialFormData);
        navigate("/");
      })
      .catch(() => {
        toast.success("Error al enviar el formulario");
      });
  };

  const { t } = useTranslation("translations");

  const metrosCuadrados = [
    "Desde 100 a 500 m2",
    "Desde 501 a 1000 m2",
    "Desde 1001 a 5000 m2",
    "Más de 1000 m2",
  ];

  useEffect(() => {
    if (dataAuth.firstName && dataAuth.lastName && dataAuth.email) {
      setForm((prevState) => ({
        ...prevState,
        firstName: dataAuth.firstName,
        lastName: dataAuth.lastName,
        email: dataAuth.email,
        phone: dataAuth.phone,
      }));
    }
  }, [dataAuth]);

  useEffect(() => {
    if (websiteMedia.lotes_image) {
      titleRef.current.style.backgroundImage = `url(${websiteMedia.lotes_image}`;
      titleRef.current.style.color = "#FFF";
    }
  }, [websiteMedia]);

  return (
    <form className="contacto-outer">
      <div ref={titleRef} className="lotes-title-image">
        <div className="lotes-title-text py-16">
          <h4>{t("plotSale")}</h4>
          <h6 className="mt-4">{t("plotSaleSubtitle")}</h6>
        </div>
      </div>

      <div className="contacto-form">
        <div className="contacto-row">
          <div className="contacto-input-col">
            <label>{t("names")}</label>
            <input
              type="text"
              disabled={dataAuth.firstName}
              name="firstName"
              value={form.firstName}
              required
              onChange={handleChange}
            ></input>
          </div>
          <div className="contacto-input-col">
            <label>{t("lastNames")}</label>
            <input
              type="text"
              disabled={dataAuth.lastName}
              name="lastName"
              value={form.lastName}
              required
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className="contacto-row">
          <div className="contacto-input-col">
            <label>{t("email")}</label>
            <input
              type="email"
              disabled={dataAuth.email}
              name="email"
              value={form.email}
              required
              onChange={handleChange}
            ></input>
          </div>
        </div>
        <div className="contacto-row">
          <div className="contacto-input-col">
            <label>{t("phone")}</label>
            <input
              type="tel"
              name="phone"
              required
              onChange={handleChange}
              value={form.phone}
            ></input>
          </div>
          <div className="contacto-input-col">
            <label>{t("company")}</label>
            <input
              type="text"
              name="company"
              required
              onChange={handleChange}
              value={form.company}
            ></input>
          </div>
        </div>
        <div className="contacto-row">
          <div className="contacto-input-col">
            <label>{t("states")}</label>
            <div
              className="form-select-dropdown"
              onClick={() => {
                setOpenMenuStates((prevState) => !prevState);
              }}
            >
              {form.state || t("selectOption")}
              {openMenuStates && (
                <div
                  className="dropdown"
                  onMouseLeave={() => {
                    setOpenMenuStates(false);
                  }}
                >
                  {dataDesarrollos.map(({ name }, index) => {
                    return (
                      <div
                        className="dropdown-item"
                        key={index}
                        onClick={() => {
                          setForm({
                            ...form,
                            state: name,
                          });
                          setOpenMenuMeters(false);
                        }}
                      >
                        {name}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          <div className="contacto-input-col">
            <label>{t("squareMeters")}</label>
            <div
              className="form-select-dropdown"
              onClick={() => {
                setOpenMenuMeters((prevState) => !prevState);
              }}
            >
              {form.squareMeters || t("selectOption")}
              {openMenuMeters && (
                <div
                  className="dropdown"
                  onMouseLeave={() => {
                    setOpenMenuMeters(false);
                  }}
                >
                  {metrosCuadrados.map((item, index) => {
                    return (
                      <div
                        className="dropdown-item"
                        key={index}
                        onClick={() => {
                          setForm({
                            ...form,
                            squareMeters: item,
                          });
                          setOpenMenuMeters(false);
                        }}
                      >
                        {item}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="contacto-row">
          <div className="contacto-input-col">
            <label>{t("message")}</label>
            <textarea
              name="message"
              placeholder={t("messageExample")}
              required
              value={form.message}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
        <div className="contacto-row cor2">
          <input
            type="checkbox"
            name="acceptPolicy"
            required
            checked={form.acceptPolicy}
            onChange={handleChange}
          />
          <div
            style={{ cursor: "pointer" }}
            onClick={() => {
              setForm((prevState) => ({
                ...prevState,
                acceptPolicy: !prevState.acceptPolicy,
              }));
            }}
          >
            {t("acceptPolicies")}
          </div>
          <a className="adp">{t("privacyNotices")}</a>
        </div>
        <div className="contacto-row">
          <div className="contacto-col">
            <ReCAPTCHA
              sitekey="6Lcg2vQZAAAAAHpqmf6Pj_-t-doxKr1iwLWfQuWQ"
              onChange={onChangeRecaptcha}
            />
            <button
              onClick={handleSubmit}
              className="enviar-button"
              disabled={
                !form.firstName ||
                !form.lastName ||
                !form.email ||
                !form.message ||
                !form.acceptPolicy ||
                !form.recaptcha
              }
            >
              {t("submit")}
            </button>
          </div>
        </div>
        <div className="lotes-row-info">
          <p>
            <span>JAVER</span> {t("plotSaleDetails")}
          </p>
          <p>
            {t("callUs")}: (81) 1133.6699 ext. 6871 {t("emailUs")}:
            lotescomerciales@javer.com.mx
            <br />
            {t("contactConsultant")}: (81) 1660.6967
          </p>
        </div>
      </div>
    </form>
  );
}

export default Lotes;
