import { useFormik } from "formik";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import * as yup from "yup";
import { useMutation } from "react-query";
import { postCustomerServiceReport } from "@/utils/api";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export const ComplaintsForm = () => {
  const { t } = useTranslation("translations");

  const validationSchema = yup.object().shape({
    first_name: yup.string().required("Campo requerido"),
    last_name: yup.string().required("Campo requerido"),
    email: yup
      .string()
      .email("Correo electrónico inválido")
      .required("Campo requerido"),
    phone: yup.string().required("Campo requerido"),
    acquired_subdivision: yup.string().required("Campo requerido"),
    street_address: yup.string().required("Campo requerido"),
    block: yup.string().required("Campo requerido"),
    lot: yup.string().required("Campo requerido"),
    subject: yup.string().required("Campo requerido"),
    message: yup
      .string()
      .min(200, "Mensaje muy corto")
      .required("Campo requerido"),
    accept: yup
      .boolean()
      .oneOf([true], "Debes aceptar las políticas de privacidad"),
  });

  const {
    getFieldProps,
    handleSubmit,
    errors,
    isValid,
    values,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      acquired_subdivision: "",
      street_address: "",
      block: "",
      lot: "",
      subject: "",
      message: "",
      accept: false,
    },
    validationSchema,
    onSubmit: (values) => {
      const { accept, ...rest } = values;
      mutate(rest);
    },
  });

  const { mutate, isLoading } = useMutation("complaints", {
    mutationFn: postCustomerServiceReport,
    onSuccess: () => {
      toast.success("Reporte enviado correctamente");
      resetForm();
    },
    onError: () => {
      toast.error("Ocurrió un error al enviar el reporte");
    },
  });

  return (
    <div className="container max-w-4xl my-16">
      <h4 className="text-primary self-start">{t("makeReport")}</h4>
      <p>{t("makeReportDescription")}</p>
      <form
        className="grid grid-cols-1 md:grid-cols-2 gap-4 mt-8 md:mx-16"
        onSubmit={handleSubmit}
      >
        <div>
          <Label>{t("names")}</Label>
          <Input {...getFieldProps("first_name")} error={errors.first_name} />
        </div>
        <div>
          <Label>{t("lastNames")}</Label>
          <Input {...getFieldProps("last_name")} error={errors.last_name} />
        </div>
        <div>
          <Label>{t("email")}</Label>
          <Input {...getFieldProps("email")} error={errors.email} />
        </div>
        <div>
          <Label>{t("phone")}</Label>
          <Input {...getFieldProps("phone")} error={errors.phone} />
        </div>
        <div>
          <Label>{t("fractioning")}</Label>
          <Input
            {...getFieldProps("acquired_subdivision")}
            error={errors.acquired_subdivision}
          />
        </div>
        <div>
          <Label>{t("streetAndNumber")}</Label>
          <Input
            {...getFieldProps("street_address")}
            error={errors.street_address}
          />
        </div>
        <div>
          <Label>{t("block")}</Label>
          <Input {...getFieldProps("block")} error={errors.block} />
        </div>
        <div>
          <Label>{t("lot")}</Label>
          <Input {...getFieldProps("lot")} error={errors.lot} />
        </div>
        <div className="md:col-span-2">
          <Label>{t("subject")}</Label>
          <Input {...getFieldProps("subject")} error={errors.subject} />
        </div>
        <div className="md:col-span-2">
          <Label>{t("message")}</Label>
          <Textarea rows={10} minLength={200} {...getFieldProps("message")} />
          <p className={`text-sm ${errors.message ? "text-red-500" : ""}`}>
            {errors.message
              ? errors.message
              : `${values.message.length} caracteres`}
          </p>
        </div>

        <div className="flex justify-between mt-5 items-center w-full md:col-span-2">
          <div className="flex gap-2 justify-center items-center">
            <Checkbox
              checked={values.accept}
              onCheckedChange={(e) => {
                setFieldValue("accept", e);
              }}
            />
            <p>
              {t("accept")}{" "}
              <a
                href="/avisos-de-privacidad"
                className="text-primary"
                target="_blank"
                rel="noreferrer"
              >
                {t("privacyNotices")}
              </a>
            </p>
          </div>
        </div>

        <div className="flex w-full md:col-span-2">
          <Button
            className="w-32"
            type="submit"
            disabled={!isValid}
            isLoading={isLoading}
          >
            {t("submit")}
          </Button>
        </div>
      </form>
    </div>
  );
};
