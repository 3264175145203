import { Img } from "../views/Img";

interface ImageWithTextProps {
  src: string;
  children: React.ReactNode;
}

export const ImageWithText = ({ src, children }: ImageWithTextProps) => {
  return (
    <div className="relative m-5">
      <Img
        className="brightness-50 object-cover h-[500px] rounded-lg w-full"
        src={src}
        alt="imageTwentySix"
      />
      <h4 className="absolute brightness-100 text-2xl md:text-4xl text-gray-50 font-sans bottom-8 font-bold left-5 uppercase">
        {children}
      </h4>
    </div>
  );
};
