import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { HiOutlineDownload } from "react-icons/hi";
import { HiOutlineExclamationTriangle } from "react-icons/hi2";

const CodeOfEthicsPage = () => {
  const { t } = useTranslation("translations");
  const [isAccepted, setIsAccepted] = useState(false);

  return (
    <div className="container py-16 flex flex-col gap-8">
      <h3 className="text-primary">{t("codeOfEthics")}</h3>
      <p>{t("codeOfEthicsPageDescription")}</p>

      <Button className="self-center">
        <a
          href="/archivos/CodigoDeEticaJAVER2020_83cd.pdf"
          target="_blank"
          rel="noreferrer"
          className="flex gap-2"
        >
          <HiOutlineDownload size={20} />
          {t("downloadCodeOfEthics")}
        </a>
      </Button>

      <Alert variant="destructive">
        <HiOutlineExclamationTriangle size={24} className="text-primary" />
        <AlertTitle>{t("important")}</AlertTitle>
        <AlertDescription>{t("codeOfEthicsNote")}</AlertDescription>
      </Alert>

      <div className="flex gap-2 self-center">
        <Checkbox
          checked={isAccepted}
          onCheckedChange={(value) => {
            setIsAccepted(Boolean(value));
          }}
        />
        <span className="text-sm">{t("codeOfEthicsCheckboxMessage")}</span>
      </div>

      <Button className="self-center" disabled={!isAccepted}>
        <a href="mailto:jperez@javer.com.mx?subject=Adjuntar%20documento%20con%20la%20Firma%20del%20C%C3%B3digo%20de%20%C3%A9tica%20Javer">
          {t("sendCodeOfEthics")}
        </a>
      </Button>
    </div>
  );
};

export default CodeOfEthicsPage;
