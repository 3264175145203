interface WazeLogoProps {
  size?: number;
  className?: string;
}

export function WazeLogo({ size = 24, className }: WazeLogoProps) {
  const aspectRatio = 122.71 / 122.88;

  const width = size;
  const height = width / aspectRatio;

  return (
    <svg
      className={className}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        background: "new 0 0 122.71 122.88",
      }}
      viewBox="0 0 122.71 122.88"
    >
      <path
        d="M55.14 104.21c4.22 0 8.44.19 12.66-.09 3.84-.19 7.88-.56 11.63-1.5 29.82-7.31 45.76-40.23 32.72-68.07C104.27 17.76 90.77 8.19 72.3 6.22 58.14 4.72 45.48 8.94 34.79 18.5c-10.5 9.47-15.94 21.28-16.31 35.44-.09 3.28 0 6.66 0 9.94-.1 7.14-4.13 12.67-10.98 14.82-.09 0-.28.19-.38.19 2.63 6.94 13.31 17.16 19.97 19.69 8.36-11.44 25.23-7.4 28.05 5.63z"
        style={{
          fill: "#fff",
        }}
      />
      <path d="M54.95 110.49c-1.03 4.69-3.56 8.16-7.69 10.31-5.25 2.72-10.6 2.63-15.57-.56-5.16-3.28-7.41-8.25-7.03-14.35.09-1.03-.19-1.41-1.03-1.88-9.1-4.78-16.31-11.44-21.28-20.44-.94-1.78-1.69-3.66-2.16-5.63-.66-2.72.38-4.03 3.19-4.31 3.38-.38 6.38-1.69 7.88-4.88.66-1.41 1.03-3.09 1.03-4.69.19-4.03 0-8.06.19-12.1 1.03-15.57 7.5-28.5 19.32-38.63C42.67 3.97 55.42-.43 69.76.03c25.04.94 46.51 18.57 51.57 43.23 4.59 22.32-2.34 40.98-20.07 55.51-1.03.84-2.16 1.69-3.38 2.44-.66.47-.84.84-.56 1.59 2.34 7.13-.94 15-7.5 18.38-8.91 4.41-19.22-.09-21.94-9.66-.09-.38-.56-.84-.84-.84-3.93-.28-7.97-.19-12.09-.19zm.19-6.28c4.22 0 8.44.19 12.66-.09 3.84-.19 7.88-.56 11.63-1.5 29.82-7.31 45.76-40.23 32.72-68.07C104.27 17.76 90.77 8.19 72.3 6.22 58.14 4.72 45.48 8.94 34.79 18.5c-10.5 9.47-15.94 21.28-16.31 35.44-.09 3.28 0 6.66 0 9.94-.1 7.14-4.13 12.67-10.98 14.82-.09 0-.28.19-.38.19 2.63 6.94 13.31 17.16 19.97 19.69 8.36-11.44 25.23-7.4 28.05 5.63z" />
      <path d="M74.92 79.74c-11.07-.56-18.38-4.97-23.07-13.78-1.13-2.16-.09-4.31 2.06-4.78 1.31-.28 2.53.66 3.47 2.16 1.22 1.88 2.44 3.75 4.03 5.25 8.81 8.34 23.25 5.72 28.79-5.06.66-1.31 1.5-2.34 3.09-2.34 2.34.09 3.66 2.44 2.63 4.59-2.91 5.91-7.5 10.22-13.69 12.28-2.72.93-5.53 1.3-7.31 1.68zM55.32 48.98c-3.38 0-6.09-2.72-6.09-6.09s2.72-6.09 6.09-6.09 6.09 2.72 6.09 6.09c.01 3.28-2.71 6.09-6.09 6.09zM98.27 42.79c0 3.38-2.72 6.09-6 6.19-3.38 0-6.09-2.63-6.09-6.09a6.15 6.15 0 0 1 6-6.19c3.28 0 5.99 2.72 6.09 6.09z" />
    </svg>
  );
}
