import { useParams } from "react-router-dom";
import { ProveedoresNotice } from "./Proveedores";
import { ClientesCasasJaver } from "./Clientes/ClientesCasasJaver";
import { ClientesQueretaro } from "./Clientes/ClientesQueretaro";
import { ClientesMexico } from "./Clientes/ClientesMexico";
import { ClientesServiciosCorporativos } from "./Clientes/ClientesServiciosCorporativos";
import { ClientesInmuebles } from "./Clientes/ClientesInmuebles";
import { EmpleadosCasasJaver } from "./Empleados/EmpleadosCasasJaver";
import { EmpleadosQueretaro } from "./Empleados/EmpleadosQueretaro";
import { EmpleadosMexico } from "./Empleados/EmpleadosMexico";
import { EmpleadosServiciosCorporativos } from "./Empleados/EmpleadosServiciosCorporativos";
import { EmpleadosInmuebles } from "./Empleados/EmpleadosInmuebles";
import { EmpleadosNoreste } from "./Empleados/EmpleadosNoreste";

const privacyNotices = {
  // clientes
  "aviso-clientes-casas-javer": <ClientesCasasJaver />,
  "aviso-clientes-queretaro": <ClientesQueretaro />,
  "aviso-clientes-mexico": <ClientesMexico />,
  "aviso-clientes-servicios-corporativos": <ClientesServiciosCorporativos />,
  "aviso-clientes-inmuebles": <ClientesInmuebles />,
  // empleados
  "aviso-empleados-casas-javer": <EmpleadosCasasJaver />,
  "aviso-empleados-queretaro": <EmpleadosQueretaro />,
  "aviso-empleados-mexico": <EmpleadosMexico />,
  "aviso-empleados-servicios-corporativos": <EmpleadosServiciosCorporativos />,
  "aviso-empleados-inmuebles": <EmpleadosInmuebles />,
  "aviso-empleados-noreste": <EmpleadosNoreste />,
  // proveedores

  "aviso-proveedores": <ProveedoresNotice />,
};

export const PrivacyNoticeInterior = () => {
  const { title } = useParams();
  return <div className="m-10 md:m-40">{privacyNotices[title]}</div>;
};
