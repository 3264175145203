import { BrowserRouter, Route, Routes } from "react-router-dom";
import { DataProvider } from "./data/DataProvider";
import LoginModal from "./root-components/LoginModal";
import TopBar from "./root-components/TopBar";
import Nosotros from "./views/Nosotros";
import Contacto from "./views/Contacto";
import CreditSimulator from "./views/CreditSimulator";
import Equipo from "./views/Equipo";
import Footer from "./views/Footer";
import Home from "./views/Home";
import Lotes from "./views/Lotes";
import { ToastContainer } from "react-toastify";
import HousingDevelopmentPage from "./pages/housing-development/HousingDevelopmentPage";
import Blog from "./views/Blog/Blog";
import BlogArticle from "./views/Blog/BlogArticle";
import { Certifications } from "./views/Certifications";
import { PrivacyNotice } from "./views/PrivacyNotice";
import { Contracts } from "./views/Contracts";
import { Decalogues } from "./views/Decalogues";
import { CustomerService } from "./views/CustomerService";
import { CustomerServiceDashboard } from "./views/CustomerServiceDashboard/Dashboard";
import { ReservasTerritoriales } from "./views/ReservasTerritoriales/ReservasTerritoriales";
import Error500Page from "./error-pages/500";
import Error400Page from "./error-pages/400";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "./App.css";
import "react-toastify/dist/ReactToastify.min.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { BlogList } from "./views/Blog/BlogList";
import { Wrapper } from "./root-components/Wrapper";
import { PrivacyNoticeInterior } from "./views/PrivacyNotice/PrivacyNoticeInterior";
import { DecalogoNoDiscriminacion } from "./views/Decalogos/NoDiscriminacion";
import PropertyPage from "./pages/housing-development/property/PropertyPage";
import StatesPage from "./pages/states/StatesPage";
import { APIProvider } from "@vis.gl/react-google-maps";
import { GOOGLE_MAPS_KEY } from "./constants";
import { ThanksPage } from "./pages/thanks/ThanksPage";
import CodeOfEthicsPage from "./pages/code-of-ethics/CodeOfEthicsPage";
import { WhatsappFAB } from "./components/WhatsappFAB";

const queryClient = new QueryClient();

function App() {
  return (
    <DataProvider>
      <APIProvider apiKey={GOOGLE_MAPS_KEY ?? ""}>
        <div className="app-container">
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <WhatsappFAB />
              <Wrapper>
                <TopBar />
                <Routes>
                  <Route path="/" element={<Home />} />
                  <Route
                    path="/desarrollos/:name"
                    element={<HousingDevelopmentPage />}
                  />
                  <Route path="/estados/:name" element={<StatesPage />} />
                  <Route
                    path="/desarrollos/:projectName/propiedad/:name"
                    element={<PropertyPage />}
                  />
                  <Route path="/credit" element={<CreditSimulator />} />
                  <Route path="/nosotros" element={<Nosotros />} />
                  <Route path="/contacto" element={<Contacto />} />
                  <Route
                    path="/codigo-de-etica"
                    element={<CodeOfEthicsPage />}
                  />
                  <Route path="/equipo" element={<Equipo />} />
                  <Route path="/lotes" element={<Lotes />} />
                  <Route path="/blog" element={<Blog />} />
                  <Route path="/blog-list/:section" element={<BlogList />} />
                  <Route path="/blog/:id" element={<BlogArticle />} />
                  <Route path="/certificaciones" element={<Certifications />} />
                  <Route
                    path="/avisos-de-privacidad"
                    element={<PrivacyNotice />}
                  />
                  <Route
                    path="/avisos-de-privacidad/:title"
                    element={<PrivacyNoticeInterior />}
                  />
                  <Route
                    path="/contratos-de-adhesion"
                    element={<Contracts />}
                  />
                  <Route path="/decalogos" element={<Decalogues />} />
                  <Route
                    path="/decalogo-de-no-discriminacion/"
                    element={<DecalogoNoDiscriminacion />}
                  />
                  <Route
                    path="/servicio-a-clientes"
                    element={<CustomerService />}
                  />
                  <Route
                    path="/reservas-territoriales"
                    element={<ReservasTerritoriales />}
                  />
                  <Route
                    path="/servicio-a-clientes-dashboard"
                    element={<CustomerServiceDashboard />}
                  />
                  <Route path="/gracias" element={<ThanksPage />} />
                  <Route path="/500" element={<Error500Page />} />
                  <Route path="*" element={<Error400Page />} />
                </Routes>
                <Footer />
              </Wrapper>
            </BrowserRouter>
          </QueryClientProvider>
        </div>
        <LoginModal />
        <ToastContainer
          position="bottom-left"
          theme="light"
          progressStyle={{ background: "#cd1019" }}
        />
      </APIProvider>
    </DataProvider>
  );
}

export default App;
