import PropTypes from "prop-types";
import { FaHeart } from "react-icons/fa";

import { useAppContext } from "../data/DataProvider";
import "./Favourites.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { toUrlCase } from "../utils/common.utils";

function FavoriteItem({ name, city, state, img, status, id, short_name }) {
  const { setFavoriteProperty } = useAppContext();
  const [isClickable, setIsClickable] = useState(true);

  return (
    <div className={`favourite-item${isClickable ? "" : " disable"}`}>
      <Link
        to={
          "/desarrollos/" +
          toUrlCase(short_name) +
          "/propiedad/" +
          toUrlCase(name)
        }
      >
        <div className="favourite-thumbnail">
          <img src={img} alt={`Property ${name}`} />
        </div>
        <div className="favourite-thumb-row">
          <div className="favourite-thumb-text-col">
            <h2>{name}</h2>
            <p>{`${city}, ${state}`}</p>
          </div>
          {/* <div className="favourite-thumb-dots"> */}
          {/*   <div></div> */}
          {/*   <div></div> */}
          {/*   <div></div> */}
          {/* </div> */}
        </div>
      </Link>
      <div className="favourite-thumb-row ftrow-2">
        <div className="favourite-status">
          {status ? "Disponible" : "Proximamente"}
        </div>
        <FaHeart
          className={isClickable ? "active-favorite" : ""}
          onClick={async () => {
            setIsClickable(false);
            setTimeout(() => {
              setFavoriteProperty(id);
            }, 1000);
          }}
        />
      </div>
    </div>
  );
}

FavoriteItem.propTypes = {
  name: PropTypes.string.isRequired,
  city: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  img: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
};

function Favorites({ onClose }) {
  const { dataAuth, favoritesData } = useAppContext();

  return (
    <div className="favourites-outer">
      <div className="favourites-top-bar">
        <div className="favourites-title">FAVORITOS</div>
        <div className="favourites-close" onClick={onClose}></div>
      </div>
      <div className="favourites-scroller">
        {!dataAuth.token ? (
          <p className="placeholder-login-favorites">
            Inicie sesión para ver sus favoritos
          </p>
        ) : favoritesData.error || favoritesData.list.length === 0 ? (
          <p className="placeholder-login-favorites">
            {favoritesData.error
              ? favoritesData.error
              : "Su lista de favoritos esta vacia"}
          </p>
        ) : (
          favoritesData.list.map((data) => (
            <FavoriteItem
              key={`id-fav-${data.id}`}
              name={data.name}
              city={data.city_name}
              state={data.state_name}
              status={data.delivery_status}
              img={data.main_image}
              id={data.id}
              short_name={data.project_short_name}
            />
          ))
        )}
      </div>
    </div>
  );
}

Favorites.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Favorites;
