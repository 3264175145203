import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { type DayPickerSingleProps } from "react-day-picker";
import { es, enUS } from "date-fns/locale";
import { useTranslation } from "react-i18next";

export function SingleDatePicker(props: Omit<DayPickerSingleProps, "mode">) {
  const { i18n } = useTranslation("translations");

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn(
            "w-full text-foreground bg-neutral-100 justify-start text-left font-normal border-input hover:bg-gray-50 hover:text-primary flex items-center",
            !props.selected && "text-muted-foreground"
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4 text-primary" />
          {props.selected ? (
            format(props.selected, "PPP", {
              locale: i18n.language === "es" ? es : enUS,
            })
          ) : (
            <span></span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar captionLayout="dropdown" mode="single" {...props} />
      </PopoverContent>
    </Popover>
  );
}
