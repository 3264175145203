import { AvisosTitle } from "./AvisosTitle";

export const ProveedoresNotice = () => {
  return (
    <div>
      <h1 className="text-center underline font-bold mb-10">
        Servicios Corporativos Javer, S.A.B. de C.V
      </h1>
      <p className="text-right my-10">Fecha de actualización:01/Julio/2021</p>

      <p>
        <b>
          Cumpliendo con lo establecido en la Ley Federal de Protección de Datos
          Personales en Posesión de Particulares, en adelante, la “Ley”;
          Servicios Corporativos Javer, S.A.B. de C.V.  sus filiales y
          subsidiarias, en adelante
        </b>{" "}
        (“Javer”), con domicilio en 16 de septiembre #301, entre Hidalgo y
        Corregidora, Colonia Centro, San Pedro Garza García, N.L, C.P. 66230, es
        responsable del uso, tratamiento y protección de la información y datos
        personales que recabe de usted o de aquellos a los que tuviere acceso
        por cualquier medio. Si usted no manifiesta su negativa para el
        tratamiento de sus datos, en el formato habilitado en este aviso para
        ejercer sus derechos ARCO, entenderemos que ha otorgado su
        consentimiento.
      </p>
      <br />

      <p>
        Es importante señalar que aún de que la “Ley” prevé sus excepciones
        respecto al otorgamiento del consentimiento y disposición del aviso de
        privacidad, tal es el caso de la captura de datos de representación y
        contacto de las personas morales y/o físicas que presten servicios y/o
        comerciantes y profesionistas con los que “Javer” mantiene una relación
        jurídica, ésta provee este aviso de privacidad como una buena práctica.
      </p>

      <AvisosTitle>Datos recabados</AvisosTitle>
      <p>
        Los datos que “Javer” puede recabar por medios electrónicos, impresos,
        de manera directa y/o personal, son los siguientes:
      </p>
      <ul className="list-disc">
        <li>
          Cédula de Inscripción ante el Registro Federal del Contribuyente y/o
          factura electrónica.
        </li>

        <li>Estado de cuenta reciente (3 meses de antigüedad).</li>
        <li>Carta de no parentesco con personal de Javer.</li>
        <li>
          Carta compromiso con el código de ética, para proveedores,
          contratistas y/o personal externo que prestan sus servicios en las
          instalaciones o durante las operaciones con Javer.
        </li>
        <li>Identificación oficial (INE o Pasaporte) del representante.</li>
        <li>Comprobante de domicilio reciente.</li>
        <li>Poder del representante legal.</li>
        <li>Alta del seguro social como patrón.</li>
        <li>Acta constitutiva y sus estatutos vigentes.</li>
        <li>
          Cuenta Estatal ISN. (Sólo contratistas para los estados de Nuevo León
          y Jalisco).
        </li>

        <li>CLABE interbancaria.</li>
        <li>Datos de contacto: nombre, teléfono y correo electrónico.</li>
      </ul>

      <AvisosTitle>Finalidades del tratamiento de los datos</AvisosTitle>
      <p>
        “Javer” podrá dar tratamiento a los datos antes señalados para las
        siguientes finalidades primarias, mismas que dan origen y son necesarias
        para la relación jurídica entre “Javer” y el titular.
      </p>
      <ul className="list-disc">
        <li>Adquisición de terrenos, análisis técnicos y antecedentes.</li>

        <li>
          Arrendamientos, comisiones, solicitud de fianzas y créditos,
          prestación de servicios.
        </li>
        <li>Contratación de mano de obra y/o materiales.</li>
        <li>Seguimiento a la relación con proveedores.</li>
        <li>
          Procesos administrativos como devoluciones, facturaciones, históricos
          de compras, procesamiento de solicitudes, cobro, aclaraciones,
          investigación.
        </li>
        <li>
          Control y realización de los procesos de alta, baja o cambio de
          proveedores.
        </li>
      </ul>

      <AvisosTitle>Transferencia de datos personales</AvisosTitle>
      <p>
        “Javer” podrá, para las finalidades ya citadas, transferir sus datos a
        “encargados” en términos de la Ley en la materia, a terceros mexicanos o
        extranjeros en los que se apoye para su operación, y a terceros del
        sector inmobiliario, construcción, así como a sus subsidiarias,
        afiliadas y controladora.
      </p>

      <AvisosTitle>
        Acceso, rectificación o cancelación de sus datos personales u oposición
        para su uso.{" "}
      </AvisosTitle>
      <p>Como titular de los datos, usted tiene el derecho de:</p>
      <ol type="i">
        <li>
          Acceso a sus datos y así conocer los detalles del tratamiento de los
          mismos,
        </li>

        <li>Rectificación en caso de que sean inexactos o incompletos,</li>
        <li>
          Cancelación cuando considere que no se requieren para alguna de las
          finalidades señaladas, estén siendo utilizados para finalidades no
          consentidas o haya finalizado la relación contractual o de servicio,
        </li>

        <li>
          Oposición al tratamiento de los mismos para fines específicos
          (conjuntamente, los “Derechos ARCO”).
        </li>
      </ol>
      <p>
        Para ejercer sus derechos ARCO, usted deberá enviar una solicitud, misma
        que puede descargar aquí en atención al oficial de privacidad, a la
        siguiente dirección de correo derechosarco@javer.com.mx
      </p>

      <p>
        Le informamos que el ejercicio de los derechos ARCO será gratuito, pero
        si usted reitera su solicitud en un periodo menor a doce meses, tendrá
        el costo definido por Ley, a menos que existan modificaciones
        sustanciales al aviso de privacidad, que motiven nuevas solicitudes
        ARCO. Usted deberá cubrir los gastos justificados de envío o el costo de
        reproducción en copias u otros formatos y, en su caso, el costo de la
        certificación de documentos.
      </p>

      <AvisosTitle>
        Medios para revocar el consentimiento para el tratamiento de datos
        personales.
      </AvisosTitle>
      <p>
        Es importante que usted conozca que puede revocar su consentimiento para
        el tratamiento de sus datos de la misma forma por la cual otorgó su
        consentimiento. De tal forma que, si usted solicita una confirmación a
        su solicitud, “Javer” a través del oficial de privacidad le responderá
        de forma expresa, terminando la relación jurídica existente con “Javer”.
      </p>

      <p>El proceso de respuesta será el siguiente:</p>
      <br />

      <p>
        El oficial de privacidad responderá su solicitud ARCO y los motivos de
        su decisión mediante un correo electrónico en un plazo máximo de 20 días
        hábiles contados desde el día en que se haya recibido su solicitud ARCO.
        En caso de que la solicitud ARCO se conteste de manera afirmativa o
        procedente, los cambios solicitados se harán en un plazo máximo de 15
        días hábiles. El responsable podrá notificarle dentro de los plazos
        referidos en este párrafo la prórroga de los mismos, por una sola vez,
        por un periodo igual al original.
      </p>

      <p>
        Es posible que “Javer” pueda negar el acceso para que usted ejerza sus
        derechos ARCO, en los supuestos que lo permita la Ley, por lo que deberá
        informar a usted el motivo de tal decisión. Dicha negativa podrá ser
        parcial, en cuyo caso Javer efectuará el acceso, rectificación,
        cancelación u oposición en la parte procedente.
      </p>

      <AvisosTitle>
        Uso de tecnologías de rastreo en el sitio web de Javer
      </AvisosTitle>
      <p>
        En nuestra página de Internet utilizamos tecnologías de rastreo como
        cookies y herramientas de analítica web como Google Analytics, (términos
        definidos más adelante) a través de las cuales es posible monitorear sus
        visitas como usuario de Internet, brindarle un mejor servicio y
        experiencia de usuario al navegar en nuestra página, así como ofrecerle
        a través de banners promocionales nuevos productos y servicios basados
        en sus preferencias.  Los datos personales que podríamos obtener de
        estas tecnologías de rastreo son los siguientes: horario de navegación,
        tiempo de navegación en nuestra página de Internet, secciones
        consultadas. De la misma forma, el portal de “Javer” tiene ligas a otros
        sitios externos, de los cuales el contenido y políticas de privacidad no
        son su responsabilidad. Los datos personales que se obtienen a través de
        estas tecnologías se podrán compartir con terceros mexicanos o
        extranjeros en los que “Javer” se apoye para su operación, así como con
        sus subsidiarias, afiliadas y controladora.
      </p>
      <br />

      <p>
        Si usted no desea utilizar las tecnologías de rastreo podrá
        deshabilitarlas siguiendo los pasos que su respectivo navegador
        establezca en la barra de menú, ayuda (help).
      </p>

      <br />
      <p>
        Para mayor información, cookie es un archivo de datos que se almacenan
        en el disco duro del equipo de cómputo o del dispositivo de comunicación
        electrónica de un usuario al navegar por internet, el cual permite
        intercambiar información entre dicho sitio y el navegador del usuario.
        Esa información puede revelar medios de identificación de la sesión,
        autentificación o preferencias del usuario, así como cualquier dato
        almacenado por el navegador respecto al sitio web.
      </p>

      <p className="italic">
        Y Google Analytics, es una herramienta web con la cual se obtienen datos
        estadísticos de visualización e interacción de parte de los usuarios con
        la página web de “Javer”
      </p>

      <br />
      <AvisosTitle>Cambios al aviso de privacidad</AvisosTitle>
      <p>
        El presente aviso de privacidad puede sufrir cambios o actualizaciones
        derivadas de nuevos requerimientos legales; por las necesidades propias
        de “Javer” o por cambios en su modelo de negocio, en el entendido de que
        toda modificación al mismo se le hará conocer a usted por medio de la
        publicación de este aviso en la página de internet de “Javer”, por lo
        que le recomendamos verificarla con frecuencia.
      </p>

      <br />
      <p>
        Como parte de la preocupación de “Javer” por el buen uso de sus datos,
        en el posible evento de que ocurra una vulneración de seguridad en
        cualquier fase del tratamiento de datos, que afecte de forma
        significativa sus derechos patrimoniales o morales, el oficial de
        privacidad comunicará de forma inmediata en la página de internet de
        “Javer” el suceso de vulneración de seguridad, para que usted pueda
        tomar las medidas necesarias correspondientes para la defensa de sus
        derechos.
      </p>

      <AvisosTitle>Mecanismos de Protección</AvisosTitle>
      <p>
        En “Javer” contamos con controles internos en el manejo de la
        información y medidas de seguridad, incluyendo herramientas para
        encriptar y autentificar información que mantienen su información
        personal a salvo. Sus datos se procesan a través de sistemas de redes
        seguras y solamente puede acceder a ella un número limitado de personas
        con derechos especiales, a quienes se les exige que mantengan dicha
        información confidencial.
      </p>

      <br />
      <p>
        Si usted considera que su derecho a la protección de sus datos ha sido
        lesionado por alguna conducta u omisión de nuestra parte, le sugerimos
        visitar la página oficial{" "}
        <a
          href="www.ifai.org.mx"
          className="underline text-blue-500"
          target="_blank"
        >
          www.ifai.org.mx
        </a>
      </p>

      <br />
      <br />
      <p>
        SI EL TITULAR NO ESTÁ DE ACUERDO CON LOS TRATAMIENTOS BAJO ESTE AVISO
        DEBE MANIFESTARLO MEDIANTE LOS MECANISMOS AQUÍ PREVISTOS Y NO
        PROPORCIONAR SUS DATOS.
      </p>

      <br />
      <p className="text-sm italic">
        Derechos Reservados © Servicios Corporativos Javer, S.A.B. de C.V., 16
        de septiembre #301, entre Hidalgo y Corregidora, Colonia Centro, San
        Pedro Garza Garcia, N.L, C.P. 66230 (Edición 2021).
      </p>
    </div>
  );
};
