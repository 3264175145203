export const DecalogoNoDiscriminacion = () => {
  return (
    <div className="m-10 md:m-40 min-h-[50vh] text-justify">
      <p className="text-right">Agosto 2022</p>
      <h1 className="font-bold text-lg my-10 text-center">
        Decálogo para la no discriminación de clientes
      </h1>

      <p>
        Javer ofrece el trato equitativo, digno, que fomente una comunicación
        abierta, transparente, tolerante y libre de discriminación hacia sus
        clientes y rechaza todo acto que incluya algún término o expresión
        discriminatoria ya sea por acción u omisión, por motivos de sexo, raza,
        nacionalidad, orientación sexual, discapacidad, estado civil, religión,
        edad o cualquier otro motivo, para ello propone las siguientes prácticas
        que promuevan la igualdad de trato a nuestros clientes:
      </p>
      <ul className="list-disc">
        <li>
          Vigilar, identificar, reconocer y sancionar los actos de
          discriminación.
        </li>
        <li>
          Promover un trato equitativo y honesto hacia los clientes siguiendo
          nuestro compromiso descrito en el Código de Ética.
        </li>
        <li>
          Poner a disposición de los clientes los diferentes canales de denuncia
          anónima para la recepción de quejas o actos de discriminación.
        </li>
        <li>
          Fomentar medidas para prevenir actos de discriminación de cualquier
          tipo, a través de campañas de promoción de valores internos y el trato
          igualitario.
        </li>
        <li>
          Implementar medidas dentro de nuestros procesos promoviendo un trato
          imparcial y transparente, en cumplimiento con las regulaciones
          oficiales que nos apliquen.
        </li>
        <li>
          Disponer dentro de las instalaciones un ambiente seguro, diverso,
          incluyente y respetuoso entre el personal de Javer y cada uno de
          nuestros clientes.
        </li>
        <li>
          Impulsar las relaciones de respeto y confianza con todos los clientes.
        </li>
        <li>
          Respetar la libertad de expresión y opiniones de los distintos
          clientes salvo que afecten la seguridad o generen prácticas inseguras
          a propios y terceros.
        </li>
        <li>
          Practicar una comunicación abierta, transparente, tolerante y libre de
          discriminación.
        </li>
        <li>
          Promover en nuestras plataformas la cultura de la no discriminación.
        </li>
      </ul>
    </div>
  );
};
