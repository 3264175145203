interface JaverMarkerProps {
  size?: number;
  color?: string;
  className?: string;
  onClick?: () => void;
}

export const JaverMarker = ({
  size = 24,
  color = "#CD1019",
  className,
  onClick,
}: JaverMarkerProps) => {
  const aspectRatio = 56 / 68;

  const width = size;
  const height = width / aspectRatio;

  return (
    <svg
      width={width}
      height={height}
      className={className}
      onClick={onClick}
      viewBox="0 0 56 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.7715 67.7369L26.7603 67.7319L26.7374 67.7215L26.6648 67.6881C26.6047 67.6601 26.5213 67.6209 26.4164 67.5703C26.2065 67.4691 25.9098 67.3223 25.5387 67.1287C24.7969 66.7419 23.7551 66.1675 22.5134 65.3979C20.0351 63.8619 16.7305 61.5304 13.4181 58.3377C6.79132 51.9504 0 41.9699 0 28C0 12.536 12.536 0 28 0C43.464 0 56 12.536 56 28C56 41.9699 49.2087 51.9504 42.5819 58.3377C39.2695 61.5304 35.9649 63.8619 33.4866 65.3979C32.2449 66.1675 31.2031 66.7419 30.4613 67.1287C30.0902 67.3223 29.7935 67.4691 29.5836 67.5703C29.4787 67.6209 29.3953 67.6601 29.3352 67.6881L29.2626 67.7215L29.2397 67.7319L29.2316 67.7355C28.44 68.08 28 68 28 68C28 68 27.56 68.08 26.7715 67.7369Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 26.2381H21.5238L32.9524 11H23.0476L12 26.2381ZM14.6667 30.4286H24.1905L35.2381 14.8095L37.9048 18.2381L26.8571 33.8571H16.9524L14.6667 30.4286ZM28.381 36.5238H18.4762L21.1429 39.9524H31.0476L41.7143 24.3333L39.4286 20.9048L28.381 36.5238ZM23.0476 43H32.9524L44 27.381H42.0952L31.8095 41.4762H24.1905L23.0476 43Z"
        fill="white"
      />
    </svg>
  );
};
