import { useTranslation } from "react-i18next";
import { useAppContext } from "../data/DataProvider";
import { useQuery } from "react-query";
import { getBlogArticles, getInitialDataDesarrollos } from "@/utils/api";
import { useEffect, useMemo, useState } from "react";
import { Button } from "@/components/ui/button";
import { ProjectCard } from "@/components/ProjectCard";
import { toUrlCase } from "@/utils/common.utils";
import { Link } from "react-router-dom";
import { Link as SmoothLink } from "react-scroll";
import { getResourceUrl } from "@/utils/image.utils";
import {
  HiArrowRight,
  HiChevronDown,
  HiOutlineClock,
  HiOutlinePhone,
} from "react-icons/hi2";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { useWindowSize } from "@uidotdev/usehooks";
import homeReviews from "@/data/static/home-reviews.json";
import qna from "@/data/static/qna.json";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
} from "@/components/ui/card";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";
import { format } from "date-fns";
import { es, enUS } from "date-fns/locale";
import { Interweave } from "interweave";
import { HomeSearchBar } from "@/components/HomeSearchBar";
import { type LocationHierarchy } from "@/models/location_hierarchy";
import { motion } from "framer-motion";
import { PHONE_NUMBER } from "@/constants";
import { HomeForm } from "@/components/HomeForm";

function Home() {
  const { t, i18n } = useTranslation("translations");
  const { websiteMedia, requestGeoPosition } = useAppContext();
  const { dataDesarrollos, lonLat } = useAppContext();
  const outstandingProjects = t("outstandingProjects").split(" ");
  const [selectedState, setSelectedState] = useState<number | null>(null);
  const { width } = useWindowSize();

  useEffect(() => {
    requestGeoPosition();
  }, []);

  const { data: developments } = useQuery(
    "getInitialDataDesarrollos",
    async () => await getInitialDataDesarrollos(),
    {
      initialData: dataDesarrollos,
    }
  );

  // const { data: models } = useQuery( TODO: Descomentar cuando se quirean habilitar los modelos
  //   "getAllPropertiesHome",
  //   async () => await getAllPropertiesHome()
  // );

  const { data: blog } = useQuery(
    "getBlogArticles",
    async () => await getBlogArticles()
  );

  const nearestState = useMemo(() => {
    if (!developments || !lonLat) return null;
    return developments.reduce<{
      state: LocationHierarchy;
      distance: number;
    } | null>((prev, curr) => {
      const projects = curr.ciudades.flatMap((c) => c.proyectos);

      const currDistance = projects.reduce((prev, curr) => {
        const distance = Math.sqrt(
          Math.pow(parseFloat(curr.latitud) - lonLat.lat, 2) +
            Math.pow(parseFloat(curr.longitud) - lonLat.lon, 2)
        );
        return distance < prev ? distance : prev;
      }, Infinity);

      if (!prev) return { state: curr, distance: currDistance };

      return currDistance < prev.distance
        ? { state: curr, distance: currDistance }
        : prev;
    }, null);
  }, [developments, lonLat]);

  const featuredDevelopments = useMemo(() => {
    if (!developments) return [];
    return developments
      .filter((d) =>
        d.ciudades.some((c) =>
          c.proyectos.some((p) => p.outstanding === 1 && p.active === 1)
        )
      )
      .map((d) => ({
        ...d,
        ciudades: d.ciudades
          .filter((c) =>
            c.proyectos.some((p) => p.outstanding === 1 && p.active === 1)
          )
          .map((c) => ({
            ...c,
            proyectos: c.proyectos.filter(
              (p) => p.outstanding === 1 && p.active === 1
            ),
          })),
      }));
  }, [developments]);

  const nearestFeaturedState = useMemo(() => {
    if (!featuredDevelopments || !lonLat) return null;
    return featuredDevelopments.reduce<{
      state: LocationHierarchy;
      distance: number;
    } | null>((prev, curr) => {
      const projects = curr.ciudades.flatMap((c) => c.proyectos);

      const currDistance = projects.reduce((prev, curr) => {
        const distance = Math.sqrt(
          Math.pow(parseFloat(curr.latitud) - lonLat.lat, 2) +
            Math.pow(parseFloat(curr.longitud) - lonLat.lon, 2)
        );
        return distance < prev ? distance : prev;
      }, Infinity);

      if (!prev) return { state: curr, distance: currDistance };

      return currDistance < prev.distance
        ? { state: curr, distance: currDistance }
        : prev;
    }, null);
  }, [featuredDevelopments, lonLat]);

  useEffect(() => {
    if (nearestFeaturedState) {
      setSelectedState(nearestFeaturedState.state.id);
    } else {
      setSelectedState(developments?.[0]?.id ?? null);
    }
  }, [nearestFeaturedState, developments]);

  return (
    <div className="mt-[-78px] lg:mt-[-95px] pb-32">
      <div className="relative">
        <video
          autoPlay
          loop
          muted
          playsInline
          src={websiteMedia.home_video}
          className="object-cover w-full h-[85vh]"
        />
        <div className="absolute -bottom-1 h-[80vh] w-full bg-gradient-to-b from-transparent via-transparent via-80% to-white" />
        <div className="absolute top-0 h-[80vh] w-full">
          <div className="container flex items-end justify-center h-full w-full max-2xl:max-w-5xl lg:pb-16">
            <HomeSearchBar selectedState={nearestState?.state.name} />
          </div>
        </div>
        <motion.div
          initial={{
            bottom: 64,
          }}
          animate={{
            bottom: 16,
          }}
          transition={{
            ease: "easeOut",
            repeatDelay: 1,
            repeat: Infinity,
            repeatType: "mirror",
            duration: 1,
          }}
          className="absolute max-lg:hidden left-[50%] -translate-x-1/2"
        >
          <SmoothLink
            to="outstanding-projects"
            smooth
            className="flex flex-col items-center [&>*]:-my-3.5 cursor-pointer"
          >
            <HiChevronDown className="text-white svg-shadow" size={56} />
          </SmoothLink>
        </motion.div>
        <div id="outstanding-projects" className="absolute bottom-16" />
      </div>
      <section className="container py-12 max-2xl:max-w-7xl">
        <div className="flex flex-col">
          <h2 className="text-stroke max-lg:!text-5xl">
            {outstandingProjects[0]}
          </h2>
          <h2 className="text-primary ml-24 -mt-5 max-lg:!text-5xl">
            {outstandingProjects[1]}
          </h2>
        </div>
        <div className="flex flex-row gap-2 items-center justify-center mt-8 flex-wrap">
          {featuredDevelopments.map((d) => (
            <Button
              key={d.id}
              className={`bg-neutral-300/20 text-neutral-700 px-4 py-3 ${
                selectedState === d.id
                  ? "bg-primary/20 text-primary border-b-2 border-primary"
                  : ""
              }`}
              onClick={() => {
                setSelectedState(d.id);
              }}
              variant="ghost"
            >
              {d.name}
            </Button>
          ))}
        </div>
        <div className="flex flex-row justify-center">
          <ProjectCard
            className="mt-8 w-[1000px]"
            project={featuredDevelopments
              .find((d) => d.id === selectedState)
              ?.ciudades?.at(0)
              ?.proyectos?.at(0)}
          />
        </div>
        <div className="flex flex-row justify-center mt-4">
          <Button className="mt-2" asChild variant="ghost">
            <Link
              to={`/estados/${toUrlCase(
                featuredDevelopments.find((d) => d.id === selectedState)
                  ?.name ?? ""
              )}`}
            >
              {t("seeTheEntireState")}
            </Link>
          </Button>
        </div>
      </section>

      {/* TODO: Descomentar cuando se quirean habilitar los modelos
      <section className="container max-2xl:max-w-7xl">
        <h4>{t("exploreDifferentModels")}</h4>
        <hr className="my-6 border-primary" />
      </section>

       <section
        className={`grid gap-1 md:gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 mt-16 max-md:hidden`}
      >
        {models?.map((model, index) => (
          <HoverableImageModel
            key={index}
            isHoverable
            model={model}
            src={getResourceUrl(model.main_image)}
            onClick={() => {
              navigate(
                `/desarrollos/${toUrlCase(
                  toUrlCase(model.project_name)
                )}/propiedad/${toUrlCase(toUrlCase(model.property_name))}`
              );
            }}
            className={`object-cover aspect-square`}
          />
        ))}
      </section> */}

      {/* <Carousel TODO: Descomentar cuando se quirean habilitar los modelos
        opts={{
          dragFree: true,
        }}
      >
        <CarouselContent className="md:hidden mx-6">
          {models?.slice(0, 3)?.map((model, index) => (
            <CarouselItem
              key={index}
              className="basis-auto max-w-full min-w-0 pl-2 pb-2"
            >
              <Card className="w-[230px] select-none border-none shadow-none">
                <CardHeader className="p-0">
                  <img
                    src={model.main_image}
                    alt={model.property_name}
                    className="w-full h-36"
                  />
                </CardHeader>
                <CardContent className="flex flex-col gap-2 p-0">
                  <div className="mt-4 font-viga">{model.property_name}</div>
                  <div className="flex flex-row justify-between items-center">
                    {t("fromPrice")}{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(model.price_base)}
                  </div>
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
      <Carousel
        opts={{
          dragFree: true,
        }}
        className="mt-4"
      >
        <CarouselContent className="md:hidden mx-6">
          {models?.slice(3, 6)?.map((model, index) => (
            <CarouselItem
              key={index}
              className="basis-auto max-w-full min-w-0 pl-2 pb-2"
            >
              <Card className="w-[230px] select-none border-none shadow-none">
                <CardHeader className="p-0">
                  <img
                    src={model.main_image}
                    alt={model.property_name}
                    className="w-full h-36"
                  />
                </CardHeader>
                <CardContent className="flex flex-col gap-2 p-0">
                  <div className="mt-4 font-viga">{model.property_name}</div>
                  <div className="flex flex-row justify-between items-center">
                    {t("fromPrice")}{" "}
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(model.price_base)}
                  </div>
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel> */}

      {/* <section className="py-16 flex flex-col lg:flex-row w-full"> TODO: Descomentar cuando se quirean habilitar las estadisticas
        <StatisticDisplay
          upperText={t("weHaveBuiltMoreThan")}
          lowerText={t("housesAndApartments")}
        >
          {(value) =>
            Intl.NumberFormat("es-MX").format(Math.floor(value * 305000))
          }
        </StatisticDisplay>
        <StatisticDisplay
          upperText={t("javerHasHelpedMoreThan")}
          lowerText={t("familiesInTheCountry")}
        >
          {(value) =>
            Intl.NumberFormat("es-MX").format(Math.floor(value * 13700))
          }
        </StatisticDisplay>
        <StatisticDisplay
          upperText={t("investorsHaveHad")}
          lowerText={t("ofBenefits")}
        >
          {(value) =>
            `+${Intl.NumberFormat("es-MX").format(Math.floor(value * 2150))}`
          }
        </StatisticDisplay>
        <StatisticDisplay
          upperText={t("weHavePresenceInMoreThan")}
          lowerText={t("statesWithinTheCountry")}
        >
          {(value) => `${Math.floor(value * 8)}+`}
        </StatisticDisplay>
      </section> */}
      <section className="lg:h-[480px] flex flex-col lg:flex-row gap-8 my-8 container xl:max-w-[1700px]">
        <img
          src="/images/home-know-javer.jpg"
          alt="know javer"
          className="lg:w-1/2 h-auto object-cover"
        />
        <div className="lg:w-1/2 flex flex-col items-center">
          <div>
            <h2 className="text-stroke !text-6xl">{t("know")}</h2>
            <h2 className="text-primary ml-32 -mt-5 !text-6xl">{t("javer")}</h2>
          </div>
          <p className="border-l pl-2 py-4 border-foreground">
            {t("knowJaverDescription")}
          </p>
          <Button className="self-start mt-2" asChild>
            <Link to="/nosotros">
              {t("knowMore")}
              <HiArrowRight className="ml-2" size={20} />
            </Link>
          </Button>
        </div>
      </section>

      <section className="container py-10">
        <h4>{t("learnAboutTheExperienceOfOurClients")}</h4>
        <hr className="my-6 border-primary" />
      </section>
      <Carousel
        opts={{
          dragFree: true,
        }}
      >
        <CarouselContent
          style={{
            marginLeft: width && width >= 1400 ? (width - 1400) / 2 + 24 : 16,
            marginRight: width && width >= 1400 ? (width - 1400) / 2 + 24 : 16,
          }}
        >
          {homeReviews?.map((review, index) => (
            <CarouselItem
              key={index}
              className="lg:basis-auto max-w-full min-w-0 pl-2 pb-2"
            >
              <Card
                onClick={() => {
                  const url: string | undefined = review.url;
                  if (url) window.open(url, "_blank");
                }}
                className={`lg:w-[500px] select-none hover:bg-primary hover:text-primary-foreground transition-colors duration-500 h-full ${review.url ? "cursor-pointer" : ""}`}
              >
                <CardContent className="flex flex-col gap-4 pt-4 h-full">
                  <div className="grow">&quot;{review.description}&quot;</div>
                  <div className="flex flex-col gap-4 lg:flex-row justify-between lg:items-center">
                    <div>- {review.name}</div>
                    <img
                      src={review.logo}
                      alt={review.name}
                      className="w-24 h-24 rounded-lg object-contain border bg-white p-2"
                    />
                  </div>
                </CardContent>
              </Card>
            </CarouselItem>
          ))}
        </CarouselContent>
        <CarouselNext />
        <CarouselPrevious />
      </Carousel>
      <section className="bg-neutral-300/20 my-8">
        <div className="container py-6 flex flex-col lg:flex-row gap-8">
          <div className="lg:w-1/2">
            <h4>{t("learnAboutOurRealEstate")}</h4>
            <hr className="my-6 border-primary" />
            <p className="border-l pl-2 py-4 border-foreground">
              {t("learnAboutOurRealEstateDescription")}
            </p>
          </div>
          <div className="lg:w-1/2">
            <Accordion type="single" collapsible className="w-full">
              {qna.map((item, index) => (
                <AccordionItem
                  value={`item-${index}`}
                  key={index}
                  className="bg-white rounded-lg mb-4 border-none px-4"
                >
                  <AccordionTrigger>
                    {item.question[i18n.language as "es" | "en"]}
                  </AccordionTrigger>
                  <AccordionContent className="pt-4">
                    <Interweave
                      content={item.answer[i18n.language as "es" | "en"]}
                    />
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </section>
      <section className="container py-10">
        <h4>{t("javerBlog")}</h4>
        <hr className="my-6 border-primary" />
        <div className="flex flex-col md:flex-row gap-4 justify-center">
          {blog?.slice(0, 3).map((article, index) => (
            <Link to={`/blog/${article.id}`} key={index}>
              <Card className="md:w-[300px] select-none">
                <CardHeader className="p-0">
                  <img
                    src={getResourceUrl(article.image)}
                    alt={article.title}
                    className="w-full h-48 object-cover"
                  />
                </CardHeader>
                <CardContent>
                  <div className="flex flex-col gap-2 mt-2">
                    <div className="text-xs">
                      {format(article.date, "PPPP", {
                        locale: i18n.language === "es" ? es : enUS,
                      })}
                    </div>
                    <h6 className="!text-base">{article.title}</h6>
                    <div className="text-sm text-foreground-soft line-clamp-4">
                      <Interweave content={article.content} />
                    </div>
                  </div>
                </CardContent>
                <CardFooter className="flex flex-row justify-end">
                  <Button className="!text-sm" variant="outline">
                    {t("seeMore")}
                    <HiArrowRight className="ml-2" size={20} />
                  </Button>
                </CardFooter>
              </Card>
            </Link>
          ))}
        </div>
      </section>
      <section className="container py-12 flex flex-col lg:flex-row gap-8">
        <div className="lg:w-1/2">
          <h4>{t("makeTheHomeOfYourDreamsComeTrue")}</h4>
          <hr className="my-6 border-primary" />
          <div className="flex flex-col justify-around mb-8 gap-8">
            <div className="flex flex-row gap-4 items-center">
              <HiOutlinePhone className="text-primary" size={28} />
              <div>
                <p>{t("contact")}</p>
                <strong className="whitespace-pre-wrap">{PHONE_NUMBER}</strong>
              </div>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <HiOutlineClock className="text-primary" size={28} />
              <div>
                <p>{t("attentionHours")}</p>
                <strong>{t("attentionHoursContent")}</strong>
              </div>
            </div>
          </div>
        </div>
        <HomeForm className="lg:!w-1/2" />
      </section>
    </div>
  );
}

export default Home;
