import { useTranslation } from "react-i18next";
import { Img } from "../Img";
import { ReservasForm } from "./ReservasForm";
import { useAppContext } from "../../data/DataProvider";

export const ReservasTerritoriales = () => {
  const { t } = useTranslation("translations");
  const { websiteMedia } = useAppContext();

  return (
    <div className="flex justify-center flex-col items-center m-10 md:m-20">
      <div className="relative m-5 flex justify-center">
        {websiteMedia.reservas_image ? (
          <Img
            className={`brightness-50 object-cover h-[500px] rounded-lg w-full `}
            src={websiteMedia.reservas_image}
            alt="contacto reservas territoriales javer"
          />
        ) : null}
        <h4
          className={`absolute font-sans font-bold flex flex-col  justify-center  brightness-100 text-2xl md:text-4xl text-gray-50  h-full  text-center w-2/3  uppercase`}
        >
          {t("landReservesTitle")}
        </h4>
      </div>

      <p className="p-5 md:p-10">{t("landReservesDescription")}</p>
      <ReservasForm />
    </div>
  );
};
