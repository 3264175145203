import Lightbox from "yet-another-react-lightbox";
import { HoverableImage } from "./HoverableImage";
import { useState, useCallback } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";
import { Thumbnails } from "yet-another-react-lightbox/plugins";
import { getResourceUrl } from "@/utils/image.utils";

interface GalleryProps {
  images?: string[];
  type?: "grid" | "carousel";
  autoWidth?: boolean;
  onClick?: (index: number) => void;
  isHoverable?: boolean;
  classNames?: {
    ROOT?: string;
    Image?: string;
    Carousel?: {
      Content?: string;
      Item?: string;
      Next?: string;
      Previous?: string;
    };
  };
}

export function Gallery({
  images,
  onClick,
  type = "grid",
  classNames,
  isHoverable = true,
  autoWidth = true,
}: GalleryProps) {
  const [amenitiesLightboxController, setAmenitiesLightboxController] =
    useState({
      toggler: false,
      slide: 1,
    });

  const openImageOnIndex = useCallback(
    (slide: number) => {
      setAmenitiesLightboxController({
        toggler: !amenitiesLightboxController.toggler,
        slide,
      });
    },
    [amenitiesLightboxController]
  );

  return (
    <>
      {type === "grid" && (
        <section
          className={`grid gap-1 md:gap-4 grid-cols-2 sm:grid-cols-2 md:grid-cols-3 2xl:grid-cols-4  mt-16 ${classNames?.ROOT}`}
        >
          {images?.map((image, index) => (
            <HoverableImage
              key={index}
              isHoverable={isHoverable}
              src={getResourceUrl(image)}
              onClick={() => {
                if (onClick) {
                  onClick(index);
                } else {
                  openImageOnIndex(index);
                }
              }}
              className={`object-cover aspect-square ${classNames?.Image}`}
            />
          ))}
        </section>
      )}
      {type === "carousel" && (
        <Carousel
          className={classNames?.ROOT}
          opts={{
            dragFree: true,
          }}
        >
          <CarouselContent className={`mx-6 ${classNames?.Carousel?.Content}`}>
            {images?.map((image, index) => (
              <CarouselItem
                key={index}
                className={`${autoWidth ? "basis-auto" : ""} max-w-full min-w-0 pl-2 ${classNames?.Carousel?.Item}`}
              >
                <HoverableImage
                  onClick={() => {
                    if (onClick) {
                      onClick(index);
                    } else {
                      openImageOnIndex(index);
                    }
                  }}
                  isHoverable={isHoverable}
                  className={`object-cover aspect-square ${classNames?.Image}`}
                  src={getResourceUrl(image)}
                />
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselNext className={classNames?.Carousel?.Next} />
          <CarouselPrevious className={classNames?.Carousel?.Previous} />
        </Carousel>
      )}
      <Lightbox
        index={amenitiesLightboxController.slide}
        open={amenitiesLightboxController.toggler}
        close={() => {
          setAmenitiesLightboxController({ toggler: false, slide: 0 });
        }}
        slides={images?.map((image) => ({
          src: getResourceUrl(image),
        }))}
        plugins={[Thumbnails]}
        thumbnails={{
          border: 0,
          imageFit: "cover",
          gap: 4,
          height: 128,
          width: 172,
        }}
      />
    </>
  );
}
