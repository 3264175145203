import { useAppContext } from "@/data/DataProvider";
import { HiBars3, HiHeart, HiOutlineUser, HiXMark } from "react-icons/hi2";
import { Link } from "react-router-dom";
import Favorites from "./Favourites";
import { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { type Translation } from "@/types/translation";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from "@/components/ui/navigation-menu";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/components/ui/popover";
import { PopoverAnchor } from "@radix-ui/react-popover";
import { ChevronDown } from "lucide-react";
import { useWindowScroll } from "@uidotdev/usehooks";
import { DesktopMenu } from "@/components/DesktopMenu";
import { MobileMenu } from "@/components/MobileMenu";

export default function TopBar() {
  const { setOpenLogin, openLogin, navbarSections, dataDesarrollos, dataAuth } =
    useAppContext();
  const { t, i18n } = useTranslation("translations");
  const [{ y }] = useWindowScroll();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const [favoritesOpen, setFavoritesOpen] = useState(false);
  const [selectedState, setSelectedState] = useState<number | null>(null);
  const [selectedCity, setSelectedCity] = useState<number | null>(null);

  useEffect(() => {
    setSelectedState(null);
    setSelectedCity(null);
  }, [isMenuOpen]);

  const links = useMemo(() => {
    return navbarSections
      .filter((item) => item.active === 1)
      .map((item) => ({
        name: t(item.name_eng as keyof Translation),
        path: item.path,
      }));
  }, [navbarSections, t]);

  const cities = useMemo(() => {
    if (selectedState === null) {
      return null;
    }
    return dataDesarrollos.find((item) => item.id === selectedState)?.ciudades;
  }, [dataDesarrollos, selectedState]);

  const projects = useMemo(() => {
    if (selectedCity === null) {
      return null;
    }
    return (
      cities
        ?.find((item) => item.id === selectedCity)
        ?.proyectos?.filter((it) => it.active === 1) ?? []
    );
  }, [cities, selectedCity]);

  return (
    <>
      <Popover open={isMenuOpen} onOpenChange={setIsMenuOpen}>
        <div className="sticky top-0 left-0 z-40">
          <div
            className={`px-4 lg:px-14 py-6 flex flex-row gap-4  transition-all ${
              isMenuOpen ? "bg-white" : ""
            } ${(y ?? 0) > 1 ? "bg-white shadow-md" : "bg-gradient-to-b from-white"}`}
          >
            <Link to="/">
              <img
                src="/images/bar-logo.png"
                alt="logo"
                className="w-28 lg:w-44"
              />
            </Link>
            <div className="grow hidden lg:flex flex-row items-center justify-center">
              <NavigationMenu>
                <NavigationMenuList className="gap-6">
                  <NavigationMenuItem>
                    <PopoverTrigger className="group flex flex-row items-center gap-1 hover:text-accent">
                      {t("developments")}

                      <ChevronDown
                        className="relative top-[1px] h-6 w-6 transition duration-200 group-data-[state=open]:rotate-180"
                        aria-hidden="true"
                      />
                    </PopoverTrigger>
                  </NavigationMenuItem>
                  {links.map((link) => (
                    <NavigationMenuItem key={link.path}>
                      <NavigationMenuLink asChild className="hover:text-accent">
                        <Link to={link.path}>{link.name}</Link>
                      </NavigationMenuLink>
                    </NavigationMenuItem>
                  ))}
                </NavigationMenuList>
              </NavigationMenu>
            </div>
            <div className="hidden lg:flex flex-row gap-6 items-center">
              <HiOutlineUser
                size={24}
                className="cursor-pointer"
                onClick={() => {
                  setOpenLogin(true);
                }}
              />
              {dataAuth.token && (
                <HiHeart
                  size={24}
                  className="text-red-600 cursor-pointer"
                  onClick={() => {
                    setFavoritesOpen(true);
                  }}
                />
              )}
              <div className="[&>*]:font-viga [&>*]:cursor-pointer flex items-center gap-2">
                <div
                  onClick={() => {
                    i18n.changeLanguage("es");
                  }}
                  className={i18n.language === "es" ? "text-accent" : ""}
                >
                  ES
                </div>
                |
                <div
                  onClick={() => {
                    i18n.changeLanguage("en");
                  }}
                  className={i18n.language === "en" ? "text-accent" : ""}
                >
                  EN
                </div>
              </div>
            </div>
            <div className="lg:hidden flex flex-row items-center justify-end grow">
              {!isMenuOpen && (
                <HiBars3
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(true);
                  }}
                />
              )}
              {isMenuOpen && (
                <HiXMark
                  size={24}
                  className="cursor-pointer"
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                />
              )}
            </div>
          </div>
          <PopoverAnchor />
        </div>
        <PopoverContent
          className="w-screen rounded-none overflow-hidden h-[calc(100vh-78px)] lg:h-[500px] p-0"
          sideOffset={-2}
        >
          <DesktopMenu
            className="max-lg:hidden"
            dataDesarrollos={dataDesarrollos}
            cities={cities}
            projects={projects}
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            setIsMenuOpen={setIsMenuOpen}
          />

          <MobileMenu
            className="lg:hidden"
            links={links}
            dataDesarrollos={dataDesarrollos}
            cities={cities}
            projects={projects}
            selectedCity={selectedCity}
            setSelectedCity={setSelectedCity}
            selectedState={selectedState}
            setSelectedState={setSelectedState}
            setIsMenuOpen={setIsMenuOpen}
          />
        </PopoverContent>
      </Popover>
      {!openLogin && favoritesOpen && (
        <Favorites
          onClose={() => {
            setFavoritesOpen(false);
          }}
        />
      )}
    </>
  );
}
