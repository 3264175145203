import { type LocationHierarchy } from "@/models/location_hierarchy";
import { toUrlCase } from "@/utils/common.utils";
import { ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { HiArrowLeft, HiArrowRight, HiHeart } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { Button } from "./ui/button";
import { useAppContext } from "@/data/DataProvider";
import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
} from "./ui/navigation-menu";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "./ui/accordion";
import { HiOutlineUser } from "react-icons/hi";
import Favorites from "@/root-components/Favourites";
import { useState } from "react";

interface MobileMenuProps {
  className?: string;
  dataDesarrollos: LocationHierarchy[];
  cities?: LocationHierarchy["ciudades"] | null;
  projects?: LocationHierarchy["ciudades"][number]["proyectos"] | null;
  selectedCity: number | null;
  setSelectedCity: (id: number | null) => void;
  selectedState: number | null;
  setSelectedState: (id: number | null) => void;
  setIsMenuOpen: (open: boolean) => void;
  links: Array<{ name: string; path: string }>;
}

export function MobileMenu({
  className,
  dataDesarrollos,
  cities,
  projects,
  selectedCity,
  setSelectedCity,
  selectedState,
  setSelectedState,
  setIsMenuOpen,
  links,
}: MobileMenuProps) {
  const { t, i18n } = useTranslation("translations");
  const { dataAuth, setOpenLogin, logoutUser, openLogin } = useAppContext();
  const [favoritesOpen, setFavoritesOpen] = useState(false);

  return (
    <div className={`w-full h-full flex flex-col gap-4 px-4 py-4 ${className}`}>
      {!dataAuth?.token && (
        <Button
          className="mt-4"
          onClick={() => {
            setOpenLogin(true);
            setIsMenuOpen(false);
          }}
        >
          <HiOutlineUser className="mr-2" size={24} />
          {t("login")}
        </Button>
      )}
      <div className="[&>*]:font-viga [&>*]:cursor-pointer flex items-center gap-2">
        <div
          onClick={() => {
            i18n.changeLanguage("es");
          }}
          className={i18n.language === "es" ? "text-accent" : ""}
        >
          ES
        </div>
        |
        <div
          onClick={() => {
            i18n.changeLanguage("en");
          }}
          className={i18n.language === "en" ? "text-accent" : ""}
        >
          EN
        </div>
      </div>
      <NavigationMenu className="w-full max-w-full items-stretch flex-col">
        <NavigationMenuList className="gap-2 flex-col items-start w-full [&>*]:py-4 [&>*]:w-full [&>*]:!mx-0">
          <NavigationMenuItem>
            <Accordion type="single" collapsible>
              <AccordionItem
                value="item-1"
                className="border-none [&[data-state=open]]:bg-neutral-100 [&[data-state=open]]:p-2 rounded transition-all"
              >
                <AccordionTrigger className="font-normal text-base p-0 hover:text-accent hover:no-underline [&[data-state=open]]:text-accent">
                  {t("developments")}
                </AccordionTrigger>
                <AccordionContent className="pt-4">
                  {!selectedState && (
                    <div>
                      {dataDesarrollos.map((item) => (
                        <div
                          key={item.id}
                          onClick={() => {
                            if (selectedState === item.id) {
                              setSelectedState(null);
                            } else {
                              setSelectedState(item.id);
                            }

                            setSelectedCity(null);
                          }}
                          className={`transition-all font-viga text-base text-foreground-soft px-4 py-2 cursor-pointer flex flex-row justify-between items-center ${
                            selectedState === item.id ? "!text-accent" : ""
                          }`}
                        >
                          {item.name}
                          <ChevronRight className="h-6 w-6 inline-block" />
                        </div>
                      ))}
                    </div>
                  )}

                  {selectedState && !selectedCity && (
                    <div>
                      <Button
                        variant="ghost"
                        onClick={() => {
                          setSelectedCity(null);
                          setSelectedState(null);
                        }}
                      >
                        <HiArrowLeft className="mr-2" />
                        {t("back")}
                      </Button>
                      {cities?.map((item) => (
                        <div
                          key={item.id}
                          onClick={() => {
                            if (selectedCity === item.id) {
                              setSelectedCity(null);
                            } else {
                              setSelectedCity(item.id);
                            }
                          }}
                          className={`transition-all font-viga text-base text-foreground-soft px-4 py-2 cursor-pointer flex flex-row justify-between items-center ${
                            selectedCity === item.id ? "!text-accent" : ""
                          }`}
                        >
                          {item.name}
                          <ChevronRight className="h-8 w-8 inline-block" />
                        </div>
                      ))}
                      <Button className="mt-4" variant="ghost" asChild>
                        <Link
                          to={`/estados/${toUrlCase(
                            dataDesarrollos.find(
                              (item) => item.id === selectedState
                            )?.name ?? ""
                          )}`}
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          {t("seeAll")}
                          <HiArrowRight className="ml-2" />
                        </Link>
                      </Button>
                    </div>
                  )}
                  {selectedCity && (
                    <div>
                      <Button
                        variant="ghost"
                        onClick={() => {
                          setSelectedCity(null);
                        }}
                      >
                        <HiArrowLeft className="mr-2" />
                        {t("back")}
                      </Button>
                      {/* {cities?.find((item) => item.id === selectedCity) // TODO: Descomentar cuando se quiera habilitar el link a Caranday JP
                        ?.name === "Aguascalientes" && (
                        <a
                          href="https://residencialcaranday.com/"
                          target="_blank"
                          rel="noreferrer"
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                          className={`transition-all font-viga text-base text-foreground-soft px-4 py-2 hover:text-accent cursor-pointer hover:underline block`}
                        >
                          Caranday JP
                        </a>
                      )} */}
                      {projects?.map((item) => (
                        <Link
                          to={`/desarrollos/${toUrlCase(item.short_name)}`}
                          key={item.id}
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                          className={`transition-all font-viga text-base text-foreground-soft px-4 py-2 hover:text-accent cursor-pointer hover:underline block`}
                        >
                          {item.name}
                        </Link>
                      ))}
                      <Button className="mt-4" variant="ghost" asChild>
                        <Link
                          to={`/estados/${toUrlCase(
                            dataDesarrollos.find(
                              (item) => item.id === selectedState
                            )?.name ?? ""
                          )}?zona=${toUrlCase(
                            cities?.find((item) => item.id === selectedCity)
                              ?.name ?? ""
                          )}`}
                          onClick={() => {
                            setIsMenuOpen(false);
                          }}
                        >
                          {t("seeAll")}
                          <HiArrowRight className="ml-2" />
                        </Link>
                      </Button>
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
            </Accordion>
          </NavigationMenuItem>

          {links.map((link) => (
            <NavigationMenuItem key={link.path} className="hover:text-accent">
              <NavigationMenuLink asChild>
                <Link
                  to={link.path}
                  onClick={() => {
                    setIsMenuOpen(false);
                  }}
                  className="font-viga"
                >
                  {link.name}
                </Link>
              </NavigationMenuLink>
            </NavigationMenuItem>
          ))}
        </NavigationMenuList>
      </NavigationMenu>
      <div className="grow" />
      {dataAuth.token && (
        <>
          <Button
            variant="outline"
            onClick={() => {
              setFavoritesOpen(true);
            }}
          >
            <HiHeart className="mr-2" size={24} />
            {t("myFavorites")}
          </Button>
          <Button
            variant="outline"
            onClick={() => {
              logoutUser();
              setIsMenuOpen(false);
            }}
          >
            {t("logout")}
          </Button>
        </>
      )}
      {!openLogin && favoritesOpen && (
        <Favorites
          onClose={() => {
            setFavoritesOpen(false);
          }}
        />
      )}
    </div>
  );
}
