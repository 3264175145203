import { GoogleMapsLogo } from "@/components/icons/GoogleMapsLogo";
import { WazeLogo } from "@/components/icons/WazeLogo";
import { type Project } from "@/models/project";
import { Button } from "@/components/ui/button";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AdvancedMarker, Map } from "@vis.gl/react-google-maps";
import { JaverMarker } from "@/components/icons/JaverMarker";

interface HousingDevelopmentLocationProps {
  project?: Project;
}

export function HousingDevelopmentLocation({
  project,
}: HousingDevelopmentLocationProps) {
  const { t } = useTranslation("translations");

  const { lat, lng } = useMemo(() => {
    if (!project) return {};

    const lat = parseFloat(project.latitud);
    const lng = parseFloat(project.longitud);

    if (isNaN(lat) || isNaN(lng) || lat === 0.0 || lng === 0.0) return {};

    return {
      lat,
      lng,
    };
  }, [project]);

  return (
    <>
      <section
        className="container xl:max-w-5xl mx-auto pt-16"
        id="location-section"
      >
        <h4>{t("location")}</h4>
        <p className="mt-2">
          {project?.calle}, {project?.colonia}, {project?.cp}, {project?.ciudad}
          .
        </p>
        <hr className="my-6 border-primary" />
        {(!!project?.link_map || project?.wase_link_map) && (
          <div className="flex flex-col lg:flex-row gap-4 lg:gap-12 justify-center items-center mb-8">
            <div>{t("howToGetHere")}</div>
            {project?.link_map && (
              <Button variant="maps">
                <a
                  href={project?.link_map}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center"
                >
                  <GoogleMapsLogo size={12} className="mr-2" />
                  {t("continueInGoogleMaps")}
                </a>
              </Button>
            )}
            {project?.wase_link_map && (
              <Button
                variant="maps"
                className="bg-[#43B3F8] border-none hover:bg-[#43B3F8]/80"
              >
                <a
                  href={project?.wase_link_map}
                  target="_blank"
                  rel="noreferrer"
                  className="flex items-center"
                >
                  <WazeLogo size={18} className="mr-2" />
                  {t("continueInWaze")}
                </a>
              </Button>
            )}
          </div>
        )}
      </section>
      {lat && lng && (
        <div className="w-full h-[30rem]">
          <Map
            defaultCenter={{
              lat,
              lng,
            }}
            zoom={15}
            mapId="housing_development_map"
          >
            <AdvancedMarker
              position={{
                lat,
                lng,
              }}
            >
              <JaverMarker size={24} />
            </AdvancedMarker>
          </Map>
        </div>
      )}
    </>
  );
}
