import {
  getAmenitiesByProjectId,
  getProjectByID,
  getPropertiesSearch,
} from "@/utils/api";
import { toUrlCase } from "@/utils/common.utils";
import { getResourceUrl } from "@/utils/image.utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { Card, CardContent, CardHeader } from "./ui/card";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "./ui/carousel";
import { HiX } from "react-icons/hi";
import { type ProjectByFilters } from "@/models/project_by_filters_result";

interface MapProjectInfoProps {
  project?: ProjectByFilters;
  onClose?: () => void;
}

export function MapProjectInfo({ project, onClose }: MapProjectInfoProps) {
  const { t } = useTranslation("translations");

  const { data: projectData } = useQuery(
    ["getProjectById", project?.project_id],
    async () => await getProjectByID(project?.project_id ?? -1),
    {
      enabled: !!project?.project_id,
    }
  );

  const { data: properties } = useQuery(
    ["getPropertiesSearch", project?.project_id],
    async () =>
      await getPropertiesSearch({ projectId: project?.project_id ?? -1 }),
    {
      enabled: !!project?.project_id,
    }
  );

  const { data: amenities } = useQuery(
    ["getAmenitiesByProjectId", project?.project_id],
    async () => await getAmenitiesByProjectId(project?.project_id ?? -1),
    {
      enabled: !!project?.project_id,
    }
  );

  const lowestPrice = useMemo(
    () =>
      properties?.reduce(
        (acc, property) => {
          if (acc === undefined) {
            acc = property.precios.reduce(
              (acc, price) => (price.price_base < acc ? price.price_base : acc),
              property.precios[0]?.price_base
            );
          }

          return property.precios.reduce(
            (acc, price) => (price.price_base < acc ? price.price_base : acc),
            acc
          );
        },
        undefined as number | undefined
      ),
    [properties]
  );

  const amenityImages = useMemo(() => {
    return amenities?.amenities
      .map((amenity) => amenity.img_url)
      .filter((img): img is string => !!img);
  }, [amenities]);

  return (
    <Card className="rounded w-72 z-50">
      <CardHeader className="flex flex-row justify-end">
        <HiX size={16} onClick={onClose} className="cursor-pointer" />
      </CardHeader>
      <CardContent>
        <div
          className={`relative ${
            (amenityImages?.length ?? 0) > 0 ? "h-64" : "h-20"
          }`}
        >
          <Carousel>
            <CarouselContent>
              {amenityImages?.map((amenity, index) => (
                <CarouselItem key={index}>
                  <img
                    className={`object-cover aspect-square h-64`}
                    src={getResourceUrl(amenity)}
                  />
                </CarouselItem>
              ))}
            </CarouselContent>
            <CarouselNext
              className={
                (amenityImages?.length ?? 0) > 0 ? "right-1" : "hidden"
              }
            />
            <CarouselPrevious
              className={(amenityImages?.length ?? 0) > 0 ? "left-1" : "hidden"}
            />
          </Carousel>
          <img
            className={`absolute bottom-2 left-2 w-24 h-24 bg-white rounded-md object-contain p-2 border lg:block ${
              (amenityImages?.length ?? 0) > 0 ? "hidden" : ""
            }`}
            src={getResourceUrl(project?.logo_color)}
            alt={project?.project_name}
          />
        </div>
        <Link to={`/desarrollos/${toUrlCase(projectData?.short_name ?? "")}`}>
          <h6>{project?.project_name}</h6>
          {lowestPrice && lowestPrice > 0 ? (
            <div className="flex flex-row gap-2 mt-2">
              <h6 className="text-foreground/70 !text-sm lg:!text-base">
                {t("fromPrice")}
              </h6>
              <h6 className="text-primary">
                {new Intl.NumberFormat("es-MX", {
                  style: "currency",
                  currency: "MXN",
                }).format(lowestPrice)}
              </h6>
            </div>
          ) : (
            <h6 className="text-primary">{t("contactConsultant")}</h6>
          )}
        </Link>
      </CardContent>
    </Card>
  );
}
