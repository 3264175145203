import { BACKEND_URL } from "../constants";
import { type AuthorizationResponse } from "../models/authorization";
import { type LocationHierarchy } from "../models/location_hierarchy";
import { type Property } from "../models/property";
import { type SectionResponse } from "../models/section";
import { type UserAllFavoritesResponse } from "../models/user_all_favorites";
import { type WebsiteMedia } from "../models/website_media";
import { ApiError } from "../types/api_error";
import { type Blog } from "../models/blog";
import { type State } from "../models/state";
import { type LotesFormData } from "../models/lotes_form_data";
import { type Project } from "../models/project";
import { type AmenitiesResponse } from "@/models/amenities_response";
import { type PropertySearch } from "@/models/property_search";
import { type ProjectByFiltersResult } from "@/models/project_by_filters_result";
import { type StateCityFilters } from "@/models/state_city_filters";
import { type ModelsForHome } from "@/models/models_for_home";
import { type CustomerServiceReportFormData } from "@/models/customer_service_report_form_data";
import { type TerritorialReservationsFormData } from "@/models/territorial_reservations_form_data";

export async function postLotesForm(formData: LotesFormData) {
  return await fetch(`${BACKEND_URL}/lotes-form/lotes`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function postCustomerServiceReport(
  formData: CustomerServiceReportFormData
) {
  return await fetch(`${BACKEND_URL}/customer-support/`, {
    method: "POST",
    body: JSON.stringify({
      ...formData,
      street_number: "0",
    }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function postTerritorialReservationsForm(
  formData: TerritorialReservationsFormData
) {
  return await fetch(`${BACKEND_URL}/reservas-form/reservas`, {
    method: "POST",
    body: JSON.stringify(formData),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function getActiveSections() {
  return await fetch(`${BACKEND_URL}/secciones/all`).then<SectionResponse>(
    async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    }
  );
}

export async function getDocuments() {
  return await fetch(`${BACKEND_URL}/documentos/allByPages`).then(
    async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    }
  );
}

export async function getContratosAdhesion() {
  return await fetch(`${BACKEND_URL}/documentos/contratos_adhesion`).then(
    async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    }
  );
}

export async function getWebsiteMedia() {
  return await fetch(`${BACKEND_URL}/estilos`).then<WebsiteMedia[]>(
    async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    }
  );
}

export async function getInitialDataDesarrollos() {
  return await fetch(`${BACKEND_URL}/location/location_hierarchy`).then<
    LocationHierarchy[]
  >(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function loginUserAPI(email: string, password: string) {
  return await fetch(`${BACKEND_URL}/usuarios/login`, {
    method: "POST",
    body: JSON.stringify({ email, password }),
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  }).then<AuthorizationResponse>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function registerUserAPI(
  firstName: string,
  lastName: string,
  password: string,
  primaryEmail: string,
  primaryPhone?: string
) {
  return await fetch(`${BACKEND_URL}/usuarios/register`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      first_name: firstName,
      last_name: lastName,
      password,
      primary_email: primaryEmail,
      primary_phone: primaryPhone,
    }),
  }).then<AuthorizationResponse>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function refreshTokenAPI(token: string) {
  return await fetch(`${BACKEND_URL}/usuarios/refresh`, {
    method: "POST",
    headers: {
      token,
    },
  }).then(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function logoutUserAPI(token: string) {
  return await fetch(`${BACKEND_URL}/usuarios/logout`, {
    method: "POST",
    headers: {
      token,
    },
  }).then(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function getAllFavoritesUser(token: string) {
  return await fetch(`${BACKEND_URL}/usuarios/get_all_favorites`, {
    method: "POST",
    headers: {
      token,
    },
  }).then<UserAllFavoritesResponse>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function setFavoritesUserAPI(token: string, propertyId: number) {
  return await fetch(`${BACKEND_URL}/usuarios/manage_favorites`, {
    method: "POST",
    headers: {
      token,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ property_id: propertyId }),
  }).then<UserAllFavoritesResponse>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function getProjectByID(id: number) {
  return await fetch(`${BACKEND_URL}/proyectos/id/${id}`).then<Project>(
    async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    }
  );
}

export async function getProjects() {
  return await fetch(`${BACKEND_URL}/proyectos`).then<Project[]>(
    async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    }
  );
}

export async function getEstados() {
  return await fetch(`${BACKEND_URL}/estados`).then<State[]>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function getBlogByID(id: number) {
  return await fetch(`${BACKEND_URL}/blog/id/${id}`).then(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function getPropertiesSearch({
  stateId,
  projectId,
}: {
  stateId?: number;
  projectId: number;
}) {
  return await fetch(
    `${BACKEND_URL}/propiedades/properties_search?${
      stateId ? "state=" + stateId : "project=" + projectId
    }`
  )
    .then<PropertySearch[]>(async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    })
    .then((data) => {
      return data.map((item) => {
        const addressParts = item.full_address?.split(" ");
        const splitIndex = Math.floor(addressParts?.length / 2);
        return {
          ...item,
          video_url: item?.video_url ?? "",
          materport_url: item?.materport_video,
          address0: addressParts?.slice(0, splitIndex).join(" "),
          address1: addressParts?.slice(splitIndex).join(" "),
          delivery_status: item.delivery_status
            ? "Entrega inmediata"
            : "En Construccion",
          construction_status: item.construction_status
            ? "Lista para Habitar"
            : "En obra blanca",
        };
      });
    });
}

export async function getPropertyById(id: number) {
  return await fetch(`${BACKEND_URL}/propiedades/id/${id}`).then<Property>(
    async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    }
  );
}

export async function getBlogArticles() {
  return await fetch(`${BACKEND_URL}/blog/active-blogs`)
    .then<Blog[]>(async (res) => {
      if (res.ok) return await res.json();
      try {
        const data = await res.json();
        if ("error" in data && typeof data.error === "string")
          throw new ApiError(data.error as string, res.status);
        else throw new ApiError("Error desconocido", res.status);
      } catch (e) {
        throw new ApiError("Error desconocido", res.status);
      }
    })
    .then((data) => {
      return data.map((post) => {
        return {
          id: post.id,
          section: post.post_section,
          title: post.post_title,
          description: post.description,
          image: post.post_image1,
          date: post.post_date,
          author: post.post_author,
          content: post.post_content,
          url: post.permalink,
        };
      });
    });
}

export async function getAmenitiesByProjectId(projectId: number) {
  return await fetch(
    `${BACKEND_URL}/amenidades/get_all/${projectId}`
  ).then<AmenitiesResponse>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function getAllProjectsByFilters(filters?: {
  project_id?: number;
  state_id?: number;
  city_id?: number;
  price_min?: number;
  price_max?: number;
}) {
  return await fetch(
    `${BACKEND_URL}/proyectos/get-all-projects-by-filters?${
      filters
        ? new URLSearchParams(
            Object.entries(filters)
              .filter(([key, value]) => value !== undefined)
              .map(([key, value]) => [key, value.toString()])
          ).toString()
        : ""
    }`
  ).then<ProjectByFiltersResult[]>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function getAllStateCitiesFilters(filters?: {
  state_id?: number;
  city_id?: number;
}) {
  return await fetch(
    `${BACKEND_URL}/estados/get-all-states-cities-filters?${
      filters
        ? new URLSearchParams(
            Object.entries(filters).map(([key, value]) => [
              key,
              value.toString(),
            ])
          ).toString()
        : ""
    }`
  ).then<StateCityFilters[]>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}

export async function getAllPropertiesHome() {
  return await fetch(
    `${BACKEND_URL}/propiedades/get-all-properties-for-home`
  ).then<ModelsForHome[]>(async (res) => {
    if (res.ok) return await res.json();
    try {
      const data = await res.json();
      if ("error" in data && typeof data.error === "string")
        throw new ApiError(data.error as string, res.status);
      else throw new ApiError("Error desconocido", res.status);
    } catch (e) {
      throw new ApiError("Error desconocido", res.status);
    }
  });
}
