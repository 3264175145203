interface HouseMaintenanceProps {
  size?: number;
  className?: string;
}

export const HouseMaintenance = ({
  size = 100,
  className,
}: HouseMaintenanceProps) => {
  const aspectRatio = 405 / 325;

  const width = size;
  const height = width / aspectRatio;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={className}
      viewBox="0 0 405 325"
      fill="none"
    >
      <path
        fill="#C6C6C6"
        d="M201.384 90.746 0 207.775l202.664 117.008 201.388-117.029L201.384 90.746Z"
      />
      <path
        fill="#9B5042"
        d="M392.684 184.226 202.659 294.658 11.365 184.217 201.393 73.783l191.291 110.443Z"
      />
      <path
        fill="#7F362D"
        d="M11.365 184.217v22.782l191.294 110.442v-22.783L11.365 184.217Z"
      />
      <path
        fill="#661F1A"
        d="M202.659 294.658v22.783L392.684 207.01v-22.784L202.659 294.658Z"
      />
      <path
        fill="#7F362D"
        d="m242.991 221.948-20.137 11.701 42.603 24.509 20.138-11.7-42.604-24.51Z"
      />
      <path
        fill="#C6C6C6"
        d="M181.482 96.862 43.936 176.437l150.533 87.601c83.365-48.229-13.583 7.858 137.541-79.575L181.482 96.862Z"
      />
      <path
        fill="#BFBFBF"
        d="M196.953 255.947 56.374 174.25l-.074-27.545L196.875 228.4l.078 27.547Z"
      />
      <path
        fill="#AAA"
        d="m323.867 155.083.075 27.548-126.989 73.316-.078-27.547 126.992-73.317Z"
      />
      <path
        fill="#C6C6C6"
        d="m196.875 226.636-140.55-81.678.049 13.908 140.579 86.595 126.962-68.943-.048-23.199-126.992 73.317Z"
      />
      <path
        fill="#751616"
        d="M201.284 238.229 48.266 149.304l-.018-5.851 153.019 88.925.017 5.851Z"
      />
      <path
        fill="#8C2323"
        d="M201.267 232.378 48.248 143.453l130.727-75.476 153.018 88.924-130.726 75.477Z"
      />
      <path
        fill="#C6C6C6"
        d="M301.906 155.286 191.757 91.187l-30.417 17.596-10.698-6.226-73.46 42.502 110.149 64.1 68.912-39.87 10.702 6.228 34.961-20.231Z"
      />
      <path
        fill="#E2B979"
        d="m186.495 206.443 68.905-39.78 11.239-1.583 13.346-14.902-97.559-61.43-99.194 57.644 103.263 60.051Z"
      />
      <path
        fill="#C36B3A"
        d="m160.424 61.932 1.105-2.279-1.114-.648-22.799 12.994-.065-23.437 22.799-12.996 1.105-2.291-1.114-.648-22.799 12.994-.03-11.031 23.9-15.272-1.113-.648-13.434 7.586 1.058-24.696-1.114-.648-1.536-.033.061 26.263-7.829 4.522 1.04-25.861-1.114-.648-1.727-.041-1.732 2.037.074 26.511-44.145 25.484-3.895 4.596.23 80.872 3.452-1.991-.117-39.951 14.109-8.145c.013 4.311.104 35.688.113 39.951l1.114.648 16.233-10.663c-.013-4.261-.105-35.638-.113-39.951 4.087-2.361 5.915-3.415 13.14-7.586l.113 39.951 1.113.648 2.347-2.646-.113-39.953 22.797-12.994ZM89.56 99.744l-.065-23.438 14.109-8.146.065 23.439-14.11 8.145Zm-.074-26.379-.03-11.033 44.597-25.748.03 11.033-44.597 25.748Zm15.65-6.093 14.279-8.243.065 23.441-14.275 8.243-.069-23.441Zm.078 26.368 14.275-8.243.013 4.107-14.279 8.243-.009-4.107Zm.105 38.19-.091-32.337 14.279-8.243.091 32.334-14.279 8.246Zm15.698-50.246-.069-23.438 13.143-7.588.065 23.441-13.139 7.585Z"
      />
      <path
        fill="#AF582C"
        d="m161.538 62.58-.009-2.927-22.799 12.994-.065-23.436 22.799-12.996-.009-2.94-22.799 12.994-.03-11.031 22.794-12.996-.009-2.925-13.433 7.586-.057-25.345-1.536-.033.06 26.263-7.829 4.522-.074-26.509-1.728-.041-1.732 2.037.074 26.511-44.145 25.484-3.894 4.596.23 80.872 3.45-1.991-.116-39.951 14.109-8.145c.013 4.311.104 35.688.113 39.951l17.348-10.014c-.013-4.261-.104-35.638-.113-39.951 4.087-2.361 5.915-3.414 13.14-7.586l.112 39.951 3.461-1.998-.113-39.953 22.8-12.993Zm-70.864 37.812-.065-23.438 14.109-8.145.065 23.438-14.109 8.145ZM90.6 74.014l-.03-11.033 44.597-25.747.03 11.033L90.6 74.014Zm15.65-6.094 14.279-8.243.065 23.44-14.275 8.243-.069-23.44Zm.078 26.368 14.275-8.243.013 4.106-14.279 8.243-.009-4.106Zm.105 38.19-.092-32.337 14.28-8.243.091 32.334-14.279 8.246Zm15.698-50.246-.069-23.438 13.144-7.588.065 23.441-13.14 7.585Z"
      />
      <path
        fill="#590C0C"
        d="m331.993 156.901.017 5.853-130.726 75.475-.017-5.851 130.726-75.477Z"
      />
      <path
        fill="#55626E"
        d="m247.924 53.232-87.626-50.92.282 99.288 87.344 50.757V53.232Z"
      />
      <path
        fill="#667684"
        d="m243.653 159.797 22.931 13.327-.278-99.289-22.653-13.163v99.125Z"
      />
      <path
        fill="#8094A6"
        d="M262.439 74.32 136.563 3.065l36.262-.753L297.01 74.553l-34.571-.233Z"
      />
      <path
        fill="#475763"
        d="m266.306 74.347 30.704.206.231 80.87-30.657 17.701-.278-98.777Z"
      />
      <path
        fill="#D3A56A"
        d="M243.653 121.033v-9.923L160.58 62.836v-.157l-72.382 42.063 98.297 59.318 66.441-37.601-9.283-5.426Z"
      />
      <path
        fill="#E2B979"
        d="M243.653 94.601v-9.922L160.58 36.404v-.156L88.32 78.241l98.175 59.821 66.384-38.068-9.226-5.393Z"
      />
      <path
        fill="#C36B3A"
        d="M187.971 205.517c-.248-88.578-.069-19.55-.23-75.114l-3.447-2.006v4.548l-52.778-30.503-3.683-2.124-39.679-22.877V66.733l-3.678-2.139c.018 6.306 2.124 72.148 2.16 83.779l1.517-.901v-40.704l20.29 11.714-.057 42.538 1.476-.925.117-40.728 41.812 24.139-.061 42.721 1.475-.929.118-40.906 30.97 17.881v41.104l3.678 2.14Zm-60.139-78.751-39.679-22.879V80.333l39.679 22.879v23.554Zm3.683 2.122v-23.552l52.778 30.502v23.554l-52.778-30.504Z"
      />
      <path
        fill="#D88056"
        d="m254.172 126.063 1.105-2.278-1.114-.648-17.4 10.044-.065-23.436 17.401-10.047 1.105-2.291-1.114-.648-17.4 10.045-.03-11.03 18.501-12.323-1.114-.648-8.033 4.637 1.058-24.697-1.114-.648-1.537-.032.062 26.263-7.83 4.521 1.04-21.404-1.113-.648-1.728-.042-1.732 2.037.073 22.055-44.144 25.484-3.896 4.596.23 80.872 3.451-1.991-.117-39.95 14.109-8.146c.014 4.311.104 35.688.113 39.951l1.114.648 16.233-10.663c-.013-4.261-.104-35.638-.112-39.951 4.086-2.361 5.914-3.414 13.139-7.586l.113 39.951 1.114.648 2.346-2.646-.113-39.953 17.399-10.046Zm-65.466 34.863-.065-23.438 14.11-8.145.065 23.439-14.11 8.144Zm-.074-26.378-.03-11.033 44.597-25.748.03 11.033-44.597 25.748Zm15.65-6.093 14.28-8.243.064 23.441-14.274 8.243-.07-23.441Zm.08 26.368 14.275-8.243.014 4.106-14.279 8.243-.01-4.106Zm.104 38.19-.092-32.336 14.279-8.243.092 32.334-14.279 8.245Zm15.698-50.246-.07-23.438 13.144-7.588.066 23.441-13.14 7.585Z"
      />
      <path
        fill="#C36B3A"
        d="m255.286 126.712-.009-2.927-17.4 10.045-.065-23.437 17.4-10.047-.009-2.94-17.4 10.045-.03-11.03 17.396-10.047-.009-2.924-8.034 4.637-.056-25.345-1.537-.032.062 26.263-7.83 4.522-.074-22.053-1.728-.042-1.732 2.037.074 22.055-44.144 25.484-3.895 4.596.23 80.872 3.451-1.991-.117-39.95 14.109-8.145c.014 4.311.104 35.688.113 39.951l17.348-10.015c-.013-4.261-.104-35.638-.113-39.951 4.087-2.361 5.915-3.414 13.14-7.586l.113 39.951 3.46-1.998-.113-39.953 17.399-10.045Zm-65.466 34.863-.065-23.439 14.11-8.145.064 23.438-14.109 8.146Zm-.074-26.379-.03-11.033 44.597-25.747.03 11.033-44.597 25.747Zm15.651-6.093 14.28-8.243.064 23.441-14.274 8.243-.07-23.441Zm.079 26.368 14.274-8.243.014 4.106-14.28 8.243-.008-4.106Zm.103 38.19-.091-32.336 14.279-8.243.092 32.334-14.28 8.245Zm15.7-50.245-.07-23.438 13.144-7.588.065 23.441-13.139 7.585Z"
      />
      <path
        fill="#D88056"
        d="M186.495 206.443c-.248-88.577-.069-18.622-.23-74.187l-3.447-2.006v3.621l-52.778-30.502-3.683-2.124-39.679-22.878V67.659l-3.677-2.139c.017 6.306.195 69.241.23 80.872l3.446 2.006v-40.704l20.29 11.714-.113 40.721 1.532.893.117-40.728 41.812 24.14-.117 40.901 1.532.89.117-40.906 30.97 17.881v41.104l3.678 2.139Zm-60.138-78.75-39.679-22.879V81.26l39.679 22.879v23.554Zm3.682 2.122v-23.552l52.778 30.503v23.554l-52.778-30.505Z"
      />
      <path
        fill="#994520"
        d="m137.562 3.065-3.654 4.309 124.877 72.555 3.654-4.308V74.32L137.562 3.065Z"
      />
      <path
        fill="#AF582C"
        d="m137.562 1.765-3.654 4.309 124.877 72.555 3.654-4.309L137.562 1.765Z"
      />
      <path
        fill="#994520"
        d="m120.327 23.487-3.654 4.309 124.877 72.555 3.654-4.309v-1.3L120.327 23.487Z"
      />
      <path
        fill="#AF582C"
        d="m120.327 22.187-3.654 4.309L241.55 99.05l3.654-4.308-124.877-72.555Z"
      />
      <path
        fill="#994520"
        d="m103.095 43.909-3.654 4.308 124.877 72.555 3.654-4.309v-1.3L103.095 43.909Z"
      />
      <path
        fill="#AF582C"
        d="m103.095 42.608-3.654 4.309 124.877 72.555 3.654-4.309-124.877-72.555Z"
      />
      <path
        fill="#994520"
        d="m85.861 64.33-3.655 4.309 124.877 72.555 3.654-4.309v-1.3L85.861 64.33Z"
      />
      <path
        fill="#AF582C"
        d="m85.861 63.03-3.655 4.308 124.877 72.555 3.654-4.308L85.861 63.03Z"
      />
      <path
        fill="#BA552D"
        d="m314.609 64.034-72.996-2.033-69.077 79.763v1.706l3.693 2.165 69.075-81.468 69.305 1.573v-1.706Z"
      />
      <path
        fill="#D88056"
        d="m310.901 61.869-69.288-1.574-69.077 81.469 3.693 2.165 69.075-81.468 69.305 1.573-3.708-2.165Z"
      />
      <path
        fill="#BA552D"
        d="m297.297 53.985-72.994-2.033-69.08 79.763v1.706l3.693 2.165 69.077-81.468 69.304 1.573v-1.706Z"
      />
      <path
        fill="#D88056"
        d="m293.589 51.82-69.286-1.574-69.08 81.469 3.693 2.165 69.077-81.468 69.304 1.573-3.708-2.165Z"
      />
      <path
        fill="#BA552D"
        d="m279.985 43.935-72.996-2.032-69.077 79.763v1.706l3.693 2.165 69.076-81.469 69.304 1.574v-1.707Z"
      />
      <path
        fill="#D88056"
        d="m276.277 41.77-69.288-1.573-69.077 81.469 3.693 2.164 69.076-81.468 69.304 1.573-3.708-2.165Z"
      />
      <path
        fill="#BA552D"
        d="m262.674 33.887-72.995-2.033-69.077 79.762v1.706l3.69 2.166 69.077-81.469 69.305 1.574v-1.706Z"
      />
      <path
        fill="#D88056"
        d="m258.966 31.721-69.287-1.573-69.077 81.468 3.69 2.166 69.077-81.469 69.305 1.574-3.708-2.166Z"
      />
      <path
        fill="#BA552D"
        d="m245.362 23.837-72.996-2.032-69.078 79.762v1.706l3.691 2.165 69.078-81.468 69.305 1.573v-1.706Z"
      />
      <path
        fill="#D88056"
        d="m241.654 21.672-69.288-1.573-69.078 81.468 3.691 2.165 69.078-81.468 69.305 1.573-3.708-2.165Z"
      />
      <path
        fill="#BA552D"
        d="m228.05 13.788-72.997-2.032-69.077 79.762v1.706l3.69 2.165 69.077-81.468 69.307 1.573v-1.706Z"
      />
      <path
        fill="#D88056"
        d="m224.342 11.623-69.289-1.573-69.077 81.468 3.69 2.165 69.077-81.468 69.307 1.573-3.708-2.165Z"
      />
      <path
        fill="#BA552D"
        d="M210.737 3.739 137.74 1.706 68.662 81.469v1.706l3.694 2.165 69.077-81.468 69.304 1.573V3.739Z"
      />
      <path
        fill="#D88056"
        d="M207.029 1.574 137.74 0 68.662 81.469l3.694 2.165 69.077-81.468 69.304 1.573-3.708-2.165Z"
      />
    </svg>
  );
};
