import { type Translation } from "@/types/translation";

const en: Translation = {
  homeStart: "Home",
  find: "Find",
  yourNew: "Your new",
  home: "home",
  weHaveSpaces: "we have spaces",
  toLiveInHarmony: "for you  to live happily and in harmony",
  state: "State",
  developments: "Developments",
  us: "About us",
  plots: "Comercial plots",
  contact: "Contact",
  javerBlog: "JAVER blog",
  company: "Company",
  information: "Information",
  socialMedia: "Social media",
  certifications: "Certifications and awards",
  complaintSystem: "Ethical Complaint System",
  javerPrivacyNotices: "JAVER's privacy notices",
  customerService: "Customer service",
  decalogues: "JAVER's decalogues",
  contracts: "Contracts",
  investors: "Investors",
  providers: "Providers",
  jobOpportunities: "Job opportunities",
  landReserves: "Land reserves",
  commercialPlots: "Commercial Plots",
  followUs: "Follow us",
  askAbout: "Ask about your new home",
  provideDetails: "Provide your details and a consultant will contact you.",
  names: "Name(s)",
  lastNames: "Last Name(s)",
  email: "Email",
  dob: "Date of birth",
  phone: "Phone",
  fractioning: "Subdivision",
  creditType: "Credit type",
  message: "Message",
  messageExample: "Ex. I'm interested in a property in Nuevo León",
  acceptPolicies: "I accept the privacy policies",
  privacyNotices: "Privacy notices",
  checkPrivacyNotices: "Read privacy policies",
  submit: "Submit",
  selectOption: "Select an option",
  plotSale: "Commercial plots sale",
  plotSaleSubtitle: "Ask about our available spaces!",
  states: "States",
  squareMeters: "Square meters",
  plotSaleDetails:
    " offers strategically located commercial lots from 200 to 10,000 square meters fully developed and with highly competitive prices. ",
  callUs: " CALL US!",
  emailUs: " EMAIL US!",
  contactConsultant: " Contact a consultant",

  aboutUsTitle: "We have over 50 years of experience",
  aboutUsSubtitle1: "Meet",
  aboutUsSubtitle2: "Mision, vision and values.",
  aboutUs1:
    " a real estate developer that began 50 years ago, today has a significant presence in the low-income, middle-income and residential sectors, and in recent years has also ventured into vertical housing. It is present in Nuevo León, Jalisco, Aguascalientes, Querétaro, Estado de México, Quintana Roo, Puebla and Tamaulipas.",
  aboutUs2:
    "The company was founded 50 years ago by Mr. Salomón Marcuschamer, and has managed to remain at the forefront, offering housing solutions according to the needs of the market, which has led Javer to be an industry leader and sell more than 300,000 homes, seeking to offer developments planned for comfort and family coexistence.",
  aboutUs3:
    "Clients can feel confident that they are acquiring the best investment for their family. They currently have projects in the Mexican Republic designed for the different needs of each market and segment, which are distinguished by their location, equipment, green areas and recreational spaces that improve the quality of life and add value to the assets of its customers, the personalized and certified advice of the sales team is an essential element in Javer, since from the first contact until the signing of the deed they are present to make the process of your next home is unsurpassed.",
  aboutUs4Start: "As of January 2016,",
  aboutUs4End:
    " is listed on the Mexican Stock Exchange, has a very robust corporate governance and is highly committed to its stakeholders. For this reason, it manages various activities based on its social responsibility model aligned with the ISO26000 standard. And to the Sustainable Development Goals. As a result, for the seventh consecutive year, JAVER has received the Socially Responsible Company distinction and the Great Place to Work certification. For years, JAVER has accompanied thousands of Mexican families on the road to their wealth. For this reason, we invite you to begin a new story and start your new home with ",
  mision: "Mision",
  misionContent:
    "To offer quality housing solutions to satisfy the housing and property needs of our clients, based on our values and human capital.",
  vision: "Vision",
  visionContent:
    "To be a leading company in its field, adapting in a timely manner to the changing needs of the market, maximizing the expectations of each of our stakeholders in a sustainable manner.",
  values: "Valores",
  valuesContent:
    "Integrity: We are consistent with our principles and values, acting with transparency and honesty. \n\nPassion: We make the ordinary extraordinary, putting our stamp on it and doing things right the first time. \n\nRespect: We promote a positive work and business environment in which empathy and equity are fostered.\n\n Sustainability: We manage the efficient use of resources, generating social, economic and environmental value over time, triggering communities and setting the course for their development: We constantly evaluate what we do, maintaining openness to creative ideas that allow us to be agile in adapting to change and decision making. \n\nIdentity: We generate a sense of belonging and commitment of human talent through the development of their capabilities and aspirations, coordinating efforts and talents to achieve objectives.",
  cities: "Cities",
  carouselTitle: "Your dream home",
  defaultDescription:
    "Spaces designed for your needs and those of your family.",
  noResults: "We did not find properties for your search",
  loading: "Loading...",
  virtualTour: "Virtual tour",
  architecturalPlans: "Architectural Plans",
  amenities: "Amenities / Equipment",
  priceList: "Price list",
  priceListTitle: "Ask about our discounts and promotions at the booth.",
  startingPrices: "Starting prices",
  squareMeterPrice: "Prices per m2 of surplus land",
  priceListNote:
    "In credit operations, the variable amounts of credit concepts and notary expenses will be added to the total price of the purchase and sale operation. Prices apply only to homes for deed until {{endDate}}. Prices subject to availability and changes without prior notice. A 3% increase will be added to the total price of the homes located in front of a park. An increase of 3% will be added to the total price of the homes that are located on a corner.",
  parkingLots_one: "{{count}} parking",
  parkingLots_other: "{{count}} parkings",
  garageCapacity_one: "{{count}} garage",
  garageCapacity_other: "{{count}} garages",
  bedrooms_one: "{{count}} bedroom",
  bedrooms_other: "{{count}} bedrooms",
  bathrooms_one: "{{count}} bathroom",
  bathrooms_other: "{{count}} bathrooms",
  blueprintHint: "Double click to zoom",
  landReservesTitle:
    "Do you have land for sale? Contact us if you have land you want to sell",
  landReservesDescription:
    "At JAVER we develop integral housing communities with urban and commercial equipment. We want to provide our clients with a comfortable space, services and quality of life. That is why we want to work together to offer sustainable developments adapted to each space. For Javer, confidentiality is paramount, when you contact us, your data will be securely preserved and you will be in contact exclusively with the Land Reserves area.",
  personalData: "Personal data",
  landData: "Land data",
  postalCode: "Postal code",
  hectare: "Hectare",
  squareMeterPriceLabel: "Price per square meter",
  description: "Description",
  dialAt: "Dial at",
  forMoreInfo: "for more information.",
  phoneContactSchedule: "(Monday to Friday from 9AM to 8PM).",
  certificationsTitle: "Is recognized as one of the best places to work.",
  fromPrice: "From*",
  contactUs: "Contact us",
  knowTheModels: "Know the models",
  areasOfInterest: "Areas of interest / Services",
  equipment: "Equipment",
  models: "Models",
  liveTheFullExperience: "Live the full experience",
  location: "Location",
  creditTypes: "Credit types",
  moreInfo: "More info",
  continueInGoogleMaps: "Continue in Google Maps",
  continueInWaze: "Continue in Waze",
  howToGetHere: "How to get here?",
  noPrices: "No prices",
  housingDevelopmentContactTitle: "Make your dream home come true",
  housingDevelopmentContactDescription:
    "We are here to help you whenever you need it, we will gladly resolve that concern you have.",
  features: "Features",
  knowMore: "Know more",
  photoGallery: "Photo gallery",
  save: "Save",
  countMore: "+{{count}} more",
  seeMap: "See location",
  seeMore: "See more",
  seeAll: "See all",
  zones: "Zones",
  housingDevelopments: "Housing developments",
  priceMoreInfoTitle: "Ask about our discounts and promotions at the booth.",
  priceMoreInfoDisclaimer:
    "*In credit operations, the total price of the purchase operation will include variable amounts of credit concepts and notary expenses. Prices apply only to homes for deed until {{lastDate}}. Prices are subject to availability and may change without prior notice. A 3% increase will be added to the total price of homes located in front of a park. A 3% increase will be added to the total price of homes located on a corner.",
  seeList: "See list",
  allZones: "All zones",
  priceFrom: "Price from",
  priceM2Extra: "Price per m² of surplus land",
  allPrices: "All prices",
  attentionHours: "Attention hours",
  attentionHoursContent: "Monday to Sunday from 9:00 a.m. to 7:00 p.m.",
  floors_other: "{{count}} floors",
  landArea_other: "{{count}} m²",
  rooms_other: "{{count}} rooms",
  login: "Login",
  back: "Back",
  logout: "Logout",
  myFavorites: "My favorites",
  outstandingProjects: "Outstanding Developments",
  exploreDifferentModels: "Explore different models",
  familiesInTheCountry: "homes with EDGE certification",
  housesAndApartments: "houses and apartments",
  investorsHaveHad: "Recycled furniture",
  javerHasHelpedMoreThan: "More than",
  weHaveBuiltMoreThan: "We have built more than",
  ofBenefits: "installed pieces",
  statesWithinTheCountry: "states within the country",
  weHavePresenceInMoreThan: "We have presence in more than",
  know: "Know",
  javer: "JAVER",
  knowJaverDescription:
    "At Javer, we are dedicated to developing innovative, sustainable homes designed to create ideal communities for the well-being of families in Mexico. Thanks to the experience of our company and the work of our experts, you can be sure that here you will find the heritage you have always desired.",
  learnAboutTheExperienceOfOurClients:
    "Learn about the experience of our clients",
  learnAboutOurRealEstate: "Frequently Asked Questions",
  learnAboutOurRealEstateDescription:
    "It's normal to have doubts about such important purchases as your next house. If your question isn't answered, feel free to contact us through the various channels we provide. We are here to help you.",
  makeTheHomeOfYourDreamsComeTrue: "Make the home of your dreams come true",
  seeTheEntireState: "See the entire state",
  findYourNewHome: "Find your new home",
  findYourNewHomeDescription:
    "We have spaces for you to live happily and in harmony.",
  selectState: "Select state",
  selectZone: "Select zone",
  selectPrice: "Price range",
  search: "Search",
  orderPrice: "Order price",
  viewBy: "View by",
  clients: "Customers",
  becomeAnInvestor: "Become an investor",
  stateOfInterest: "State of interest",
  returnToHome: "Return to home",
  thanksTitle: "Thank you!",
  thanksMessage: "Shortly we will contact you to provide more information.",
  restrooms_other: "{{count}} half baths",
  noDevelopmentsFound: "There are no results to show",
  viewAllPrices: "View all prices",
  floors_one: "{{count}} floor",
  landArea_one: "{{count}} m²",
  rooms_one: "{{count}} room",
  restrooms_one: "{{count}} half bath",
  contactUsInCustomerService: "Contact us in customer service",
  inMaintenance: "In maintenance...",
  inMaintenanceMessage:
    "WE ARE HERE TO HELP YOU AND TAKE CARE OF YOUR HERITAGE.",
  downloadTipsForMaintenance: "Download maintenance guide",
  tipsForMaintenance: "Maintenance tips for your home",
  tipsForMaintenanceDescription:
    "Download our PDF guide for the care and maintenance of your new home.",
  makeReport: "Send a report",
  makeReportDescription:
    "If you have any questions or problems with your home, let us know.",
  streetAndNumber: "Street and number",
  block: "Block",
  lot: "Lot",
  subject: "Subject",
  accept: "Accept",
  codeOfEthics: "Code of ethics",
  codeOfEthicsPageDescription:
    "Welcome to JAVER's Code of Ethics. This code reflects the fundamental values and principles that guide our actions and decisions on a daily basis. By following this code, we are committed to maintaining the highest standards of integrity, accountability and respect in all our interactions and operations.",
  codeOfEthicsCheckboxMessage:
    "By signing and sending the document, I commit to comply with the values and principles established here.",
  codeOfEthicsNote:
    "At the end of reading this Code of Ethics, each employee and/or provider must download a copy, read it carefully, understand its content and then send the signed document accepting the conditions and clicking the following button:",
  important: "Important",
  downloadCodeOfEthics: "Download Code of Ethics",
  sendCodeOfEthics: "Send Code of Ethics",
};

export default en;
