import { Link } from "react-router-dom";
import { Line } from "../Line";
import { Text } from "../Text";

export const BlogMenu = (props) => {
  return (
    <div className="absolute bg-black/60 rounded-lg  h-full inset-[0] items-center justify-center m-auto pb-[57px] md:px-10 px-5 md:px-[57px] w-full">
      <div className="flex flex-col items-center justify-start mb-[583px] w-[99%] md:w-full">
        <div className="flex flex-col items-center justify-end pt-[9px] w-full">
          <div className="flex flex-col items-center justify-start w-full">
            <div
              className="flex text-center my-5 flex-col md:flex-row md:gap-10 items-center justify-between w-full"
              style={{ height: "2em" }}
            >
              <Text
                className="md:text-5xl text-4xl text-gray-50 uppercase"
                size="txtVigaRegular60"
              >
                <span className="text-gray-50 font-viga text-left font-normal">
                  BLOG{" "}
                </span>
                <span className="text-primary font-viga text-left font-normal">
                  JAVER
                </span>
              </Text>
              <div className="flex flex-col mt-10 md:mt-0  text-lg md:text-xs font-bold md:flex-row sm:gap-5 items-center justify-end p-3">
                <Link
                  to="/blog-list/noticias"
                  className="md:mt-0   text-center text-gray-50 uppercase"
                  size="txtRobotoRomanBold10"
                >
                  {props?.noticiastext}
                </Link>
                {/* <Link */}
                {/*   to="/blog-list/finanzas" */}
                {/*   className="ml-[0] md:ml-[53px] mt-0 md:my-[5px] text-[10px] text-center text-gray-50 uppercase" */}
                {/*   size="txtRobotoRomanBold10" */}
                {/* > */}
                {/*   {props?.finanzastext} */}
                {/* </Link> */}
                <Link
                  to="/blog-list/hogar-javer"
                  className="md:ml-10 ml-[0] mt-5 md:mt-0 text-center text-gray-50 uppercase"
                  size="txtRobotoRomanBold10"
                >
                  {props?.tuhogarjavertext}
                </Link>
                <Link
                  to="/blog-list/responsabilidad"
                  className="ml-[0] md:ml-[42px] mt-5 md:mt-0  md:my-[5px]  text-center text-gray-50 uppercase"
                  size="txtRobotoRomanBold10"
                >
                  {props?.rsetext}
                </Link>
                <Link
                  to="/blog-list/archivo"
                  className="ml-[0] md:ml-[52px]  mt-5 md:mt-0  text-center text-gray-50 uppercase"
                  size="txtRobotoRomanBold10"
                >
                  {props?.archivotext}
                </Link>
              </div>
            </div>
            <Line className="bg-primary h-px w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

BlogMenu.defaultProps = {
  noticiastext: "NOTICIAS",
  finanzastext: "FINANZAS",
  tuhogarjavertext: "TU HOGAR javer",
  rsetext: "RSE",
  archivotext: "ARCHIVO",
  vermastext: "TU HOGAR JAVER",
  cheapairlinetic: "Puebla, hogar lleno de historia, vida y estilo.",
  description:
    "La ciudad de Puebla, ubicada en el centro de México, es un lugar que combina historia, cultura y modernidad de manera única.",
  violamanisa: "Por Javer",
  p02may: "11 octubre 2023",
  recentarticles: "Últimas novedades",
  vermaslabel: "Ver más",
  futureofworktext: "Querétaro, tu mejor inversión.",
  descriptiontext:
    "El mercado inmobiliario en México ofrece una amplia gama de oportunidades para la inversión, uno de los destinos más prometedores es el estado de Querétaro.",
  linahickstext: "Por Javer",
  datetext: "11 septiembre 2023",
  futureofworktext1:
    "Así se vivió la inauguración del primer vivero Javer en Jalisco.",
  descriptiontext1: (
    <Text
      className="mt-[27px] text-sm text-gray-50 w-full"
      size="txtRobotoRomanRegular14"
    >
      <span className="text-gray-50 font-roboto text-left font-normal">
        El pasado 23 de junio se llevó a cabo la inauguración de nuestro primer
        vivero{" "}
      </span>
      <a
        href="javascript:"
        className="text-gray-50 font-roboto text-left font-normal underline"
      >
        Javer{" "}
      </a>
      <span className="text-gray-50 font-roboto text-left font-normal">
        en Jalisco, el quinto a nivel nacional.
      </span>
    </Text>
  ),
  linahickstext1: "Por Javer",
  datetext1: "10 julio 2023",
  futureofworktext2: "Javer, gran empresa mexicana.",
  languagetext: (
    <Text
      className="mt-[29px] text-sm text-gray-50 w-full"
      size="txtRobotoRomanRegular14"
    >
      <span className="text-gray-50 font-roboto text-left font-normal">
        Año con año buscamos posicionarnos y dar a conocer los programas que
        hacen de Javer una{" "}
      </span>
      <a
        href="javascript:"
        className="text-gray-50 font-roboto text-left font-normal underline"
      >
        Empresa Responsable
      </a>
      <span className="text-gray-50 font-roboto text-left font-normal">. </span>
    </Text>
  ),
  linahickstext2: "Por Javer",
  datetext2: "19 septiembre 2023",
};
