import "./Equipo.css";

function Equipo() {
  return (
    <div className="equipo-container">
      <div className="equipo-scroller">
        <div className="equipo-section">
          <div className="equipo-team-image">
            <div className="flex-grow-port-only"></div>
            <div className="equipo-title">
              <h1>UNETE A NUESTRO</h1>
              <h1>
                EQUIPO <span>JAVER</span>
              </h1>
              <h2>¡Te estamos esperando!</h2>
            </div>
            <div className="flex-grow-port-only"></div>
            <div className="equipo-search"></div>
            <sub>¿No encuentras lo que buscas?</sub>
            <sub>
              Únete a nuestra comunidad de talentos y recibe notificaciones de
              nuevas oportunidades de trabajo.
            </sub>
            <div className="flex-grow-port-only"></div>
            <button>¡ÚNETE A NUESTRA COMUNIDAD DE TALENTOS!</button>
          </div>
        </div>
        {/* <div className="equipo-section"></div> */}
      </div>
    </div>
  );
}

export default Equipo;
