import { Link } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import { Img } from "../Img";
import { Text } from "../Text";
import PropTypes from "prop-types";

export const BlogCarousel = ({ article }) => {
  return (
    <Link
      to={"/blog/" + article?.id}
      className="absolute bottom-[0] inset-x-[0] mx-auto shadow-md md:w-[90%] w-full"
    >
      <div className="bg-gray-50 border border-indigo-50 border-solid h-[380px] m-auto rounded-b-lg md:rounded-none  shadow-md w-full"></div>
      <Img
        className="hidden md:block absolute brightness-50 md:brightness-100 h-[380px] inset-y-[0] left-[0] my-auto object-cover  w-full md:w-1/2"
        src={article?.image}
        alt="imageTwentySix"
      />
      <div className="absolute flex flex-col gap-[19px] h-max inset-y-[0] items-start justify-start my-auto right-[5%] md:w-[42%] p-5 ">
        <div className="brightness-100">
          <div className="blog-red-title">TU HOGAR JAVER</div>
          <Text
            className="leading-[45.00px] text-4xl md:text-3xl  text-gray-900 w-[97%] xs:w-full"
            size="txtRobotoRomanSemiBold40"
          >
            {article?.title}
          </Text>
          <Text
            className="leading-[32.00px] mt-5 text-gray-900 text-xl md:text-lg w-full"
            size="txtRobotoRomanRegular20"
          >
            {article?.description}
          </Text>
          <div className="flex flex-row items-center justify-between w-full">
            <div className="flex flex-col items-start justify-end pr-[9px] py-[9px]">
              <Text
                className="italic mt-1 text-base text-slate-800"
                size="txtOpenSansItalicRegular16"
              >
                Por Javer
              </Text>
            </div>
            <Text
              className="text-base text-slate-600 text-right"
              size="txtOpenSansSemiBold16"
            >
              {formatDate(article?.date)}
            </Text>
          </div>
        </div>
      </div>
    </Link>
  );
};

BlogCarousel.propTypes = {
  article: PropTypes.any,
};
