export const sortPrices: Array<{
  min: number;
  max: number | null;
}> = [
  {
    min: 0,
    max: 800000,
  },
  {
    min: 800000,
    max: 1200000,
  },
  {
    min: 1200000,
    max: 1600000,
  },
  {
    min: 1600000,
    max: 2200000,
  },
  {
    min: 2200000,
    max: 3000000,
  },
  {
    min: 3000000,
    max: 3800000,
  },
  {
    min: 3800000,
    max: null,
  },
];
