import { Gallery } from "@/components/Gallery";
import { GridList } from "@/components/GridList";
import i18n from "@/i18n";
import { getAmenitiesByProjectId } from "@/utils/api";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";

interface HousingDevelopmentAmenitiesProps {
  projectId?: number;
}

export function HousingDevelopmentAmenities({
  projectId,
}: HousingDevelopmentAmenitiesProps) {
  const { t } = useTranslation("translations");

  const { data: amenities } = useQuery(
    ["getAmenitiesByProjectId", projectId],
    async () => await getAmenitiesByProjectId(projectId ?? -1)
  );

  return (
    <>
      {amenities?.amenities && amenities.amenities.length > 0 && (
        <>
          <section className="container xl:max-w-5xl mx-auto mt-16">
            <h4>{t("amenities")}</h4>
            <hr className="my-6 border-primary" />
            <GridList
              items={
                amenities.amenities.map((amenity) =>
                  i18n.language === "es" ? amenity.name : amenity.name_eng
                ) ?? []
              }
            />
          </section>
          <section className="mt-16">
            <Gallery
              images={amenities.amenities
                .map((amenity) => amenity.img_url)
                .filter<string>((imgUrl): imgUrl is string => !!imgUrl)}
              type="carousel"
              classNames={{
                Carousel: {
                  Item: "h-full w-full sm:basis-1/2 md:basis-1/3 2xl:basis-1/4",
                },
              }}
            />
          </section>
        </>
      )}
    </>
  );
}
