import { type LocationHierarchy } from "@/models/location_hierarchy";
import { toUrlCase } from "@/utils/common.utils";
import { ChevronRight } from "lucide-react";
import { useTranslation } from "react-i18next";
import { HiArrowRight } from "react-icons/hi2";
import { Link } from "react-router-dom";
import { ScrollArea } from "./ui/scroll-area";
import { Button } from "./ui/button";

interface DesktopMenuProps {
  className?: string;
  dataDesarrollos: LocationHierarchy[];
  cities?: LocationHierarchy["ciudades"] | null;
  projects?: LocationHierarchy["ciudades"][number]["proyectos"] | null;
  selectedCity: number | null;
  setSelectedCity: (id: number | null) => void;
  selectedState: number | null;
  setSelectedState: (id: number | null) => void;
  setIsMenuOpen: (open: boolean) => void;
}

export function DesktopMenu({
  className,
  dataDesarrollos,
  cities,
  projects,
  selectedCity,
  setSelectedCity,
  selectedState,
  setSelectedState,
  setIsMenuOpen,
}: DesktopMenuProps) {
  const { t } = useTranslation("translations");
  return (
    <div className={`w-full flex flex-row h-full ${className}`}>
      <ScrollArea className={`pl-10 pt-4 bg-neutral-100 h-full w-1/3`}>
        <h6 className="mx-8 border-l-[1px] border-foreground px-4">
          {t("states")}
        </h6>
        <div className="ml-10 mt-4">
          {dataDesarrollos.map((item) => (
            <div
              key={item.id}
              onMouseEnter={() => {
                if (selectedState === item.id) {
                  setSelectedState(null);
                } else {
                  setSelectedState(item.id);
                }

                setSelectedCity(null);
              }}
              className={`transition-all font-viga text-foreground-soft px-4 py-2 hover:text-accent cursor-pointer flex flex-row justify-between items-center ${
                selectedState === item.id ? "!text-accent ml-2" : ""
              }`}
            >
              {item.name}
              {selectedState === item.id && (
                <div>
                  <ChevronRight className="h-8 w-8 inline-block" />
                  <ChevronRight className="h-8 w-8 inline-block" />
                </div>
              )}
            </div>
          ))}
        </div>
      </ScrollArea>
      <ScrollArea className={`pl-10 pt-4 border-l h-full w-1/3`}>
        <h6 className="mx-8 border-l-[1px] border-foreground px-4">
          {t("zones")}
        </h6>
        <div className="ml-10 mt-4">
          {cities?.map((item) => (
            <div
              key={item.id}
              onMouseEnter={() => {
                if (selectedCity === item.id) {
                  setSelectedCity(null);
                } else {
                  setSelectedCity(item.id);
                }
              }}
              className={`transition-all font-viga text-foreground-soft px-4 py-2 hover:text-accent cursor-pointer flex flex-row justify-between items-center ${
                selectedCity === item.id ? "!text-accent ml-2" : ""
              }`}
            >
              {item.name}
              {selectedCity === item.id && (
                <div>
                  <ChevronRight className="h-8 w-8 inline-block" />
                  <ChevronRight className="h-8 w-8 inline-block" />
                </div>
              )}
            </div>
          ))}
          {cities && (
            <Button className="mt-4" variant="ghost" asChild>
              <Link
                to={`/estados/${toUrlCase(
                  dataDesarrollos.find((item) => item.id === selectedState)
                    ?.name ?? ""
                )}`}
                onClick={() => {
                  setIsMenuOpen(false);
                }}
              >
                {t("seeAll")}
                <HiArrowRight className="ml-2" />
              </Link>
            </Button>
          )}
        </div>
      </ScrollArea>
      <ScrollArea className={`pl-10 pt-4 border-l h-full transition-all w-1/3`}>
        <h6 className="mx-8 border-l-[1px] border-foreground px-4">
          {t("housingDevelopments")}
        </h6>
        <div className="ml-10 mt-4">
          {/* {cities?.find((item) => item.id === selectedCity)?.name === // TODO: Descomentar cuando se quiera habilitar el link a Caranday JP
            "Aguascalientes" && (
            <a
              href="https://residencialcaranday.com/"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                setIsMenuOpen(false);
              }}
              className={`transition-all font-viga text-foreground-soft px-4 py-2 hover:text-accent cursor-pointer hover:underline block`}
            >
              Caranday JP
            </a>
          )} */}
          {projects?.map((item) => (
            <Link
              to={`/desarrollos/${toUrlCase(item.short_name)}`}
              key={item.id}
              onClick={() => {
                setIsMenuOpen(false);
              }}
              className={`transition-all font-viga text-foreground-soft px-4 py-2 hover:text-accent cursor-pointer hover:underline block`}
            >
              {item.name}
            </Link>
          ))}
          {projects && (
            <Button className="mt-4" variant="ghost" asChild>
              <Link
                to={`/estados/${toUrlCase(
                  dataDesarrollos.find((item) => item.id === selectedState)
                    ?.name ?? ""
                )}?zona=${toUrlCase(
                  cities?.find((item) => item.id === selectedCity)?.name ?? ""
                )}`}
                onClick={() => {
                  setIsMenuOpen(false);
                }}
              >
                {t("seeAll")}
                <HiArrowRight className="ml-2" />
              </Link>
            </Button>
          )}
        </div>
      </ScrollArea>
    </div>
  );
}
