const sizeClasses = {
  txtRobotoRomanBold10: "font-bold font-roboto",
  txtRobotoRomanRegular8Bluegray10001: "font-normal font-roboto",
  txtRobotoItalicRegular8: "font-normal font-roboto italic",
  txtRobotoRomanBold10Gray500: "font-bold font-roboto",
  txtRobotoRomanSemiBold28: "font-roboto font-semibold",
  txtOpenSansBold16Red900: "font-bold font-sans",
  txtRobotoRomanBold10Gray900: "font-bold font-roboto",
  txtOpenSansBold16: "font-bold font-sans",
  txtRobotoRomanRegular6: "font-normal font-roboto",
  txtRobotoRomanRegular8: "font-normal font-roboto",
  txtVigaRegular60: "font-normal font-viga",
  txtRobotoRomanSemiBold40: "font-roboto font-semibold",
  txtOpenSansItalicRegular16: "font-normal font-sans italic",
  txtOpenSansSemiBold16: "font-sans font-semibold",
  txtOpenSansItalicRegular12: "font-normal font-sans italic",
  txtOpenSansSemiBold12: "font-sans font-semibold",
  txtRobotoRomanRegular10: "font-normal font-roboto",
  txtRobotoRomanSemiBold12: "font-roboto font-semibold",
  txtRobotoRomanRegular20: "font-normal font-roboto",
  txtRobotoRomanRegular12: "font-normal font-roboto",
  txtRobotoRomanRegular14: "font-normal font-roboto",
  txtRobotoRomanBold36: "font-bold font-roboto",
  txtRobotoRomanBold25: "font-bold font-roboto",
};

interface TextProps {
  children: React.ReactNode;
  className?: string;
  size?: keyof typeof sizeClasses;
  as?: keyof JSX.IntrinsicElements;
}

export const Text = ({
  children,
  className = "",
  size,
  as,
  ...restProps
}: TextProps) => {
  const Component = as ?? "p";

  return (
    <Component
      className={`text-left ${className} ${size && sizeClasses[size]}`}
      {...restProps}
    >
      {children}
    </Component>
  );
};
