import { ImageWithText } from "../shared-components/ImageText";
import { Mark } from "../shared-components/Mark";
import { Title } from "../shared-components/Title";
import { useAppContext } from "../data/DataProvider";

const decalogues = [
  {
    title: "Decálogo de derechos humanos",
    items: [
      {
        content: "Decálogo de Derechos Humanos ",
        url: "/archivos/CodigoDeEticaJAVER2020_83cd.pdf",
      },
    ],
  },
  {
    title: "Decálogo para la no discriminación de clientes ",
    items: [
      {
        content: "Decálogo para la no discriminación de clientes ",
        url: "/decalogo-de-no-discriminacion/",
      },
    ],
  },
];

export const Decalogues = () => {
  const { websiteMedia } = useAppContext();

  return (
    <div className="my-10 mx-2 sm:mx-10  xl:mx-40 md:min-h-[70vh] ">
      <Title>
        Decálogos <Mark>JAVER</Mark>
      </Title>
      <div className="mt-8">
        {websiteMedia.decalogos_image ? (
          <ImageWithText src={websiteMedia.decalogos_image}>
            <Mark>Javer</Mark> está comprometido con los valores de integridad,
            pasión, identidad, sostenibilidad, innovación y respeto.
          </ImageWithText>
        ) : null}
        {decalogues.map((v) => (
          <div key={v.title} className="p-5">
            <div className="text-primary font-bold my-5">{v.title}</div>
            {v.items.map((i) => (
              <div key={i.content}>
                <p>
                  {i.content}[{" "}
                  <a
                    target="_blank"
                    className="underline text-primary"
                    href={i.url}
                    rel="noreferrer"
                  >
                    Leer aquí
                  </a>{" "}
                  ]
                </p>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
