import { type Proyecto } from "@/models/location_hierarchy";
import {
  getAmenitiesByProjectId,
  getProjectByID,
  getPropertiesSearch,
} from "@/utils/api";
import { toUrlCase } from "@/utils/common.utils";
import { getResourceUrl } from "@/utils/image.utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { HiArrowRight, HiMail } from "react-icons/hi";
import { useQuery } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import { Gallery } from "./Gallery";
import { EdgeLogo } from "./icons/EdgeLogo";
import { Button } from "./ui/button";
import { Card, CardContent } from "./ui/card";
import { type ProjectByFilters } from "@/models/project_by_filters_result";

interface ProjectCardProps extends React.HTMLAttributes<HTMLDivElement> {
  project?: Proyecto | ProjectByFilters;
  isGalleryEnabled?: boolean;
  isSelected?: boolean;
}

export function ProjectCard({
  project: initialProject,
  isGalleryEnabled = true,
  isSelected = false,
  className,
  ...props
}: ProjectCardProps) {
  const { t, i18n } = useTranslation("translations");
  const navigate = useNavigate();

  const projectId = useMemo(
    () =>
      initialProject
        ? "project_id" in initialProject
          ? initialProject.project_id
          : initialProject.id
        : undefined,
    [initialProject]
  );

  const { data: project } = useQuery(
    ["getProjectByID", projectId],
    async () => await getProjectByID(Number(projectId)),
    {
      enabled: !!projectId,
    }
  );

  const projectName = useMemo(() => {
    if (project) return project.name;
    if (!initialProject) return "";

    return "project_id" in initialProject
      ? initialProject.project_name
      : initialProject?.name;
  }, [project, initialProject]);

  const { data: properties } = useQuery(
    ["getPropertiesSearch", projectId],
    async () => await getPropertiesSearch({ projectId: projectId ?? -1 }),
    {
      enabled: !!projectId,
    }
  );

  const { data: amenities } = useQuery(
    ["getAmenitiesByProjectId", projectId],
    async () => await getAmenitiesByProjectId(projectId ?? -1),
    {
      enabled: !!projectId,
    }
  );

  const lowestPrice = useMemo(
    () =>
      properties?.reduce(
        (acc, property) => {
          if (acc === undefined) {
            acc = property.precios.reduce(
              (acc, price) => (price.price_base < acc ? price.price_base : acc),
              property.precios[0]?.price_base
            );
          }

          return property.precios.reduce(
            (acc, price) => (price.price_base < acc ? price.price_base : acc),
            acc
          );
        },
        undefined as number | undefined
      ),
    [properties]
  );

  const amenityImages = useMemo(() => {
    return amenities?.amenities
      .map((amenity) => amenity.img_url)
      .filter((img): img is string => !!img);
  }, [amenities]);

  const shownAmenities = useMemo(() => {
    return amenities?.amenities?.slice(0, 3);
  }, [amenities]);

  return (
    <Card
      {...props}
      className={`hover:border-primary/30 ${isSelected ? "border-primary" : ""} ${className}`}
    >
      <CardContent className="flex flex-col lg:flex-row gap-8 pt-4 items-stretch">
        {isGalleryEnabled && (amenityImages?.length ?? 0) > 0 && (
          <div className="relative">
            <Gallery
              type="carousel"
              autoWidth={false}
              isHoverable={false}
              classNames={{
                ROOT: "lg:w-80 w-full",
                Image: "h-64 lg:h-56",
                Carousel: {
                  Content: "mx-0",
                  Item: "p-0",
                  Previous: "left-1",
                  Next: "right-1",
                },
              }}
              images={amenityImages}
            />
            <img
              className="absolute w-24 h-24 bg-white rounded-md object-contain p-2 border lg:hidden block bottom-2 left-2"
              src={getResourceUrl(project?.logo_color)}
              alt={projectName}
            />
          </div>
        )}
        <div
          className="flex flex-col gap-4 grow cursor-pointer"
          onClick={() => {
            navigate(`/desarrollos/${toUrlCase(project?.short_name ?? "")}`);
          }}
        >
          <div className="flex flex-row gap-4 h-fit">
            <img
              className={`w-24 h-24 bg-white rounded-md object-contain p-2 border lg:block ${
                isGalleryEnabled && (amenityImages?.length ?? 0) > 0
                  ? "hidden"
                  : ""
              }`}
              src={getResourceUrl(
                project?.logo_color ?? initialProject?.logo_color
              )}
              alt={projectName}
            />
            <div className="flex flex-col justify-between w-full">
              <div className="flex justify-between gap-2">
                <h5 className="mb-2">{projectName}</h5>
                <Button
                  variant="outline"
                  className="hidden lg:flex"
                  onClick={(e) => {
                    e.stopPropagation();
                    window.location.href = `/desarrollos/${toUrlCase(project?.short_name ?? "")}#contact-section`;
                  }}
                >
                  {t("contactUs")}
                  <HiMail size={24} className="ml-2" />
                </Button>
              </div>
              {lowestPrice && lowestPrice > 0 ? (
                <div className="flex flex-row gap-2">
                  <h6 className="text-foreground/70 !text-sm lg:!text-lg">
                    {t("fromPrice")}
                  </h6>
                  <h5 className="text-primary">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(lowestPrice)}
                  </h5>
                </div>
              ) : (
                <h6 className="text-primary">{t("contactConsultant")}</h6>
              )}
            </div>
          </div>
          {project && project.credit_types.length > 0 && (
            <div className="flex flex-col lg:flex-row gap-2">
              <span className="whitespace-nowrap">{t("creditTypes")}</span>
              <div className="flex flex-row items-center gap-2 flex-wrap">
                {project.credit_types.map((creditName, index) => (
                  <>
                    <span
                      key={index}
                      className="font-viga text-sm lg:text-base"
                    >
                      {creditName}
                    </span>
                    {index < project.credit_types.length - 1 && (
                      <span className="mx-1 font-semibold">|</span>
                    )}
                  </>
                ))}
              </div>
            </div>
          )}
          {shownAmenities &&
            amenities?.amenities &&
            shownAmenities.length > 0 && (
              <div className="flex flex-col lg:flex-row lg:items-center gap-2">
                <div className="flex flex-row items-center gap-2 flex-wrap">
                  {shownAmenities?.map((amenity, index) => (
                    <>
                      <span key={index} className="text-sm lg:text-base">
                        {(i18n.language === "es"
                          ? amenity.name
                          : amenity.name_eng) ?? ""}
                      </span>
                      {index < shownAmenities.length - 1 && (
                        <span className="mx-1 font-semibold">|</span>
                      )}
                    </>
                  ))}
                </div>
                {shownAmenities.length < amenities.amenities.length && (
                  <span className="font-viga text-sm lg:text-base flex flex-row gap-2">
                    <span>|</span>{" "}
                    {t("countMore", {
                      count: amenities.amenities.length - shownAmenities.length,
                    })}
                  </span>
                )}
              </div>
            )}
          <div className="flex flex-row justify-between w-full grow items-end">
            <div>
              {project?.hasPropertyWithEdgeCertification && <EdgeLogo />}
            </div>
            <Button asChild variant="ghost">
              <Link to={`/desarrollos/${toUrlCase(project?.short_name ?? "")}`}>
                {t("seeMore")} <HiArrowRight className="ml-2" size={24} />
              </Link>
            </Button>
          </div>
          <Button
            variant="outline"
            className="lg:hidden self-center mt-1"
            onClick={(e) => {
              e.stopPropagation();
              window.location.href = `/desarrollos/${toUrlCase(project?.short_name ?? "")}#contact-section`;
            }}
          >
            {t("contactUs")}
            <HiMail size={24} className="ml-2" />
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
