import { AvisosTitle } from "../AvisosTitle";

export const AvisoEmpleados = ({ title, content }) => {
  return (
    <div>
      <h1 className="text-center underline font-bold">{title}</h1>

      <p className="text-right my-10">Fecha de actualización: 01/Julio/2021</p>

      <p>{content}</p>

      <br />
      <p className="underline font-bold">Datos personales recabados</p>
      <p>
        <p>“Javer” podrá recabar los siguientes datos:</p>
        <br />

        <p className="underline font-bold">Personales: </p>
      </p>
      <br />
      <ul className="list-disc">
        <li>
          Datos de identificación de usted: Nombre completo, edad, lugar y fecha
          de nacimiento, país de nacimiento, nacionalidad, estado civil,
          domicilio, identificación oficial para votar, cartilla militar, clave
          única de registro de población, registro federal de contribuyentes,
          teléfono(s), correo electrónico, datos de sus dependientes económicos.
        </li>
        <li>
          Datos Laborales: Ocupación o profesión, giro o actividad de negocio.
        </li>
        <li>
          Datos académicos: Evaluaciones psicométricas, información curricular.
        </li>
        <li>Características personales: Huellas dactilares</li>
        <li>
          Datos de identificación de su cónyuge: Nombre y apellidos de cónyuge o
          concubino
        </li>
      </ul>
      <br />
      <p className="underline font-bold">Personales sensibles:</p>
      <ul className="list-disc">
        <li>De salud: Enfermedades y padecimientos.</li>
        <li>Características personales: Tipo de sangre</li>
        <li>Características físicas: Imagen en fotografía</li>
      </ul>

      <br />
      <p>
        <span className="underline">Patrimoniales:</span> Número de dependientes
        económicos, relación de propiedad de lugar de vivienda, evaluación
        socioeconómica, crédito INFONAVIT, sueldo, número de cuenta, CLABE
        interbancaria.
      </p>
      <br />

      <p className="font-bold underline">
        Finalidades del tratamiento de sus datos personales:
      </p>
      <p>
        “Javer” podrá recabar sus datos personales, personales sensibles y
        patrimoniales para las siguientes finalidades primarias, mismas que dan
        origen y son necesarias para la relación jurídica entre “Javer” y el
        titular.
      </p>
      <ul className="list-disc">
        <li>Identificar a los empleados o candidatos.</li>

        <li>Realizar el proceso reclutamiento y selección.</li>
        <li>Celebración del contrato individual de trabajo.</li>
        <li>Integración y actualización de expedientes.</li>
        <li>Control y administración de personal.</li>
        <li>Exámenes psicométricos, socioeconómicos y médicos</li>
      </ul>

      <p>
        Cabe señalar que todas las finalidades primarias dan origen y son
        necesarias para la relación jurídica entre “Javer” y el titular.
      </p>
      <br />

      <p>
        De manera adicional, “Javer” utilizará sus datos personales para las
        siguientes finalidades secundarias, que nos permiten y facilitan
        brindarle un mejor servicio:
      </p>
      <ul className="list-disc">
        <li>Análisis y elaboración de estadísticas de carácter interno.</li>

        <li>Atención de cualquier consulta, duda, aclaración o queja.</li>
        <li>Seguridad.</li>
        <li>Monitoreo de accesos y salidas.</li>
        <li>Otorgamiento de estímulos y reconocimientos.</li>
        <li>Con fines de comunicación o contacto.</li>
      </ul>

      <br />
      <AvisosTitle>Transferencia de datos personales</AvisosTitle>
      <p>
        “Javer” podrá, para las finalidades citadas, transferir sus datos a
        “encargados en términos de la Ley en la materia, a terceros mexicanos o
        extranjeros en los que se apoye para para su operación, a la compañía de
        seguros vigente, así como a subsidiarias, afiliadas y controladoras.
      </p>
      <br />

      <p>
        Acceso, rectificación o cancelación de sus datos personales u oposición
        para su uso Como titular de los datos, usted tiene el derecho de: (i)
        acceso a sus datos personales y así conocer los detalles del tratamiento
        de los mismos, (ii) rectificación en caso de ser inexactos o
        incompletos, (iii) cancelación cuando considere que no se requieren para
        alguna de las finalidades señaladas, estén siendo utilizados para
        finalidades no consentidas o haya finalizado la relación contractual o
        de servicio, (iv) oposición al tratamiento de los mismos para fines
        específicos, (conjuntamente, los “derechos ARCO”).
      </p>
      <br />

      <p>
        Para ejercer sus derechos ARCO, usted deberá enviar una solicitud, misma
        que puede descargar{" "}
        <a
          className="underline text-blue-500"
          href="https://javer-api-dev.s3.amazonaws.com/documentos/F-P-ADM-09-1+Solicitud+de+Ejercicio+de+Derechos+ARCO.2017.docx"
          download
        >
          aquí
        </a>
        , en atención al oficial de privacidad, a la siguiente dirección de
        correo{" "}
        <a className="underline text-blue-500">derechosarco@javer.com.mx </a>
      </p>

      <br />
      <p>
        Le informamos que el ejercicio de los derechos ARCO será gratuito, pero
        si usted reitera su solicitud en un periodo menor a doce meses, tendrá
        el costo definido por Ley, a menos que existan modificaciones
        sustanciales al aviso de privacidad, que motiven nuevas solicitudes
        ARCO. Usted deberá de cubrir los gastos justificados de envío o el costo
        de reproducción en copias u otros formatos y, en su caso, el costo de la
        certificación de documentos.
      </p>

      <br />
      <AvisosTitle>
        Medios para revocar el consentimiento para el tratamiento de datos
        personales.
      </AvisosTitle>
      <p>
        Es importante que usted conozca que puede revocar su consentimiento para
        el tratamiento de sus datos personales de la misma forma por la cual
        otorgo su consentimiento. De tal forma que si usted solicita una
        confirmación a su solicitud, “Javer” a través del oficial de privacidad
        le responderá de forma expresa, terminando la relación jurídica
        existente con “Javer”.
      </p>

      <br />
      <p>El proceso de respuesta será el siguiente:</p>
      <br />

      <p>
        El oficial de privacidad responderá su solicitud ARCO y los motivos de
        su decisión mediante un correo electrónico en un plazo máximo de 20 días
        hábiles contados desde el día en que se haya recibido su solicitud ARCO.
        En caso de que la solicitud ARCO se conteste de manera afirmativa o
        procedente, los cambios solicitados se harán en un plazo máximo de 15
        días hábiles. El responsable podrá notificarle dentro de los plazos
        referidos en este párrafo la prórroga de los mismos, por una sola vez,
        por un periodo igual al original.
      </p>

      <p>
        Es posible que “Javer pueda negar el acceso para que usted ejerza sus
        derechos ARCO, en los supuestos que lo permita la Ley, por lo que deberá
        informar a usted el motivo de tal decisión.
      </p>

      <p>
        Dicha negativa podrá ser parcial, en cuyo caso el responsable efectuará
        el acceso, rectificación, cancelación u oposición en la parte
        procedente.
      </p>

      <br />
      <AvisosTitle>Límites al uso de sus datos personales</AvisosTitle>
      <p>
        Ahora bien, como una alternativa para limitar el alcance de tratamiento
        de sus datos usted podrá listarse en el Registro Público para evitar
        publicidad, dependencia a cargo de la Procuraduría Federal del
        Consumidor. Para mayor información sobre este registro, puede consultar
        el portal de Internet de la PROFECO o bien, ponerse en contacto directo
        con ésta.
      </p>

      <br />
      <AvisosTitle>
        Uso de tecnologías de rastreo en el sitio web de Javer
      </AvisosTitle>
      <p>
        En nuestra página de Internet utilizamos tecnologías de rastreo como
        cookies y herramientas de analítica web como Google Analytics, (términos
        definidos más adelante) a través de las cuales es posible monitorear sus
        visitas como usuario de Internet, brindarle un mejor servicio y
        experiencia de usuario al navegar en nuestra página, así como ofrecerle
        a través de banners promocionales nuevos productos y servicios basados
        en sus preferencias.  Los datos personales que podríamos obtener de
        estas tecnologías de rastreo son los siguientes: horario de navegación,
        tiempo de navegación en nuestra página de Internet, secciones
        consultadas. Nuestro portal tiene ligas a otros sitios externos, de los
        cuales el contenido y políticas de privacidad no son responsabilidad de
        Javer. Los datos personales que se obtienen a través de estas
        tecnologías podríamos compartirlos con terceros mexicanos o extranjeros
        en los que se apoye para su operación, así como subsidiarias, afiliadas
        y controladora.
      </p>
      <br />

      <p>
        Si usted no desea utilizar las tecnologías de rastreo podrá
        deshabilitarlas siguiendo los pasos que su respectivo navegador
        establezca en la barra de menú ayuda (help).
      </p>

      <br />
      <p className="italic">
        Para mayor información, <b>cookie</b> es un archivo de datos que se
        almacenan en el disco duro del equipo de cómputo o del dispositivo de
        comunicación electrónica de un usuario al navegar por internet, el cual
        permite intercambiar información entre dicho sitio y el navegador del
        usuario. Esa información puede revelar medios de identificación de la
        sesión, autentificación o preferencias del usuario, así como cualquier
        dato almacenado por el navegador respecto al sitio web.
      </p>
      <br />

      <p className="italic">
        Y Google Analytics, es una herramienta web con la cual se obtienen datos
        estadísticos de visualización e interacción de parte de los usuarios con
        la página web de “Javer”
      </p>
      <br />

      <AvisosTitle>Cambios al aviso de privacidad</AvisosTitle>
      <p>
        El presente aviso de privacidad puede sufrir cambios o actualizaciones
        derivadas de nuevos requerimientos legales; por las necesidades propias
        de “Javer” o por cambios en su modelo de negocio, en el entendido de que
        toda modificación al mismo se le hará conocer a usted por medio de la
        publicación de este aviso en la página de internet de “Javer”, por lo
        que le recomendamos verificarla con frecuencia.
      </p>
      <br />

      <p>
        Como parte de la preocupación de “Javer” por el buen uso de sus datos,
        en el posible evento de que ocurra una vulneración de seguridad en
        cualquier fase del tratamiento de datos personales, que afecte de forma
        significativa sus derechos patrimoniales o morales, el oficial de
        privacidad comunicará de forma inmediata en la página de internet del
        responsable el suceso de vulneración de seguridad, para que usted pueda
        tomar las medidas necesarias correspondientes para la defensa de sus
        derechos.
      </p>

      <br />
      <p>
        En el proceso comercial pueden capturarse imágenes fotográficas,
        tomaremos las siguientes medidas de seguridad en el evento en que
        detectemos que se han capturado imágenes de algún menor de edad:
      </p>
      <ul className="list-disc">
        <li>
          El dato se obtendrá con el consentimiento de los padres o tutores por
          medio escrito.
        </li>
        <li>
          La autenticidad del consentimiento otorgado por los padres o tutores
          se verificará solicitando los documentos oficiales que acrediten dicha
          condición.
        </li>
      </ul>
      <br />

      <AvisosTitle>Mecanismos de protección</AvisosTitle>

      <p>
        En “Javer” contamos con controles internos en el manejo de la
        información y medidas de seguridad, incluyendo herramientas para
        encriptar y autentificar información que mantienen su información
        personal a salvo. Sus datos personales se procesan a través de sistemas
        de redes seguras y solamente puede acceder a ella un número limitado de
        personas con derechos especiales, a quienes se les exige que mantengan
        dicha información confidencial.
      </p>
      <br />

      <p>
        Si usted considera que su derecho a la protección de sus datos
        personales ha sido lesionado por alguna conducta u omisión de nuestra
        parte, le sugerimos visitar la página oficial{" "}
        <a href="www.ifai.org.mx" target="_blank">
          www.ifai.org.mx
        </a>
      </p>
      <br />
      <p>
        SI EL TITULAR NO ESTÁ DE ACUERDO CON LOS TRATAMIENTOS BAJO ESTE AVISO
        DEBE MANIFESTARLO MEDIANTE LOS MECANISMOS AQUÍ PREVISTOS Y EN EL CASO DE
        LA PÁGINA WEB DE JAVER, ABSTENERSE DE SU USO.
      </p>

      <br />
      <br />

      <p>
        De conformidad con el aviso de privacidad, así como con las
        transferencias de datos.
      </p>
      <br />
      <br />

      <p className="italic text-sm">
        Derechos Reservados © Servicios Corporativos Javer, S.A.B de C.V., 16
        de septiembre #301, entre Hidalgo y Corregidora, Colonia Centro, San
        Pedro Garza Garcia, N.L, C.P. 66230 (Edición 2021).
      </p>
    </div>
  );
};
