import { JaverMarker } from "@/components/icons/JaverMarker";
import { MapProjectInfo } from "@/components/MapProjectInfo";
import { ProjectCard } from "@/components/ProjectCard";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { sortPrices } from "@/models/sort_order";
import {
  getAllProjectsByFilters,
  getInitialDataDesarrollos,
} from "@/utils/api";
import { toTitleCase, toUrlCase } from "@/utils/common.utils";
import { AdvancedMarker, Map, useMap } from "@vis.gl/react-google-maps";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { HiMapPin, HiListBullet } from "react-icons/hi2";
import { useQuery } from "react-query";
import { useParams, useSearchParams } from "react-router-dom";

export default function StatesPage() {
  const { name } = useParams();
  const [params, setParams] = useSearchParams();
  const [isMapVisible, setIsMapVisible] = useState(false);
  const [hoveredProject, setHoveredProject] = useState<number | null>(null);
  const [selectedProject, setSelectedProject] = useState<number | null>(null);
  const [showPinPopover, setShowPinPopover] = useState<number | null>(null);
  const map = useMap();
  const { t } = useTranslation("translations");

  const selectedZone = useMemo(() => params.get("zona"), [params]);

  const { data: statesWithZones } = useQuery(
    "getInitialDataDesarrollos",
    getInitialDataDesarrollos
  );

  const state = useMemo(
    () =>
      statesWithZones?.find((state) => state.name === toTitleCase(name ?? "")),
    [statesWithZones, name]
  );

  const { data: filteredZones } = useQuery(
    ["getAllProjectsByFilters", params.get("price"), state?.id, selectedZone],
    async () => {
      const index = parseInt(params.get("price") ?? "-1");
      const price = index >= 0 ? sortPrices[index] : null;

      const zone = !selectedZone
        ? undefined
        : state?.ciudades.find((zone) => toUrlCase(zone.name) === selectedZone)
            ?.id;

      return await getAllProjectsByFilters({
        price_min: price?.min ?? undefined,
        price_max: price?.max ?? undefined,
        state_id: state?.id,
        city_id: zone,
      });
    }
  );

  const filteredZonesLatLngs = useMemo(
    () =>
      filteredZones?.flatMap((zone) =>
        zone.projects
          .map((project) => ({
            project,
            lat: Number(project.latitud.trim()),
            lng: Number(project.longitud.trim()),
          }))
          .filter(({ lat, lng }) => !isNaN(lat) && !isNaN(lng))
          .filter(
            ({ project }) =>
              selectedProject === null || project.project_id === selectedProject
          )
      ),
    [filteredZones, selectedProject]
  );

  useEffect(() => {
    if (map) {
      setTimeout(() => {
        const bounds = new window.google.maps.LatLngBounds();

        filteredZonesLatLngs?.forEach(({ lat, lng }) => {
          bounds.extend({ lat, lng });
        });

        if (showPinPopover) {
          const { lat, lng } =
            filteredZonesLatLngs?.find(
              ({ project }) => project.project_id === showPinPopover
            ) ?? {};
          if (lat && lng) {
            map.setCenter(new window.google.maps.LatLng(lat, lng, true));
            map.setZoom(13);
          }
        } else {
          map.fitBounds(bounds);
        }
      }, 100);
    }
  }, [filteredZonesLatLngs, map, isMapVisible, showPinPopover]);

  return (
    <div className="mb-8">
      <div className="fixed md:hidden bottom-4 z-10 w-full px-4">
        <Button
          className="w-full"
          onClick={() => {
            setIsMapVisible(!isMapVisible);
            setSelectedProject(null);
            setShowPinPopover(null);
          }}
        >
          {isMapVisible ? (
            <>
              <HiListBullet size={24} className="mr-2" />
              {t("seeList")}
            </>
          ) : (
            <>
              <HiMapPin size={24} className="mr-2" />
              {t("seeMap")}
            </>
          )}
        </Button>
      </div>
      {state?.banner_url && (
        <div className="relative">
          <img
            className="h-64 object-cover w-full"
            src={state?.banner_url}
            alt={state?.name}
          />
          <div className="absolute top-1 left-0 w-full lg:h-full h-64 bg-gradient-to-b from-transparent via-transparent via-40% to-white z-10" />
        </div>
      )}

      <div className={`lg:container ${isMapVisible ? "" : "container"}`}>
        <div
          className={`flex flex-col lg:flex-row justify-between lg:items-end my-8 gap-8  ${isMapVisible ? "max-lg:container" : ""}`}
        >
          <h2>{state?.name}</h2>
          <div className="flex flex-row gap-4 items-end">
            <div className="md:w-64 w-full hidden">
              <Label className="normal-case">{t("orderPrice")}</Label>
              <Select
                value={params.get("price") ?? "-1"}
                onValueChange={(value) => {
                  const priceIndex = Number(value);

                  setParams((prev) => {
                    if (priceIndex === -1) {
                      prev.delete("price");
                    } else {
                      prev.set("price", priceIndex.toString());
                    }

                    return prev;
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem key="-1" value="-1">
                    {t("allPrices")}
                  </SelectItem>
                  {sortPrices.map((zone, index) => (
                    <SelectItem key={index} value={index.toString()}>
                      {new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(zone.min)}{" "}
                      -{" "}
                      {zone.max
                        ? new Intl.NumberFormat("es-MX", {
                            style: "currency",
                            currency: "MXN",
                          }).format(zone.max)
                        : "Más"}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="md:w-64 w-full">
              <Label className="normal-case">{t("viewBy")}</Label>
              <Select
                value={selectedZone ?? "all"}
                onValueChange={(value) => {
                  setParams((prev) => {
                    if (value === "all") {
                      prev.delete("zona");
                    } else {
                      prev.set("zona", value);
                    }

                    return prev;
                  });
                }}
              >
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem key="empty" value={"all"}>
                    {t("allZones")}
                  </SelectItem>
                  {state?.ciudades?.map((zone) => (
                    <SelectItem key={zone.id} value={toUrlCase(zone.name)}>
                      {zone.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <Button
              className="max-md:hidden"
              onClick={() => {
                setIsMapVisible(!isMapVisible);
                setSelectedProject(null);
                setShowPinPopover(null);
              }}
            >
              {isMapVisible ? (
                <>
                  <HiListBullet size={24} className="mr-2" />
                  {t("seeList")}
                </>
              ) : (
                <>
                  <HiMapPin size={24} className="mr-2" />
                  {t("seeMap")}
                </>
              )}
            </Button>
          </div>
        </div>
        <div
          className={`flex flex-col-reverse lg:flex-row ${isMapVisible ? "gap-8" : ""}`}
        >
          <div
            className={`flex flex-col gap-16 pt-16 grow transition-all ${isMapVisible ? "max-lg:hidden" : "2xl:px-16"}`}
          >
            {filteredZones?.length === 0 ? (
              <h5 className="text-gray-500">{t("noDevelopmentsFound")}</h5>
            ) : null}
            {filteredZones?.map((zone) => (
              <div key={zone.city_id}>
                <h4>{zone.city_name}</h4>
                <hr className="border-primary my-4 border" />
                <div className="flex flex-col gap-2">
                  {zone.projects.map((project) => (
                    <ProjectCard
                      key={project.project_id}
                      project={project}
                      isGalleryEnabled={!isMapVisible}
                      isSelected={selectedProject === project.project_id}
                      onMouseEnter={() => {
                        setHoveredProject(project.project_id);
                      }}
                      onMouseLeave={() => {
                        setHoveredProject(null);
                      }}
                      onClick={() => {
                        if (!isMapVisible) return;

                        if (selectedProject === project.project_id) {
                          setSelectedProject(null);
                          setShowPinPopover(null);
                        } else {
                          setSelectedProject(project.project_id);
                          setShowPinPopover(project.project_id);
                        }
                      }}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
          <div
            className={`relative lg:mt-[120px] w-full transition-all ${isMapVisible ? "lg:!w-[500px] h-[90vh]" : "!w-0"}`}
          >
            <Map mapId="states_map">
              {filteredZonesLatLngs?.map(({ project, lat, lng }, index) => (
                <AdvancedMarker
                  key={index}
                  position={{
                    lat,
                    lng,
                  }}
                  onClick={() => {
                    if (showPinPopover !== project.project_id) {
                      setShowPinPopover(project.project_id);
                    }
                  }}
                  className="flex flex-col items-center"
                >
                  {showPinPopover === project.project_id && (
                    <MapProjectInfo
                      project={project}
                      onClose={() => {
                        setTimeout(() => {
                          setShowPinPopover(null);
                        }, 100);
                      }}
                    />
                  )}
                  <JaverMarker
                    color={
                      hoveredProject === project.project_id ||
                      selectedProject === project.project_id ||
                      showPinPopover === project.project_id
                        ? undefined
                        : "black"
                    }
                    size={32}
                  />
                </AdvancedMarker>
              ))}
            </Map>
          </div>
        </div>
      </div>
    </div>
  );
}
