import { GridList } from "@/components/GridList";
import {
  PropertyAmenity,
  PropertyAmenityType,
} from "@/components/PropertyAmenity";
import { type Property } from "@/models/property";
import { useTranslation } from "react-i18next";

interface PropertyFeaturesProps {
  property?: Property;
}

export function PropertyFeatures({ property }: PropertyFeaturesProps) {
  const { t, i18n } = useTranslation("translations");

  if (!property) {
    return <></>;
  }

  return (
    <section
      className="container xl:max-w-5xl mx-auto pt-16"
      id="features-section"
    >
      <h4>{t("features")}</h4>
      <hr className="my-6 border-primary" />

      <p className="mb-6">
        {i18n.language === "es"
          ? property.description
          : property.description_eng}
      </p>

      <div className="flex flex-row gap-12 flex-wrap mb-6">
        <PropertyAmenity
          size={40}
          type={PropertyAmenityType.Land}
          disapearOnZero
          quantity={property.square_meters}
        />
        <PropertyAmenity
          size={40}
          type={PropertyAmenityType.Floors}
          disapearOnZero
          quantity={property.floors}
        />
        <PropertyAmenity
          size={40}
          type={PropertyAmenityType.Rooms}
          disapearOnZero
          quantity={property.rooms}
        />
        <PropertyAmenity
          size={40}
          type={PropertyAmenityType.Bathrooms}
          disapearOnZero
          quantity={property.bathrooms + (property.restrooms ?? 0) / 2}
        />
        <PropertyAmenity
          size={40}
          disapearOnZero
          type={
            property.cars_garage_capacity > property.cars_parking_lot_capacity
              ? PropertyAmenityType.Garage
              : PropertyAmenityType.Parking
          }
          quantity={Math.max(
            property.cars_garage_capacity,
            property.cars_parking_lot_capacity
          )}
        />
      </div>
      <GridList
        items={property?.features?.[i18n.language as "es" | "en"] ?? []}
      />
    </section>
  );
}
