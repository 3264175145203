import "@/styles/privacy_policy.css";

interface PrivacyPolicyClientsProps {
  aplicableSociety: string;
}

export const PrivacyPolicyClients = ({
  aplicableSociety,
}: PrivacyPolicyClientsProps) => {
  return (
    <div className="WordSection1">
      <h3 className="MsoNormal" style={{ textAlign: "center" }}>
        <b>Aviso de privacidad &ldquo;integral&rdquo;</b>
      </h3>
      <p className="MsoNormal">
        <b>&nbsp;</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "right" }}>
        <b>Fecha de actualización: 31/julio/2024</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <b>&nbsp;</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <b>
          Cumpliendo con lo establecido en la Ley Federal de Protección de Datos
          Personales en Posesión de Particulares, en adelante la “Ley”;{" "}
          {aplicableSociety}
        </b>
        , en adelante (“Javer”), con domicilio en 16 de septiembre #301, entre
        Hidalgo y Corregidora, Colonia Centro, San Pedro Garza Garcia, N.L, C.P.
        66230, es responsable del uso, tratamiento y protección de la
        información y datos personales que recabe de usted o de aquellos a los
        que tuviere acceso por cualquier medio para brindarle algún servicio. Si
        usted no manifiesta su negativa para el tratamiento de sus datos, en el
        formato habilitado en este aviso para ejercer sus derechos ARCO,
        entenderemos que ha otorgado su consentimiento.
      </p>
      <p className="MsoNormal">
        <b>
          <u>Datos personales sujetos a tratamiento</u>
        </b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Para cumplir con las finalidades previstas en el presente Aviso de
        Privacidad, “Javer” podrá recabar la siguiente información por medios
        electrónicos, impresos, de manera directa y/o personal:
      </p>
      <p className="MsoNormal">
        <b>Personales:</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        a.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Datos de identificación de usted: Nombre completo, fecha y lugar de
        nacimiento, dirección, teléfono particular, celular, estado civil,
        empresa en la que trabaja, número de hijos, edad de sus hijos, sexo de
        usted, domicilio de su trabajo, puesto, antigüedad en la empresa,
        e-mail, cédula profesional, licencia de conducir, acta de matrimonio,
        acta de nacimiento, número de su credencial INE, copia de pasaporte,
        Clave Única de Registro de Población (CURP), Registro Federal de
        Contribuyentes (RFC), comprobante de domicilio, régimen conyugal,
        escolaridad, referencia familiar, referencia personal, imagen en
        cualquier formato y su firma.
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        b.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Datos de identificación de su cónyuge: Nombre completo, fecha de
        nacimiento, dirección, número de credencial INE, Clave Única de Registro
        de Población (CURP), puesto, antigüedad en la empresa, dirección donde
        trabaja, teléfono del trabajo, e imagen en cualquier formato.
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        c.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Datos de identificación del coacreditado: Nombre completo, empresa en la
        que labora, fecha de nacimiento, domicilio del trabajo, puesto, teléfono
        de trabajo, antigüedad en el trabajo e imagen en cualquier formato.
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        <b>Patrimoniales:</b> Ingreso en su trabajo actual y anterior, NSS,
        ingreso del coacreditado, recibo de sueldo, precalificación INFONAVIT o
        bancario, NSS del cónyuge, estado de cuenta de su nómina y salario del
        cónyuge.
      </p>
      <p className="MsoNormal">
        <b>Personales Sensibles: </b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Para proporcionarle un mejor servicio, y con el fin de realizar la
        gestión de crédito hipotecario con terceros “Entidades crediticias
        nacionales, públicas y privadas”, según sea requerido; “Javer” podrá
        recabar la categoría de “Datos sobre su estado de salud”. Por lo que,
        usted autoriza a “Javer” a dar tratamiento de la información
        proporcionada, así como a transferirla a dichos terceros con los que se
        asista para ese fin.
      </p>
      <p className="MsoNormal">
        <b>Finalidades del tratamiento de sus datos </b>
      </p>
      <p className="MsoNormal">
        “Javer” tratará los datos antes señalados para las siguientes
        finalidades primarias:
      </p>
      <p
        className="MsoListParagraphCxSpFirst"
        style={{ textIndent: "-18.0pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Compraventa de inmuebles.
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ textIndent: "-18.0pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Generar una base de datos para dar seguimiento a interesados, a
        excepción de los datos personales sensibles.
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ textIndent: "-18.0pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Estudios socioeconómicos y sociodemográficos.
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ textIndent: "-18.0pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Procesos administrativos como devoluciones, facturaciones, históricos de
        compras, procesamiento de solicitudes, cobro, aclaraciones,
        investigación.
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ textIndent: "-18.0pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Control y realización de los procesos subsecuentes de consulta,
        créditos, titulación y escrituración.
      </p>
      <p className="MsoListParagraphCxSpLast" style={{ textIndent: "-18.0pt" }}>
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Mercadotecnia, publicidad y prospección comercial.
      </p>
      <p className="MsoNormal">
        De manera adicional, “Javer” utilizará sus datos personales para las
        siguientes finalidades secundarias, que nos permiten y facilitan
        brindarle un mejor servicio:{" "}
      </p>
      <p
        className="MsoListParagraphCxSpFirst"
        style={{ marginLeft: "53.25pt", textIndent: "-35.25pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Boletines de noticias.
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ marginLeft: "53.25pt", textIndent: "-35.25pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Mejora del control y realización de los procesos subsecuentes de
        consulta, créditos, titulación y escrituración.
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ marginLeft: "53.25pt", textIndent: "-35.25pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Implementación de mejoras en productos y servicios.
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ marginLeft: "53.25pt", textIndent: "-35.25pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Comunicar promociones direccionadas.
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ marginLeft: "53.25pt", textIndent: "-35.25pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Invitaciones a eventos especiales.
      </p>
      <p
        className="MsoListParagraphCxSpLast"
        style={{ marginLeft: "53.25pt", textIndent: "-35.25pt" }}
      >
        <span style={{ fontFamily: "Symbol" }}>
          ·
          <span style={{ font: '7.0pt "Times New Roman"' }}>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </span>
        </span>
        Atención al cliente, felicitaciones, mensajes de bienvenida.
      </p>
      <p className="MsoNormal">
        <b>Transferencia de datos personales</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        “Javer” podrá, para las finalidades citadas, transferir sus datos a
        “encargados” en términos de la Ley en la materia, a terceros mexicanos o
        extranjeros en los que se apoye para su operación, y a terceros del
        sector inmobiliario, créditos, construcción, administradoras de
        fraccionamientos, así como a sus subsidiarias, afiliadas y controladora.{" "}
      </p>
      <p className="MsoNormal">
        <b>
          Acceso, rectificación o cancelación de sus datos personales u
          oposición para su uso.{" "}
        </b>
      </p>
      <p className="MsoNormal">
        Como titular de los datos, usted tiene el derecho de:{" "}
      </p>
      <p
        className="MsoListParagraphCxSpFirst"
        style={{ textIndent: "-36.0pt" }}
      >
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        i.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Acceso a sus datos personales y así conocer los detalles del tratamiento
        de los mismos,{" "}
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ textIndent: "-36.0pt" }}
      >
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        ii.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Rectificación en caso de que sean inexactos o incompletos,{" "}
      </p>
      <p
        className="MsoListParagraphCxSpMiddle"
        style={{ textIndent: "-36.0pt" }}
      >
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        iii.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Cancelación cuando considere que no se requieren para alguna de las
        finalidades señaladas, estén siendo utilizados para finalidades no
        consentidas o haya finalizado la relación contractual o de servicio.
      </p>
      <p className="MsoListParagraphCxSpLast" style={{ textIndent: "-36.0pt" }}>
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;{" "}
        </span>
        iv.
        <span style={{ font: '7.0pt "Times New Roman"' }}>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
        Oposición al tratamiento de los mismos para fines específicos
        (conjuntamente, los “derechos ARCO”).{" "}
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Para ejercer sus derechos ARCO, usted deberá enviar una solicitud, misma
        que puede descargar{" "}
        <a
          className="text-blue-600 underline"
          href="https://javer-my.sharepoint.com/:w:/g/personal/jeh_javer_com_mx/ETpvXfS7CdpOoy4E81O3_s0BmQQ3jStq5gEfTIo2y8eKbg?e=K01QR2"
        >
          aquí
        </a>
        , en atención al oficial de privacidad, a la siguiente dirección de
        correo{" "}
        <a
          className="text-blue-600 underline"
          href="mailto:derechosarco@javer.com.mx?subject=Solicitud de derechos ARCO"
        >
          derechosarco@javer.com.mx
        </a>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Le informamos que el ejercicio de los derechos ARCO será gratuito, pero
        si usted reitera su solicitud en un periodo menor a doce meses, tendrá
        el costo definido por Ley, a menos que existan modificaciones
        sustanciales al aviso de privacidad, que motiven nuevas solicitudes
        ARCO. Usted deberá cubrir los gastos justificados de envío o el costo de
        reproducción en copias u otros formatos y, en su caso, el costo de la
        certificación de documentos.
      </p>
      <p className="MsoNormal">
        <b>
          Medios para revocar el consentimiento para el tratamiento de datos
          personales.
        </b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Es importante que usted conozca que puede revocar su consentimiento para
        el tratamiento de sus datos personales de la misma forma por la cual
        otorgo su consentimiento. De tal forma que, si usted solicita una
        confirmación a su solicitud, “Javer” a través del oficial de privacidad
        le responderá de forma expresa, terminando la relación jurídica
        existente con “Javer”.{" "}
      </p>
      <p className="MsoNormal">El proceso de respuesta será el siguiente: </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        El oficial de privacidad responderá su solicitud ARCO y los motivos de
        su decisión mediante un correo electrónico en un plazo máximo de 20 días
        hábiles contados desde el día en que se haya recibido su solicitud ARCO.
        En caso de que la solicitud ARCO se conteste de manera afirmativa o
        procedente, los cambios solicitados se harán en un plazo máximo de 15
        días hábiles. El responsable podrá notificarle dentro de los plazos
        referidos en este párrafo la prórroga de los mismos, por una sola vez,
        por un periodo igual al original.{" "}
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Es posible que “Javer” pueda negar el acceso para que usted ejerza sus
        derechos ARCO, en los supuestos que lo permita la Ley, por lo que deberá
        informar a usted el motivo de tal decisión. Dicha negativa podrá ser
        parcial, en cuyo caso Javer efectuará el acceso, rectificación,
        cancelación u oposición en la parte procedente.
      </p>
      <p className="MsoNormal">
        <b>Límites al uso de sus datos personales.</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Ahora bien, como una alternativa para limitar el alcance de tratamiento
        de sus datos usted podrá listarse en el Registro Público para evitar
        publicidad, dependencia a cargo de la Procuraduría Federal del
        Consumidor. Para mayor información sobre este registro, puede consultar
        el portal de Internet de la PROFECO o bien, ponerse en contacto directo
        con ésta.{" "}
      </p>
      <p className="MsoNormal">
        <b>Uso de tecnologías de rastreo en el sitio web de Javer</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        En nuestra página de Internet utilizamos tecnologías de rastreo como
        cookies y herramientas de analítica web como Google Analytics, (términos
        definidos más adelante), a través de las cuales es posible monitorear
        sus visitas como usuario de Internet, brindarle un mejor servicio y
        experiencia de usuario al navegar en nuestra página, así como ofrecerle
        a través de banners promocionales nuevos productos y servicios basados
        en sus preferencias.&nbsp; Los datos personales que podríamos obtener de
        estas tecnologías de rastreo son los siguientes: horario de navegación,
        tiempo de navegación en nuestra página de Internet, secciones
        consultadas. De la misma forma, el portal de “Javer” tiene ligas a otros
        sitios externos, de los cuales el contenido y políticas de privacidad no
        son su responsabilidad. Los datos personales que se obtienen a través de
        estas tecnologías se podrán compartir con terceros mexicanos o
        extranjeros en los que “Javer” se apoye para su operación, así como con
        sus subsidiarias, afiliadas y controladora.{" "}
      </p>
      <p className="MsoNormal">
        Si usted no desea utilizar las tecnologías de rastreo podrá
        deshabilitarlas siguiendo los pasos que su respectivo navegador
        establezca en la barra de menú, ayuda (help).
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Para mayor información, cookie es un archivo de datos que se almacenan
        en el disco duro del equipo de cómputo o del dispositivo de comunicación
        electrónica de un usuario al navegar por internet, el cual permite
        intercambiar información entre dicho sitio y el navegador del usuario.
        Esa información puede revelar medios de identificación de la sesión,
        autentificación o preferencias del usuario, así como cualquier dato
        almacenado por el navegador respecto al sitio web.
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Y Google Analytics, es una herramienta web con la cual se obtienen datos
        estadísticos de visualización e interacción de parte de los usuarios con
        la página web de “Javer”
      </p>
      <p className="MsoNormal">
        <b>Cambios al aviso de privacidad</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        El presente aviso de privacidad puede sufrir cambios o actualizaciones
        derivadas de nuevos requerimientos legales; por las necesidades propias
        de “Javer” o por cambios en su modelo de negocio, en el entendido de que
        toda modificación al mismo se le hará conocer a usted por medio de la
        publicación de este aviso en la página de internet de “Javer”, por lo
        que le recomendamos verificarla con frecuencia.{" "}
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Como parte de la preocupación de “Javer” por el buen uso de sus datos,
        en el posible evento de que ocurra una vulneración de seguridad en
        cualquier fase del tratamiento de datos personales, que afecte de forma
        significativa sus derechos patrimoniales o morales, el oficial de
        privacidad comunicará de forma inmediata en la página de internet de
        “Javer” el suceso de vulneración de seguridad, para que usted pueda
        tomar las medidas necesarias correspondientes para la defensa de sus
        derechos.{" "}
      </p>
      <p className="MsoNormal">
        <b>Mecanismos de protección</b>
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        En “Javer” contamos con controles internos en el manejo de la
        información y medidas de seguridad, incluyendo herramientas para
        encriptar y autentificar información que mantienen su información
        personal a salvo. Sus datos personales se procesan a través de sistemas
        de redes seguras y solamente puede acceder a ella un número limitado de
        personas con derechos especiales, a quienes se les exige que mantengan
        dicha información confidencial.{" "}
      </p>
      <p className="MsoNormal">&nbsp;</p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        Si usted considera que su derecho a la protección de sus datos
        personales ha sido lesionado por alguna conducta u omisión de nuestra
        parte, le sugerimos visitar la página oficial www.ifai.org.mx
      </p>
      <p className="MsoNormal" style={{ textAlign: "justify" }}>
        SI EL TITULAR NO ESTÁ DE ACUERDO CON LOS TRATAMIENTOS BAJO ESTE AVISO
        DEBE MANIFESTARLO MEDIANTE LOS MECANISMOS AQUÍ PREVISTOS Y NO
        PROPORCIONAR SUS DATOS.{" "}
      </p>
      <p className="MsoNormal">&nbsp;</p>
      <p className="MsoNormal">
        Derechos Reservados © Servicios Corporativos Javer, S.A.B de C.V., 16
        de septiembre #301, entre Hidalgo y Corregidora, Colonia Centro, San
        Pedro Garza Garcia, N.L, C.P. 66230 (Edición 2024).
      </p>
    </div>
  );
};
