interface MapIconProps {
  size?: number;
}

export const MapIcon = ({ size = 36.91 }: MapIconProps) => {
  const aspectRatio = 36.91 / 37.06;

  const width = size;
  const height = width / aspectRatio;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      data-name="Capa 2"
    >
      <g data-name="Capa 1">
        <path
          d="M19.53 0c.49.11 1 .18 1.48.32 3.65 1.05 5.86 3.51 6.83 7.13.77 2.88.32 5.66-.82 8.37-.56 1.33-1.22 2.6-1.91 3.87l-1.32 2.16c-.18.2-.31.42-.44.65-.02.02-.04.03-.05.05a58.56 58.56 0 0 1-3.93 5.2c-.24.28-.52.5-.92.46V13.03c.07 0 .14-.02.22-.02 2.25-.28 3.6-2.35 2.81-4.39-.53-1.37-1.6-2.02-3.03-2.13v-6.5c.36.01.72.01 1.08.01Z"
          style={{
            fill: "#ca0110",
            strokeWidth: 0,
          }}
        />
        <path
          d="M18.45 13.04v15.2c-.4-.03-.69-.22-.94-.53-.41-.52-.85-1.02-1.27-1.52l-1.32-1.74-1.79-2.6c-.45-.72-.89-1.44-1.34-2.16-.57-1.1-1.18-2.18-1.67-3.32-.69-1.6-1.31-3.23-1.39-4.98-.2-4.11.97-7.62 4.6-9.98C14.49.66 15.79.26 17.15.08c.08 0 .18.03.22-.08h1.09v6.5c-.08 0-.17.01-.25.02a3.257 3.257 0 0 0-2.95 3.82c.28 1.55 1.47 2.56 3.2 2.7Z"
          style={{
            fill: "#ed3616",
            strokeWidth: 0,
          }}
        />
        <path
          d="m23.79 21.85 1.32-2.16h4.08c.59 0 .95.24 1.17.78 1.14 2.66 2.28 5.32 3.42 7.99 1 2.33 2 4.66 2.99 6.99.3.7.09 1.19-.67 1.61H18.44V34.88h15.73c-.54-1.19-.97-2.37-1.53-3.49-.23-.69-.56-1.34-.84-2.01-.14-.33-.27-.67-.42-1-.89-2.08-1.78-4.15-2.67-6.23-.07-.15-.1-.3-.34-.3-1.24.02-2.48.01-3.72.02-.29 0-.58-.02-.87-.02Z"
          style={{
            fill: "#bbdbfd",
            strokeWidth: 0,
          }}
        />
        <path
          d="M18.45 34.89v2.17H.78c-.21-.19-.5-.25-.65-.54-.2-.4-.16-.75 0-1.14 1.42-3.29 2.83-6.58 4.24-9.86l2.22-5.16c.2-.47.57-.66 1.06-.66h4.12c.45.72.89 1.44 1.34 2.16-1.5 0-3.01 0-4.51.01-.17 0-.31 0-.4.21-1.03 2.45-2.08 4.89-3.12 7.33-.45 1.06-.9 2.12-1.35 3.17-.34.76-.67 1.52-1.02 2.32H11.16c1.54 0 3.08 0 4.62-.01.89 0 1.77.01 2.66.02Z"
          style={{
            fill: "#dff3fd",
            strokeWidth: 0,
          }}
        />
        <path
          d="M21.22 34.87h-2.34c-.12-.16-.26-.31-.43-.43 0-.02-.01-.04-.02-.06v-6.17c.41.04.68-.18.92-.46 1.41-1.66 2.72-3.39 3.93-5.2.01-.02.03-.03.05-.05.48-.12.88-.4 1.31-.63 1.24 0 2.48 0 3.72-.02.23 0 .27.15.34.3.89 2.08 1.78 4.15 2.67 6.23.14.33.28.67.42 1-2.1 1.09-4.21 2.17-6.31 3.26-1.43.74-2.85 1.49-4.28 2.24Z"
          style={{
            fill: "#0eaa61",
            strokeWidth: 0,
          }}
        />
        <path
          d="M24.66 21.87c-.43.23-.83.51-1.31.63.13-.23.26-.46.44-.65.29 0 .58.02.87.02Z"
          style={{
            fill: "#f6bb2a",
            strokeWidth: 0,
          }}
        />
        <path
          d="M18.43 34.39s.01.04.02.06c0 .14 0 .28-.01.42v.01c-.89 0-1.77-.01-2.66-.02a.21.21 0 0 0-.04-.06c-1.79-1.79-3.58-3.58-5.36-5.38-.14-.14-.25-.13-.41-.05-.71.37-1.43.74-2.15 1.1-1.36.69-2.72 1.38-4.09 2.07.45-1.06.9-2.12 1.35-3.17.4-.06.72-.31 1.07-.48 2.92-1.48 5.83-2.97 8.75-4.46l1.32 1.74c-1.19.61-2.37 1.23-3.56 1.84-.42.21-.42.21-.07.55l3.96 3.96c.62.62 1.25 1.24 1.88 1.85Z"
          style={{
            fill: "#fbd842",
            strokeWidth: 0,
          }}
        />
        <path
          d="M14.91 24.44c-2.92 1.49-5.83 2.98-8.75 4.46-.35.18-.67.43-1.07.48 1.04-2.44 2.09-4.88 3.12-7.33.09-.21.23-.21.4-.21 1.5 0 3.01 0 4.51-.01l1.79 2.6Z"
          style={{
            fill: "#0fab61",
            strokeWidth: 0,
          }}
        />
        <path
          d="m18.43 34.39-1.88-1.85-3.96-3.96c-.34-.34-.34-.34.07-.55 1.19-.61 2.38-1.23 3.56-1.84.42.51.86 1.01 1.27 1.52.25.31.55.5.94.53v6.15Z"
          style={{
            fill: "#5dc882",
            strokeWidth: 0,
          }}
        />
        <path
          d="M21.22 34.87c1.42-.75 2.85-1.5 4.28-2.24 2.1-1.09 4.21-2.17 6.31-3.26.28.67.6 1.32.84 2.01-1.09.56-2.17 1.12-3.26 1.68-1.15.6-2.29 1.2-3.43 1.8h-4.73Z"
          style={{
            fill: "#f9bd2a",
            strokeWidth: 0,
          }}
        />
        <path
          d="M25.95 34.87c1.14-.6 2.29-1.21 3.43-1.8 1.08-.57 2.17-1.12 3.26-1.68.55 1.12.99 2.29 1.53 3.49h-8.22Z"
          style={{
            fill: "#169bdf",
            strokeWidth: 0,
          }}
        />
        <path
          d="M18.44 34.88c0-.14 0-.28.01-.42.17.12.31.26.43.43h-.44Z"
          style={{
            fill: "#f7ba96",
            strokeWidth: 0,
          }}
        />
        <path
          d="M3.74 32.56c1.36-.69 2.73-1.38 4.09-2.07.72-.36 1.44-.73 2.15-1.1.16-.08.26-.1.41.05 1.78 1.8 3.58 3.58 5.36 5.38.02.02.02.04.04.06-1.54 0-3.08 0-4.62.01H2.72c.35-.8.69-1.56 1.02-2.32Z"
          style={{
            fill: "#159be0",
            strokeWidth: 0,
          }}
        />
      </g>
    </svg>
  );
};
