import { type Footer, type Navbar } from "../models/section";

export const initialNavbarSections: Navbar[] = [
  {
    id: 1,
    name: "Nosotros",
    path: "/nosotros",
    active: 1,
    name_eng: "us",
  },
  {
    id: 2,
    name: "Lotes Comerciales",
    path: "/lotes",
    active: 1,
    name_eng: "plots",
  },
  {
    id: 3,
    name: "Contacto",
    path: "/contacto",
    active: 1,
    name_eng: "contact",
  },
  {
    id: 4,
    name: "Blog Javer",
    path: "/blog",
    active: 1,
    name_eng: "javerBlog",
  },
];

export const initialFooterSections: Footer[] = [
  {
    id: 1,
    name: "Certificaciones y Premios",
    name_eng: "Certifications and Awards",
    path: "/certificaciones",
    active: 1,
    is_bold: 0,
  },
  {
    id: 2,
    name: "Politicas de Privacidad",
    name_eng: "Privacy policies",
    path: "/avisos-de-privacidad",
    active: 1,
    is_bold: 0,
  },
  {
    id: 3,
    name: "Sistema de Denuncias Éticas",
    name_eng: "Ethical complaint systems",
    path: "https://www.letica.mx/eticajaver",
    active: 1,
    is_bold: 0,
  },
  {
    id: 4,
    name: "Decálogos JAVER",
    name_eng: "JAVER Decalogues",
    path: "/decalogos",
    active: 1,
    is_bold: 0,
  },
  {
    id: 5,
    name: "Contratos de adhesión",
    name_eng: "Adhesion contracts",
    path: "/contratos-de-adhesion",
    active: 1,
    is_bold: 0,
  },
  {
    id: 6,
    name: "Inversionistas",
    name_eng: "Investors",
    path: "https://ri.javer.com.mx/",
    active: 1,
    is_bold: 1,
  },
  {
    id: 7,
    name: "Proveedores",
    name_eng: "Suppliers",
    path: "https://alereapps-s1.com/ords/f?p=110:LOGIN",
    active: 1,
    is_bold: 1,
  },
  {
    id: 8,
    name: "Bolsa de Trabajo",
    name_eng: "Job Board",
    path: "https://hcgl.fa.us2.oraclecloud.com/hcmUI/CandidateExperience/es/sites/CasasJaverBolsaTrabajo",
    active: 1,
    is_bold: 0,
  },
  {
    id: 9,
    name: "Reservas Territoriales",
    name_eng: "Territorial Reserves",
    path: "/reservas-territoriales",
    active: 1,
    is_bold: 0,
  },
  {
    id: 10,
    name: "Lotes Comerciales",
    name_eng: "Commercial Sheds",
    path: "/lotes",
    active: 1,
    is_bold: 0,
  },
  {
    id: 11,
    name: "Blog JAVER",
    name_eng: "Blog JAVER",
    path: "/blog",
    active: 1,
    is_bold: 0,
  },
];
