import { SingleDatePicker } from "@/components/DatePicker";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { format, subYears } from "date-fns";
import { Form, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { es, enUS } from "date-fns/locale";
import { type Project } from "@/models/project";
import { SALESFORCE_ORG_ID, SALESFORCE_URL } from "@/constants";

interface DevelopmentContactFormProps {
  className?: string;
  development?: Project;
}

export const DevelopmentContactForm = ({
  className,
  development,
}: DevelopmentContactFormProps) => {
  const { t, i18n } = useTranslation("translations");

  const location = useLocation();
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Este campo es requerido"),
    lastName: yup.string().required("Este campo es requerido"),
    email: yup
      .string()
      .email("Correo inválido")
      .required("Este campo es requerido"),
    phone: yup.string().required("Este campo es requerido"),
    birthDate: yup.date().required("Este campo es requerido"),
    typeOfCredit: yup.string().required("Este campo es requerido"),
    message: yup.string().required("Este campo es requerido"),
    recaptcha: yup.string().required("Este campo es requerido"),
    acceptPolicy: yup
      .boolean()
      .required("Este campo es requerido")
      .isTrue("Este campo es requerido"),
  });

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        birthDate: subYears(new Date(), 16),
        typeOfCredit: undefined as string | undefined,
        message: "",
        recaptcha: undefined as string | undefined,
        acceptPolicy: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm, setSubmitting }) => {
        await fetch(
          `${SALESFORCE_URL}&${new URLSearchParams({
            captcha_settings: `{"keyname":"CAPTCHAJAVER","fallback":"true","orgId":"${SALESFORCE_ORG_ID}","ts":"${JSON.stringify(new Date().getTime())}"}`,
            oid: SALESFORCE_ORG_ID,
            retURL: "https://www.javer.com.mx/gracias",
            "g-recaptcha-response": values.recaptcha ?? "",
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            email: values.email,
            "00N3l00000Q7A50": format(values.birthDate, "yyyy-MM-dd"),
            "00N3l00000Q7A54": development?.short_name?.toUpperCase() ?? "",
            "00N3l00000Q7A5V": values.typeOfCredit ?? "",
            "00N3l00000Q7A57": "Sitio_Javer",
            "00N3l00000Q7A4n": "Medios Digitales",
            "00N3l00000Q7A5S": "Pagina web Javer",
            message: values.message,
            acceptPolicy: "on",
          }).toString()}`,
          {
            method: "POST",
            mode: "no-cors",
          }
        )
          .then((response) => {
            resetForm();
            setSubmitting(false);

            const searchParams = new URLSearchParams(
              location.search
            ).toString();

            navigate(
              `/gracias${searchParams.length > 0 ? `?${searchParams}` : ""}`
            );
          })
          .catch(() => {
            toast.error("Error en el envío de la información");
          });
      }}
      validateOnChange
      validateOnMount
    >
      {({ getFieldProps, setFieldValue, values, isValid, isSubmitting }) => (
        <Form
          className={`grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 w-full ${className}`}
        >
          <div>
            <Label htmlFor="firstName">{t("names")}</Label>
            <Input {...getFieldProps("firstName")} />
          </div>
          <div>
            <Label htmlFor="lastName">{t("lastNames")}</Label>
            <Input {...getFieldProps("lastName")} />
          </div>
          <div>
            <Label htmlFor="email">{t("email")}</Label>
            <Input {...getFieldProps("email")} />
          </div>
          <div>
            <Label htmlFor="birthDate">{t("dob")}</Label>
            <SingleDatePicker
              locale={i18n.language === "es" ? es : enUS}
              fromYear={new Date().getFullYear() - 100}
              toYear={new Date().getFullYear() - 16}
              selected={values.birthDate}
              onSelect={async (date) => await setFieldValue("birthDate", date)}
            />
          </div>
          <div>
            <Label htmlFor="phone">{t("phone")}</Label>
            <Input {...getFieldProps("phone")} />
          </div>
          <div>
            <Label htmlFor="typeOfCredit">{t("creditType")}</Label>
            <Select
              name="typeOfCredit"
              onValueChange={(value) => {
                setFieldValue("typeOfCredit", value);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder={t("selectOption")} />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="-">--Ninguno--</SelectItem>
                <SelectItem value="INFONAVIT">INFONAVIT</SelectItem>
                <SelectItem value="ISSSTELEON">ISSSTELEON</SelectItem>
                <SelectItem value="SHF/BANCO">SHF/BANCO</SelectItem>
                <SelectItem value="BANCO - SOFOL">BANCO - SOFOL</SelectItem>
                <SelectItem value="COFINAVIT">COFINAVIT</SelectItem>
                <SelectItem value="CONTADO">CONTADO</SelectItem>
                <SelectItem value="FOVISSSTE">FOVISSSTE</SelectItem>
                <SelectItem value="OTROS / NO SE">OTROS / NO SE</SelectItem>
                <SelectItem value="BANJERCITO">BANJERCITO</SelectItem>
                <SelectItem value="ISSFAM">ISSFAM</SelectItem>
                <SelectItem value="PENSIONES">PENSIONES</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="col-span-1 sm:col-span-2">
            <Label htmlFor="message">{t("message")}</Label>
            <Textarea
              placeholder={t("messageExample")}
              {...getFieldProps("message")}
            />
          </div>
          <div className="col-span-1 sm:col-span-2 flex items-center gap-2">
            <Checkbox
              id="terms"
              checked={values.acceptPolicy}
              onCheckedChange={() => {
                setFieldValue("acceptPolicy", !values.acceptPolicy);
              }}
            />
            <label htmlFor="terms">
              <Link to="/avisos-de-privacidad" className="underline">
                {t("acceptPolicies")}
              </Link>
            </label>
          </div>
          <div className="col-span-1 sm:col-span-2">
            <ReCAPTCHA
              sitekey="6Lcg2vQZAAAAAHpqmf6Pj_-t-doxKr1iwLWfQuWQ"
              onChange={async (e) => {
                await setFieldValue("recaptcha", e);
              }}
            />
          </div>
          <div className="col-span-1 sm:col-span-2">
            <Button
              size="lg"
              type="submit"
              className="w-full md:w-fit"
              disabled={!isValid}
              isLoading={isSubmitting}
            >
              {t("submit")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
