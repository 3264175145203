import { useQuery } from "react-query";
import { Mark } from "../shared-components/Mark";
import { Title } from "../shared-components/Title";
import { getContratosAdhesion } from "../utils/api";

export const Contracts = () => {
  const { data, isLoading } = useQuery(
    "contratos_adhesion",
    getContratosAdhesion
  );
  return (
    <div className=" my-10 mx-2 md:mx-40 min-h-[70vh] ">
      <div className="grid place-items-center">
        <Title>
          Aviso de privacidad de viviendas nuevas <Mark>JAVER</Mark>
        </Title>
        {isLoading ? null : (
          <>
            <span className=" p-6 border-y-2 border-neutral-400 flex gap-10 justify-center w-full ">
              {data.map((v) => (
                <div
                  key={v.section}
                  className="text-neutral-400 uppercase font-bold"
                >
                  {v.section.split(",")[0]}
                </div>
              ))}
            </span>
            <div className="mt-8 grid place-items-center w-2/3">
              {data.map((v) => (
                <div key={v.section} className="p-5">
                  <div className="text-primary font-bold my-5">{v.section}</div>
                  {v.documents.map((i) => (
                    <div key={i.content}>
                      <p>
                        {i.name} [{" "}
                        <a
                          href={i.url}
                          target="_blank"
                          className="underline text-primary"
                          rel="noreferrer"
                        >
                          Leer aquí
                        </a>{" "}
                        ]
                      </p>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
