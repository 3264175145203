import { Link } from "react-router-dom";
import { Mark } from "../shared-components/Mark";
import { Title } from "../shared-components/Title";

const notices = [
  {
    title: "Avisos de privacidad para clientes",
    items: [
      {
        content:
          "Aviso de Privacidad Casas Javer, S.A. de C.V.",
        url: "/aviso-clientes-casas-javer",
      },

      {
        content:
          "Aviso de Privacidad Servicios Corporativos Javer, S.A.B. de C.V.",
        url: "/aviso-clientes-servicios-corporativos",
      },

      {
        content: "Aviso de Privacidad Casas Javer de Querétaro, S.A. de C.V.",
        url: "/aviso-clientes-queretaro",
      },

      {
        content: "Aviso de Privacidad Casas Javer de México, S.A. de C.V.",
        url: "/aviso-clientes-mexico",
      },

      {
        content: "Aviso de Privacidad Inmuebles para Ti, S.A. de C.V.",
        url: "/aviso-clientes-inmuebles",
      },
    ],
  },
  {
    title: "Avisos de privacidad para empleados",
    items: [
      {
        content: "Casas Javer, S.A. de C.V.",
        url: "/aviso-empleados-casas-javer",
      },

      {
        content: "Casas Javer de Querétaro, S.A. de C.V.",
        url: "/aviso-empleados-queretaro",
      },

      {
        content: "Casas Javer de México, S.A. de C.V.",
        url: "/aviso-empleados-mexico",
      },
      {
        content: "Servicios Corporativos Javer, S.A. B. de C.V.",
        url: "/aviso-empleados-servicios-corporativos",
      },

      {
        content: "Inmuebles Para Ti, S.A. de C.V.",
        url: "/aviso-empleados-inmuebles",
      },
      // {
      //   content:
      //     "Aviso de Privacidad Casas Consentidas Javer, S.A. de C.V. SOFOM ENR",
      //   url: "https://www.javer.com.mx/aviso-empleados-casas-consentidas-javer",
      // },
    ],
  },

  {
    title: "Avisos de privacidad para proveedores",
    items: [
      {
        content: "Servicios Corporativos Javer, S.A.B. de C.V.",
        url: "/aviso-proveedores",
      },
    ],
  },
];

export const PrivacyNotice = () => {
  return (
    <div className=" my-10 mx-2 md:mx-40 min-h-[70vh] ">
      <div className="grid place-items-center">
        <Title>
          Aviso de privacidad de viviendas nuevas <Mark>JAVER</Mark>
        </Title>
        <span className=" p-6 border-y-2 border-neutral-400 flex gap-10 justify-center w-full ">
          <div className="text-neutral-400 font-bold">PARA CLIENTES</div>
          <div className="text-neutral-400 font-bold">PARA EMPLEADOS</div>
          <div className="text-neutral-400 font-bold">PARA PROVEEDORES</div>
        </span>
        <div className="mt-8 grid   w-2/3">
          {notices.map((v) => (
            <div key={v.title} className="p-5">
              <div className="text-primary font-bold my-5">{v.title}</div>
              {v.items.map((i) => (
                <div key={i.content}>
                  <p>
                    {i.content}[{" "}
                    <Link
                      // target="_blank"
                      to={"/avisos-de-privacidad" + i.url}
                      className="underline text-primary"
                      // rel="noreferrer"
                    >
                      Leer aquí
                    </Link>{" "}
                    ]
                  </p>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
