import { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa";
import { useLocation } from "react-router-dom";

export const WhatsappFAB = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === "/servicio-a-clientes") {
      document
        .querySelector('div:has(iframe[title="Botmaker"])')
        ?.classList.add("hidden-bot");
    } else {
      document
        .querySelector('div:has(iframe[title="Botmaker"])')
        ?.classList.remove("hidden-bot");
    }
  }, [pathname]);

  return (
    <>
      {pathname !== "/servicio-a-clientes" && (
        <div
          className="bg-green-500 w-14 h-14 flex items-center justify-center rounded-full shadow-lg fixed bottom-44 right-4 cursor-pointer z-50"
          onClick={() => window.open("https://wa.me/5218111336614")}
        >
          <FaWhatsapp size={32} className="text-white" />
        </div>
      )}
    </>
  );
};
