import { useState } from "react";
import PropTypes from "prop-types";

import { Link } from "react-router-dom";
import { Img } from "../Img";
import { Text } from "../Text";
import { BlogCarousel } from "./BlogCarousel";
import { BlogMenu } from "./BlogMenu";
import { BlogRecentArticles } from "./BlogRecentArticles";
import { useAppContext } from "../../data/DataProvider";

const BlogHeroScroll = ({
  articles,
  className,
  recentarticles,
  vermaslabel,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { websiteMedia } = useAppContext();

  // Scroll back or forward through the blogs
  const scrollPreviousBlog = () => {
    setCurrentIndex((currentIndex - 1 + articles.length) % articles.length);
  };

  const scrollNextBlog = () => {
    setCurrentIndex((currentIndex + 1) % articles.length);
  };

  const currentArticle = articles[currentIndex];

  return (
    <div className={className}>
      <div className="sm:h-[740px] h-[793px] md:h-[828px] relative w-full">
        <div className="sm:h-[740px] h-[793px] md:h-[828px] m-auto w-full">
          <div className="absolute flex flex-col inset-x-[0] items-center justify-start mx-auto top-[0] w-full">
            <div className="flex flex-col items-center justify-start w-full">
              <div className="flex flex-col rounded-md items-center justify-start w-full">
                <div className="h-[740px] md:h-[828px] relative w-full">
                  {websiteMedia.blog_image ? (
                    <Img
                      className="h-[740px] m-auto object-cover rounded-lg w-full"
                      src={websiteMedia.blog_image}
                      alt="rectangleSixtyNine"
                    />
                  ) : null}
                  <BlogMenu />
                </div>
              </div>
            </div>
          </div>

          <BlogCarousel article={currentArticle} />
        </div>
        <div className="absolute bottom-5 md:bottom-[18%] flex flex-row md:gap-10 inset-x-[0] items-center justify-between mx-auto w-[95%]">
          <Img
            className="h-14 cursor-pointer"
            src="https://images.monoceros-dev.com/images/iconos/img_blogleft.svg"
            alt="back"
            onClick={scrollPreviousBlog}
          />

          <Img
            className="h-14 cursor-pointer"
            src="https://images.monoceros-dev.com/images/iconos/img_blogright.svg"
            alt="forward"
            onClick={scrollNextBlog}
          />
        </div>
      </div>
      <div className="  relative w-[92%] md:w-full">
        <div className=" flex flex-col inset-[0]   m-auto w-[99%]">
          <div className="flex flex-col gap-5 items-center justify-center w-full">
            <div className="flex  md:flex-row md:gap-10 items-start justify-between w-full">
              <Text
                className="text-4xl sm:text-[32px] md:text-[34px] text-primary tracking-[0.20px]"
                size="txtRobotoRomanBold36"
              >
                {recentarticles}
              </Text>
              <div className="flex border-2 rounded-md border-red-500 items-center justify-center p-3 ">
                <Link
                  to={"/blog-list/archivo"}
                  className="flex flex-row gap-3.5 items-center justify-center  md:w-full"
                >
                  <Text
                    className="text-base text-primary"
                    size="txtOpenSansBold16Red900"
                  >
                    {vermaslabel}
                  </Text>
                  <Img
                    className=""
                    src="https://images.monoceros-dev.com/images/iconos/img_arrowright_red_900.svg"
                    alt="arrowright"
                  />
                </Link>
              </div>
            </div>

            <BlogRecentArticles
              articles={articles
                .sort(
                  (a, b) =>
                    new Date(b.date).getTime() - new Date(a.date).getTime()
                )
                .slice(0, 3)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BlogHeroScroll.defaultProps = {
  blogTitleText: (
    <Text
      className="md:text-5xl text-6xl text-gray-50 uppercase"
      size="txtVigaRegular60"
    >
      <span className="text-gray-50 font-viga text-left font-normal">
        BLOG{" "}
      </span>
      <span className="text-primary font-viga text-left font-normal">
        JAVER
      </span>
    </Text>
  ),
  noticiastext: "NOTICIAS",
  finanzastext: "FINANZAS",
  tuhogarjavertext: "TU HOGAR javer",
  rsetext: "RSE",
  archivotext: "ARCHIVO",
  vermastext: "TU HOGAR JAVER",
  headline: "Puebla, hogar lleno de historia, vida y estilo.",
  description:
    "La ciudad de Puebla, ubicada en el centro de México, es un lugar que combina historia, cultura y modernidad de manera única.",
  violamanisa: "Por Javer",
  p02may: "11 octubre 2023",
  recentarticles: "Últimas novedades",
  vermaslabel: "Ver más",
  futureofworktext: "Querétaro, tu mejor inversión.",
  descriptiontext:
    "El mercado inmobiliario en México ofrece una amplia gama de oportunidades para la inversión, uno de los destinos más prometedores es el estado de Querétaro.",
  linahickstext: "Por Javer",
  datetext: "11 septiembre 2023",
  futureofworktext1:
    "Así se vivió la inauguración del primer vivero Javer en Jalisco.",
  descriptiontext1: (
    <Text
      className="mt-[27px] text-sm text-gray-50 w-full"
      size="txtRobotoRomanRegular14"
    >
      <span className="text-gray-50 font-roboto text-left font-normal">
        El pasado 23 de junio se llevó a cabo la inauguración de nuestro primer
        vivero{" "}
      </span>
      <a
        href="javascript:"
        className="text-gray-50 font-roboto text-left font-normal underline"
      >
        Javer{" "}
      </a>
      <span className="text-gray-50 font-roboto text-left font-normal">
        en Jalisco, el quinto a nivel nacional.
      </span>
    </Text>
  ),
  linahickstext1: "Por Javer",
  datetext1: "10 julio 2023",
  futureofworktext2: "Javer, gran empresa mexicana.",
  languagetext: (
    <Text
      className="mt-[29px] text-sm text-gray-50 w-full"
      size="txtRobotoRomanRegular14"
    >
      <span className="text-gray-50 font-roboto text-left font-normal">
        Año con año buscamos posicionarnos y dar a conocer los programas que
        hacen de Javer una{" "}
      </span>
      <a
        href="javascript:"
        className="text-gray-50 font-roboto text-left font-normal underline"
      >
        Empresa Responsable
      </a>
      <span className="text-gray-50 font-roboto text-left font-normal">. </span>
    </Text>
  ),
  linahickstext2: "Por Javer",
  datetext2: "19 septiembre 2023",
};

BlogHeroScroll.propTypes = {
  articles: PropTypes.any,
  className: PropTypes.any,
  recentarticles: PropTypes.any,
  vermaslabel: PropTypes.any,
};

export default BlogHeroScroll;
