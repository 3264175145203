import { useTranslation } from "react-i18next";
import { FaFacebook, FaYoutube } from "react-icons/fa";
import { BiLogoTiktok } from "react-icons/bi";
import { LiaLinkedinIn, LiaPinterestP } from "react-icons/lia";
import { FaXTwitter } from "react-icons/fa6";
import { useAppContext } from "../data/DataProvider";
import { Link } from "react-router-dom";
import { useMemo } from "react";
import { type Translation } from "@/types/translation";
import { IoLogoInstagram } from "react-icons/io";
import { HiEnvelope, HiMapPin, HiPhone } from "react-icons/hi2";

function Footer() {
  const { footerSections } = useAppContext();

  const companyLinks = useMemo(() => {
    return footerSections
      .filter((item) => item.active === 1 && item.section === "company")
      .map((item) => ({
        id: item.id,
        path: item.path,
        name: item.name_eng as keyof Translation,
        is_external: item.path.startsWith("http"),
      }));
  }, [footerSections]);

  const investorLinks = useMemo(() => {
    return footerSections
      .filter((item) => item.active === 1 && item.section === "investors")
      .map((item) => ({
        id: item.id,
        path: item.path,
        name: item.name_eng as keyof Translation,
        is_external: item.path.startsWith("http"),
      }));
  }, [footerSections]);

  const infoLinks = useMemo(() => {
    return footerSections
      .filter((item) => item.active === 1 && item.section === "info")
      .map((item) => ({
        id: item.id,
        path: item.path,
        name: item.name_eng as keyof Translation,
        is_external: item.path.startsWith("http"),
      }));
  }, [footerSections]);

  const clientLinks = useMemo(() => {
    return footerSections
      .filter((item) => item.active === 1 && item.section === "client")
      .map((item) => ({
        id: item.id,
        path: item.path,
        name: item.name_eng as keyof Translation,
        is_external: item.path.startsWith("http"),
      }));
  }, [footerSections]);

  const { t } = useTranslation("translations");
  return (
    <div className="bg-neutral-900 text-white pt-12">
      <div className="flex flex-col gap-8 container">
        <div className="flex flex-col-reverse lg:flex-row justify-between gap-16">
          <div className="flex flex-col lg:flex-row justify-between grow lg:mr-[20%] gap-8">
            <div className="flex flex-col gap-4">
              {companyLinks.length > 0 && (
                <>
                  <h6 className="!text-base font-bold">{t("company")}</h6>
                  {companyLinks.map((item) => {
                    if (item.is_external) {
                      return (
                        <a
                          key={item.id}
                          target="_blank"
                          href={item.path}
                          rel="noreferrer"
                          className="text-neutral-400"
                        >
                          {t(item.name)}
                        </a>
                      );
                    } else {
                      return (
                        <Link
                          key={item.id}
                          to={item.path}
                          className="text-neutral-400"
                        >
                          {t(item.name)}
                        </Link>
                      );
                    }
                  })}
                </>
              )}

              <br />
              {investorLinks.length > 0 && (
                <>
                  <h6 className="!text-base font-bold">{t("investors")}</h6>
                  {investorLinks.map((item) => {
                    if (item.is_external) {
                      return (
                        <a
                          key={item.id}
                          target="_blank"
                          href={item.path}
                          rel="noreferrer"
                          className="text-neutral-400"
                        >
                          {t(item.name)}
                        </a>
                      );
                    } else {
                      return (
                        <Link
                          key={item.id}
                          to={item.path}
                          className="text-neutral-400"
                        >
                          {t(item.name)}
                        </Link>
                      );
                    }
                  })}
                </>
              )}
            </div>

            <div className="flex flex-col gap-4">
              {infoLinks.length > 0 && (
                <>
                  <h6 className="!text-base font-bold text-white">
                    {t("information")}
                  </h6>
                  {infoLinks.map((item) => {
                    if (item.is_external) {
                      return (
                        <a
                          key={item.id}
                          target="_blank"
                          href={item.path}
                          rel="noreferrer"
                          className="text-neutral-400"
                        >
                          {t(item.name)}
                        </a>
                      );
                    } else {
                      return (
                        <Link
                          key={item.id}
                          to={item.path}
                          className="text-neutral-400"
                        >
                          {t(item.name)}
                        </Link>
                      );
                    }
                  })}
                </>
              )}
              <br />
              {clientLinks.length > 0 && (
                <>
                  <h6 className="!text-base font-bold text-white">
                    {t("clients")}
                  </h6>
                  {clientLinks.map((item) => {
                    if (item.is_external) {
                      return (
                        <a
                          key={item.id}
                          target="_blank"
                          href={item.path}
                          rel="noreferrer"
                          className="text-neutral-400"
                        >
                          {t(item.name)}
                        </a>
                      );
                    } else {
                      return (
                        <Link
                          key={item.id}
                          to={item.path}
                          className="text-neutral-400"
                        >
                          {t(item.name)}
                        </Link>
                      );
                    }
                  })}
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col lg:flex-row gap-4">
            <div className="flex flex-col gap-6 text-neutral-400">
              <div className="flex flex-row items-center gap-3">
                <HiPhone size={24} className="text-primary" />
                81 1133-6614 / 81 1133-6611
              </div>
              <a
                href="mailto:contacto@javer.com.mx"
                className="flex flex-row items-center gap-3"
              >
                <HiEnvelope size={24} className="text-primary" />
                contacto@javer.com.mx
              </a>
              <p className="flex flex-row items-center gap-3">
                <HiMapPin size={24} className="text-primary" />
                Monterrey, NL, México
              </p>
            </div>
          </div>
        </div>
        <div>
          <h6 className="!text-base font-bold">{t("followUs")}</h6>
          <div className="flex flex-row gap-4 mt-2 flex-wrap [&>*]:z-10">
            <FaFacebook
              size={24}
              className="cursor-pointer"
              onClick={() => window.open("https://www.facebook.com/Javer")}
            />
            <IoLogoInstagram
              className="bg-white text-black rounded-full p-[2px] cursor-pointer"
              size={24}
              onClick={() => window.open("https://www.instagram.com/javer_mx/")}
            />
            <LiaLinkedinIn
              className="bg-white text-black rounded-full p-[2px] cursor-pointer"
              size={24}
              onClick={() =>
                window.open("https://www.linkedin.com/company/javermx/")
              }
            />
            <LiaPinterestP
              className="bg-white text-black rounded-full p-[2px] cursor-pointer"
              size={24}
              onClick={() =>
                window.open("https://www.pinterest.com.mx/javer_mx/")
              }
            />
            <BiLogoTiktok
              className="bg-white text-black rounded-full p-[4px] cursor-pointer"
              size={24}
              onClick={() => window.open("https://www.tiktok.com/@javer_mx")}
            />
            <FaYoutube
              className="bg-white text-black rounded-full p-[4px] cursor-pointer"
              size={24}
              onClick={() =>
                window.open("https://www.youtube.com/@JAVER_MEXICO")
              }
            />
            <FaXTwitter
              className="bg-white text-black rounded-full p-[4px] cursor-pointer"
              size={24}
              onClick={() => window.open("https://twitter.com/javer_mx")}
            />
          </div>
        </div>
        <div className="relative">
          <div className="flex flex-row justify-between lg:justify-end gap-8 w-full lg:absolute bottom-8">
            <img
              src="/images/socialmente-responsable.svg"
              alt="Javer"
              className="w-32"
            />
            <img src="/images/gptw.svg" alt="Javer" className="w-16" />
          </div>
        </div>
        <div className="flex flex-col gap-8">
          <hr className="border-white" />
          <div className="flex flex-col lg:flex-row lg:justify-between items-center gap-8 pb-12 w-full">
            <img src="/images/logo-white.png" alt="Javer" className="w-44" />
            <p className="text-sm text-center">
              Derechos Reservados © Servicios Corporativos Javer, S.A.B. de C.V
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
