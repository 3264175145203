import { useTranslation } from "react-i18next";
import { ContactForm } from "../components/ContactForm";
import "./Contacto.css";

function Contacto() {
  const { t } = useTranslation("translations");

  return (
    <div className="container max-w-3xl mb-16">
      <div className="my-8">
        <h3 className="text-center text-primary">{t("askAbout")}</h3>
        <p className="text-neutral-400 text-center mt-6 uppercase">
          {t("provideDetails")}
        </p>
      </div>
      <ContactForm />
    </div>
  );
}

export default Contacto;
