import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import {
  getInitialDataDesarrollos,
  getProjectByID,
  getProjects,
  getPropertiesSearch,
} from "../../utils/api";

import { GridList } from "@/components/GridList";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { ModelCard } from "@/components/ModelCard";
import { toUpperCase, toUrlCase } from "@/utils/common.utils";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import { HousingDevelopmentHeader } from "./sections/HousingDevelopmentHeader";
import { HousingDevelopmentAmenities } from "./sections/HousingDevelopmentAmenities";
import { HousingDevelopmentLocation } from "./sections/HousingDevelopmentLocation";
import { AditionalInfoSection } from "@/components/AditionalInfoSection";
import { Interweave } from "interweave";
import { useWindowSize } from "@uidotdev/usehooks";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { getResourceUrl } from "@/utils/image.utils";
import { HiOutlineClock, HiOutlinePhone } from "react-icons/hi2";
import { DevelopmentContactForm } from "@/components/DevelopmentContactForm";

export const HousingDevelopmentPage = () => {
  const { t, i18n } = useTranslation("translations");
  const { width } = useWindowSize();
  const { name } = useParams();
  const [showPromotionalBanner, setShowPromotionalBanner] = useState(false);

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
    }
  }, []);

  const { data: projects } = useQuery(
    "getProjects",
    async () => await getProjects()
  );

  const id = useMemo(
    () =>
      projects?.find(
        (project) => project.short_name === toUpperCase(name ?? "")
      )?.id,
    [projects, name]
  );

  const { data: project } = useQuery(
    ["getProjectByID", id],
    async () => await getProjectByID(Number(id)),
    {
      enabled: !!id,
    }
  );

  const { data: properties } = useQuery(
    ["getPropertiesSearch", id],
    async () => await getPropertiesSearch({ projectId: id ?? -1 }),
    {
      enabled: !!id,
    }
  );

  const { data: statesWithProjects } = useQuery(
    "getInitialDataDesarrollos",
    async () => await getInitialDataDesarrollos()
  );

  const state = useMemo(
    () =>
      statesWithProjects?.find((state) =>
        state.ciudades.find((city) =>
          city.proyectos.find((project) => project.id === id)
        )
      ),
    [statesWithProjects, id]
  );

  const lowestPrice = useMemo(
    () =>
      properties?.reduce(
        (acc, property) => {
          if (acc === undefined) {
            acc = property.precios.reduce(
              (acc, price) => (price.price_base < acc ? price.price_base : acc),
              property.precios[0]?.price_base
            );
          }

          return property.precios.reduce(
            (acc, price) => (price.price_base < acc ? price.price_base : acc),
            acc
          );
        },
        undefined as number | undefined
      ),
    [properties]
  );

  useEffect(() => {
    if (project) {
      setShowPromotionalBanner(!!project.banner_url);
    }
  }, [project]);

  return (
    <div>
      <Dialog
        open={showPromotionalBanner}
        onOpenChange={setShowPromotionalBanner}
      >
        <DialogContent className="max-w-4xl p-0 border-none overflow-hidden [&>button]:bg-white">
          <img
            src={getResourceUrl(project?.banner_url ?? "")}
            alt="Banner promocional"
          />
        </DialogContent>
      </Dialog>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink to="/">{t("homeStart")}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink to={`/estados/${toUrlCase(state?.name ?? "")}`}>
              {t("developments")}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink
              to={`/desarrollos/${toUrlCase(project?.short_name ?? "")}`}
              className="text-primary"
            >
              {project?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <HousingDevelopmentHeader
        development={project}
        properties={properties}
        lowestPrice={lowestPrice}
      />
      <HousingDevelopmentAmenities projectId={id} />

      {properties && properties.length > 0 && (
        <>
          <section
            className="container xl:max-w-5xl mx-auto pt-16"
            id="models-section"
          >
            <h4>
              {t("models")} {project?.name}
            </h4>
            <hr className="my-6 border-primary" />
          </section>
          {properties?.length === 1 ? (
            <div className="flex flex-row justify-center">
              <ModelCard
                model={properties[0]}
                projectName={toUrlCase(project?.short_name ?? "")}
                orientation="horizontal"
                className="mx-8"
              />
            </div>
          ) : (
            <Carousel
              opts={{
                dragFree: true,
              }}
            >
              <CarouselContent
                style={{
                  marginLeft:
                    width && width >= 1280 ? (width - 1024) / 2 + 24 : 16,
                  marginRight:
                    width && width >= 1280 ? (width - 1024) / 2 + 24 : 16,
                }}
              >
                {properties?.map((property, index) => (
                  <CarouselItem
                    key={index}
                    className="basis-auto max-w-full min-w-0 pl-2 pb-2"
                  >
                    <ModelCard
                      className="h-full w-72"
                      model={property}
                      projectName={toUrlCase(project?.short_name ?? "")}
                    />
                  </CarouselItem>
                ))}
              </CarouselContent>
              <CarouselNext />
              <CarouselPrevious />
            </Carousel>
          )}
        </>
      )}
      {project?.additional_info && (
        <AditionalInfoSection info={project.additional_info} />
      )}
      {(!!project?.live_the_experience_description ||
        project?.live_the_experience_url) && (
        <section className="container xl:max-w-5xl mx-auto mt-16">
          <h4>{t("liveTheFullExperience")}</h4>
          <hr className="my-6 border-primary" />
          {project?.live_the_experience_description && (
            <p className="mb-6">
              {i18n.language === "es"
                ? project?.live_the_experience_description
                : project?.live_the_experience_description_en}
            </p>
          )}
          {project?.live_the_experience_url && (
            <Interweave
              allowList={["iframe"]}
              className="w-full aspect-video"
              content={project?.live_the_experience_url}
            />
          )}
        </section>
      )}
      {project?.interest_area && (
        <section className="container xl:max-w-5xl mx-auto mt-16">
          <h4>{t("areasOfInterest")}</h4>
          <hr className="my-6 border-primary" />
          <GridList
            items={
              i18n.language === "es"
                ? project.interest_area.sp
                : project.interest_area.en
            }
          />
        </section>
      )}
      {project?.equipment && (
        <section className="container xl:max-w-5xl mx-auto mt-16">
          <h4>{t("equipment")}</h4>
          <hr className="my-6 border-primary" />
          <GridList
            items={
              i18n.language === "es"
                ? project.equipment.sp
                : project.equipment.en
            }
          />
        </section>
      )}

      <HousingDevelopmentLocation project={project} />

      <section
        id="contact-section"
        className="container xl:max-w-5xl mx-auto my-16"
      >
        <h4>{t("housingDevelopmentContactTitle")}</h4>
        <h3 className="text-primary mt-1">{project?.name}</h3>
        <p className="text-slate-600 mt-2">
          {t("housingDevelopmentContactDescription")}
        </p>
        <hr className="my-6 border-primary" />
        <div className="lg:px-32">
          <div className="flex flex-col md:flex-row justify-around mb-8 gap-4">
            <div className="flex flex-row gap-4 items-center">
              <HiOutlinePhone className="text-primary" size={28} />
              <div>
                <p>{t("contact")}</p>
                <strong>{project?.contact_form?.phone_number}</strong>
              </div>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <HiOutlineClock className="text-primary" size={28} />
              <div>
                <p>{t("attentionHours")}</p>
                <strong>
                  {i18n.language === "es"
                    ? project?.contact_form?.opening_hours?.es
                    : project?.contact_form?.opening_hours?.en}
                </strong>
              </div>
            </div>
          </div>
          <DevelopmentContactForm development={project} />
        </div>
      </section>
    </div>
  );
};

export default HousingDevelopmentPage;
