import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { useTranslation } from "react-i18next";
import { Button } from "./ui/button";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { toUrlCase } from "@/utils/common.utils";
import { useMemo } from "react";
import { PropertyAmenity, PropertyAmenityType } from "./PropertyAmenity";
import { type PropertySearch } from "@/models/property_search";

type ModelCardProps = React.HTMLAttributes<HTMLDivElement> & {
  model: PropertySearch;
  projectName: string;
  orientation?: "horizontal" | "vertical";
};

export const ModelCard = ({
  model,
  projectName,
  className,
  orientation = "vertical",
  ...props
}: ModelCardProps) => {
  const { t } = useTranslation("translations");

  const lowestPrice = useMemo(
    () =>
      model.precios.reduce(
        (acc, price) => (price.price_base < acc ? price.price_base : acc),
        model.precios[0]?.price_base
      ),
    [model]
  );

  return (
    <Link
      to={`/desarrollos/${projectName}/propiedad/${toUrlCase(model.name)}`}
      className="flex gap-2 items-center"
    >
      <Card
        {...props}
        className={`flex ${orientation === "vertical" ? "flex-col" : "flex-col lg:flex-row lg:items-stretch"} ${className}`}
      >
        <CardHeader>
          <img
            src={model.main_image}
            alt={model.name}
            className={`object-cover aspect-[4/3] ${
              orientation === "vertical" ? "w-full" : "w-64"
            }`}
          />
        </CardHeader>
        <CardContent
          className={`grow ${orientation === "vertical" ? "" : "lg:mt-4 lg:flex lg:flex-col lg:justify-between lg:w-96"}`}
        >
          <CardTitle>{model.name}</CardTitle>
          {lowestPrice ? (
            <p className="mt-2">
              {t("fromPrice")}{" "}
              {new Intl.NumberFormat("es-MX", {
                style: "currency",
                currency: "MXN",
              }).format(lowestPrice)}
            </p>
          ) : (
            <p className="mt-2">{t("noPrices")}</p>
          )}
          <div className="flex flex-row gap-1 mt-2 [&>*]:w-1/3">
            <PropertyAmenity
              type={PropertyAmenityType.Rooms}
              quantity={model.rooms}
            />
            <PropertyAmenity
              type={PropertyAmenityType.Bathrooms}
              quantity={model.bathrooms + (model.restrooms ?? 0) / 2}
            />
            <PropertyAmenity
              type={
                model.cars_garage_capacity > model.cars_parking_lot_capacity
                  ? PropertyAmenityType.Garage
                  : PropertyAmenityType.Parking
              }
              quantity={Math.max(
                model.cars_garage_capacity,
                model.cars_parking_lot_capacity
              )}
            />
          </div>
          <div className="flex justify-end">
            <Button variant="ghost">
              {t("knowMore")}
              <HiArrowRight size={18} />
            </Button>
          </div>
        </CardContent>
      </Card>
    </Link>
  );
};
