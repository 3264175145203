import { type Translation } from "@/types/translation";

const es: Translation = {
  homeStart: "Inicio",
  find: "Encuentra",
  yourNew: "tu nuevo",
  home: "hogar",
  weHaveSpaces: "contamos con espacios",
  toLiveInHarmony: "para que vivas feliz en armonía",
  state: "Estado",
  developments: "Desarrollos",
  us: "Nosotros",
  plots: "Lotes comerciales",
  contact: "Contacto",
  javerBlog: "Blog JAVER",
  company: "Empresa",
  information: "Información",
  socialMedia: "Redes sociales",
  certifications: "Certificaciones y premios",
  complaintSystem: "Sistema de Denuncias Éticas",
  javerPrivacyNotices: "Avisos de privacidad de JAVER",
  customerService: "Servicio a clientes",
  decalogues: "Decálogos JAVER",
  contracts: "Contratos de adhesión",
  investors: "Inversionistas",
  providers: "Proveedores",
  jobOpportunities: "Bolsa de trabajo",
  landReserves: "Reservas Territoriales",
  commercialPlots: "Lotes comerciales",
  followUs: "Síguenos",
  askAbout: "Pregunta por tu nuevo hogar",
  provideDetails:
    "Proporciona tus datos y un asesor se pondrá en contacto contigo",
  names: "Nombre(s)",
  lastNames: "Apellido(s)",
  email: "Correo Electrónico",
  dob: "Fecha de nacimiento",
  phone: "Teléfono",
  fractioning: "Fraccionamiento",
  creditType: "Tipo de crédito",
  message: "Mensaje",
  messageExample: "Ej. Me interesa una propiedad en Nuevo León",
  acceptPolicies: "Acepto políticas de privacidad",
  privacyNotices: "Avisos de privacidad",
  checkPrivacyNotices: "Ver políticas de privacidad",
  submit: "Enviar",
  selectOption: "Seleccione una opción",
  plotSale: "Venta de lotes comerciales",
  plotSaleSubtitle: "¡Pregunta por nuestros espacios disponibles!",
  states: "Estados",
  squareMeters: "Metros cuadrados",
  plotSaleDetails:
    "ofrece lotes comerciales estratégicamente ubicados desde 200 hasta 10,000 metros cuadrados totalmente urbanizados y con precios altamente competitivos. ",
  callUs: " ¡LLÁMANOS!",
  emailUs: " ESCRÍBENOS",
  contactConsultant: " Contacta a un asesor",
  aboutUsTitle: "Contamos con más de 50 años de experiencia",
  aboutUsSubtitle1: "Conoce",
  aboutUsSubtitle2: "Misión, visión y valores.",
  aboutUs1:
    " desarrolladora inmobiliaria que inició hace 50 años, hoy tiene una importante presencia en los sectores de interés social, media y residencial y en los últimos años ha incursionado también en vivienda vertical. Tiene presencia en Nuevo León, Jalisco, Aguascalientes, Querétaro, Estado de México, Quintana Roo, Puebla y Tamaulipas.",
  aboutUs2:
    "La compañía fue fundada hace 50 años por el Ing. Salomón Marcuschamer, logrando mantenerse a la vanguardia, y ofrecer soluciones habitacionales de acuerdo con las necesidades del mercado, lo que ha llevado a Javer ser líder en la industria y vender más de 300,000 viviendas, buscando ofrecer desarrollos planeados para la comodidad y la convivencia familiar.",
  aboutUs3:
    "Los clientes pueden sentirse tranquilos de que estarán adquiriendo la mejor inversión para su familia. Actualmente tienen proyectos en la República mexicana pensados en las diferentes necesidades de cada plaza y segmento, los cuales se distinguen por su ubicación, equipamiento, áreas verdes y espacios de esparcimiento que mejoran la calidad de vida y agregan valor al patrimonio de sus clientes, la asesoría personalizada y certificada del equipo de ventas es un elemento esencial en Javer, ya que desde el primer contacto hasta la firma de escritura están presentes para que el trámite de su próxima casa sea insuperable.",
  aboutUs4Start: "Desde enero de 2016,",
  aboutUs4End:
    " cotiza en la Bolsa mexicana de valores, cuenta con un gobierno corporativo muy robusto y tiene un alto compromiso con sus grupos de interés. Por lo cual, gestiona diversas actividades en base a su modelo de responsabilidad social alineado a la norma ISO26000. Y a los objetivos del desarrollo sostenible. Como resultado, ha recibido por séptimo año consecutivo el distintivo de empresa socialmente responsable y la certificación Great Place to Work. Por años, JAVER ha acompañado a miles de familias mexicanas en el camino hacia su patrimonio. Por ello, te invitamos a que tú también comiences una nueva historia y estrenes tu nuevo hogar con ",
  mision: "Misión",
  misionContent:
    "Ofrecer soluciones habitacionales de calidad para satisfacer las necesidades de vivienda o patrimoniales de nuestros clientes, fundamentados en nuestros valores y capital humano.",
  vision: "Visión",
  visionContent:
    "Ser una empresa líder en su ramo, que se adecúe oportunamente a las necesidades cambiantes del mercado, maximizando las expectativas de cada uno de nuestros grupos de interés de una manera sostenible.",
  values: "Valores",
  valuesContent:
    "Integridad : Somos consistentes con nuestros principios y valores, actuando con transparencia y honestidad. \n \n Pasión: Hacemos de lo ordinario algo extraordinario, imprimiendo nuestro sello y ejecutando las cosas bien a la primera. \n\nRespeto: Promovemos un ambiente de trabajo y empresarial positivo en el que se fomenta la empatía y la equidad.\n.\n Sostenibilidad: Gestionamos el uso eficiente de los recursos generando valor social, económico y ambiental a través del tiempo, detonando comunidades y marcando el rumbo de su desarrollo.\n\n Innovación: Evaluamos constantemente lo que hacemos, manteniendo apertura a ideas creativas que nos permitan ser ágiles en la adaptación al cambio y la toma de decisiones. \n\nIdentidad: Generamos un sentido de pertenencia y compromiso del talento humano a través del desarrollo de sus capacidades y aspiraciones, coordinando esfuerzos y talentos para alcanzar objetivos.",
  cities: "Ciudades",
  carouselTitle: "El hogar de tus sueños",
  defaultDescription:
    "Espacios pensados para tus necesidades y las de tu familia.",
  noResults: "No encontramos propiedades para tu búsqueda",
  loading: "Cargando...",
  virtualTour: "Recorrido virtual",
  architecturalPlans: "Plantas Arquitectónicas",
  amenities: "Amenidades / Equipamiento",
  priceList: "Lista de precios",
  priceListTitle:
    "Pregunta por nuestras bonificaciones y promociones en caseta.",
  startingPrices: "Precios desde",
  squareMeterPrice: "Precios por m2 de terreno excedente",
  priceListNote:
    "En las operaciones de crédito al precio total de la operación de compraventa se añadirán los montos variables de conceptos de crédito y gastos notariales. los precios aplican únicamente en viviendas para escritura hasta el {{endDate}}. precios sujetos a disponibilidad y a cambios sin previos aviso. Se le añadirá un incremento del 3% al precio total de las viviendas que estén ubicadas frente a parque. Se le añadirá un incremento del 3% al precio total de las viviendas que están ubicadas en esquina.",

  parkingLots_one: "{{count}} estacionamiento",
  parkingLots_other: "{{count}} estacionamientos",
  garageCapacity_one: "{{count}} cochera",
  garageCapacity_other: "{{count}} cocheras",
  bedrooms_one: "{{count}} recámara",
  bedrooms_other: "{{count}} recámaras",
  bathrooms_one: "{{count}} baño",
  bathrooms_other: "{{count}} baños",
  blueprintHint: "Doble click para hacer zoom",
  landReservesTitle:
    "¿Tienes un terreno en venta? contáctanos si cuentas con un terreno que desees vender",
  landReservesDescription:
    "En JAVER desarrollamos comunidades habitacionales integrales con equipamiento urbano y comercial. Queremos brindar a nuestros clientes un espacio confortable, de servicios y calidad de vida. Es por eso que queremos trabajar en conjunto para ofrecer desarrollos sustentables y adaptados a cada espacio. Para Javer, la confidencialidad es primordial, al ponerte en contacto con nosotros, tus datos serán preservados de forma segura y estarás en contacto exclusivamente con el área de Reservas Territoriales.",

  personalData: "Datos personales",
  landData: "Datos del terreno",
  postalCode: "Código postal",

  hectare: "Hectárea",
  squareMeterPriceLabel: "Precio por M2",
  description: "Descripción",

  dialAt: "Llama al ",
  forMoreInfo: "para más información.",
  phoneContactSchedule: "(Lunes a viernes de 9 AM a 8 PM).",
  certificationsTitle:
    "Es reconocido como uno de los mejores lugares para trabajar.",
  fromPrice: "Desde*",
  contactUs: "Contáctanos",
  knowTheModels: "Conoce los modelos",
  areasOfInterest: "Zonas de interés / Servicios",
  equipment: "Equipamiento",
  models: "Modelos",
  liveTheFullExperience: "Vive la experiencia completa",
  location: "Ubicación",
  creditTypes: "Tipos de crédito",
  moreInfo: "Más información",
  continueInGoogleMaps: "Continuar en Google Maps",
  continueInWaze: "Continuar en Waze",
  howToGetHere: "¿Cómo llegar?",
  noPrices: "Sin precios",
  housingDevelopmentContactTitle: "Haz realidad el hogar de tus sueños",
  housingDevelopmentContactDescription:
    "Estamos para ayudarte siempre que lo necesites, con gusto resolveremos esa inquietud que tienes.",
  features: "Características",
  knowMore: "Conoce más",
  photoGallery: "Galería de fotos",
  save: "Guardar",
  countMore: "+{{count}} más",
  seeMap: "Ver ubicación",
  seeMore: "Ver más",
  seeAll: "Ver todo",
  zones: "Zonas",
  housingDevelopments: "Fraccionamientos",
  priceMoreInfoTitle:
    "Pregunta por nuestras bonificaciones y promociones en caseta.",
  priceMoreInfoDisclaimer:
    "*En las operaciones de crédito al precio total de la operación de compraventa se añadirán los montos variables de conceptos de crédito y gastos notariales. los precios aplican únicamente en viviendas para escritura hasta el {{lastDate}}. precios sujetos a disponibilidad y a cambios sin previos aviso. Se le añadirá un incremento del 3% al precio total de las viviendas que estén ubicadas frente a parque. Se le añadirá un incremento del 3% al precio total de las vivienda que están ubicadas en esquina.",
  seeList: "Ver lista",
  allZones: "Todas las zonas",
  priceFrom: "Precio desde",
  priceM2Extra: "Precios por m² de terreno excedente",
  allPrices: "Todos los precios",
  attentionHours: "Horarios de atención",
  attentionHoursContent: "Lunes a domingo de 9:00 a.m. a 7:00 p.m.",
  floors_other: "{{count}} pisos",
  landArea_other: "{{count}} m²",
  rooms_other: "{{count}} habitaciones",
  login: "Ingresar",
  back: "Regresar",
  logout: "Cerrar sesión",
  myFavorites: "Mis favoritos",
  outstandingProjects: "Desarrollos Destacados",
  exploreDifferentModels: "Explora distintos modelos",
  familiesInTheCountry: "casas con Certificación EDGE",
  weHaveBuiltMoreThan: "Hemos construido más de",
  housesAndApartments: "casas y departamentos",
  investorsHaveHad: "Mobiliario reciclado",
  javerHasHelpedMoreThan: "Más de",
  ofBenefits: "piezas instaladas",
  statesWithinTheCountry: "estados dentro del país",
  weHavePresenceInMoreThan: "Tenemos presencia en más de",
  know: "Conoce",
  javer: "JAVER",
  knowJaverDescription:
    "En Javer, nos dedicamos al desarrollo de viviendas innovadoras, sostenibles y diseñadas para crear comunidades ideales para el bienestar de las familias en México. Gracias a la experiencia de nuestra empresa y el trabajo de nuestros expertos podrás tener la certeza de que aquí encontrarás el patrimonio que siempre deseaste.",
  learnAboutTheExperienceOfOurClients:
    "Conoce la experiencia de nuestros clientes",
  learnAboutOurRealEstate: "Preguntas Frecuentes",
  learnAboutOurRealEstateDescription:
    "Es normal tener dudas ante compras tan importantes como tu próxima casa. Si tu duda no se encuentra, ten la confianza de contactarnos por diferentes medios que te brindamos. Estamos para ayudarte.",
  makeTheHomeOfYourDreamsComeTrue: "Haz realidad el hogar de tus sueños",
  seeTheEntireState: "Ver todo el estado",
  findYourNewHome: "Encuentra tu nuevo hogar",
  findYourNewHomeDescription:
    "Contamos con espacios para que vivas feliz y en armonía.",
  selectState: "Selecciona un estado",
  selectZone: "Selecciona una zona",
  selectPrice: "Rango de precios",
  search: "Buscar",
  orderPrice: "Ordenar precio",
  viewBy: "Ver por",
  clients: "Clientes",
  becomeAnInvestor: "Conviértete en inversionista",
  stateOfInterest: "Estado de interés",
  thanksTitle: "¡Gracias!",
  thanksMessage: "En breve nos pondremos en contacto contigo",
  returnToHome: "Regresar a la página principal",
  restrooms_other: "{{count}} medios baños",
  noDevelopmentsFound: "No existen resultados que mostrar",
  viewAllPrices: "Ver todos los precios",
  floors_one: "{{count}} piso",
  landArea_one: "{{count}} m²",
  restrooms_one: "{{count}} medio baño",
  rooms_one: "{{count}} habitación",
  contactUsInCustomerService: "Contáctanos en servicio al cliente",
  tipsForMaintenance: "TIPS DE MANTENIMIENTO PARA TU VIVIENDA",
  tipsForMaintenanceDescription:
    "Descarga nuestra guía en PDF para el cuidado y mantenimiento de tu nuevo hogar.",
  downloadTipsForMaintenance: "Descargar guía",
  inMaintenance: "En mantenimiento...",
  inMaintenanceMessage: "ESTAMOS PARA AYUDARTE Y CUIDAR DE TU PATRIMONIO.",
  makeReport: "Realiza un reporte",
  makeReportDescription:
    "Estamos para ayudarte siempre que lo necesites, con gusto resolveremos esa inquietud que tienes.",
  streetAndNumber: "Calle y número",
  block: "Manzana",
  lot: "Lote",
  subject: "Asunto",
  accept: "Acepto",
  codeOfEthics: "Código de ética",
  codeOfEthicsPageDescription:
    "Bienvenidos al Código de Ética de JAVER. Este código refleja los valores y principios fundamentales que guían nuestras acciones y decisiones a diario. Al seguir este código, estamos comprometidos a mantener los más altos estándares de integridad, responsabilidad y respeto en todas nuestras interacciones y operaciones.",
  codeOfEthicsCheckboxMessage:
    "Al firmar y enviar el documento, me comprometo a cumplir con los valores y principios aquí establecidos.",
  codeOfEthicsNote:
    "Al finalizar la lectura de este Código de Ética, cada empleado y/o proveedor debe descargar una copia, leerla detenidamente, comprender su contenido y después deberá enviar el documento ya firmado aceptando las condiciones y haciendo clic en el siguiente botón:",
  important: "Importante",
  downloadCodeOfEthics: "Descargar Código de Ética",
  sendCodeOfEthics: "Enviar Código de Ética",
};

export default es;
