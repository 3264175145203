import { RiArrowGoBackLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./error-pages.css";

function Error400Page() {
  return (
    <div className="error-page">
      <p className="error-title">¡Lo siento!</p>
      <p className="error-description">Creo que hemos perdido señal</p>
      <img
        className="error-img"
        src="/images/error/400.png"
        alt="http 500 error"
      />
      <Link to="/" className="error-home-button">
        <RiArrowGoBackLine />
        <p>Regresar</p>
      </Link>
    </div>
  );
}

export default Error400Page;
