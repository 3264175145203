import { AvisoEmpleados } from "./Empleados";

export const EmpleadosServiciosCorporativos = () => {
  return (
    <AvisoEmpleados
      title={"Servicios Corporativos Javer, S.A. B. de C.V."}
      content={
        <p>
          <b>
            Cumpliendo con lo establecido en la Ley Federal de Protección de
            Datos Personales en Posesión de Particulares, en adelante la “Ley”;
            Servicios Corporativos Javer, S.A. B. de C.V. , en adelante
          </b>
          (“Javer”), con domicilio en 16 de septiembre #301, entre Hidalgo y
          Corregidora, Colonia Centro, San Pedro Garza Garcia, N.L, C.P. 66230,
          es responsable del uso, tratamiento y protección de la información y
          datos personales que recabe de usted o de aquellos a los que tuviere
          acceso por cualquier medio para brindarle algún servicio. Si usted no
          manifiesta su negativa para el tratamiento de sus datos, en el formato
          habilitado en este aviso para ejercer sus derechos ARCO, entenderemos
          que ha otorgado su consentimiento
        </p>
      }
    />
  );
};
