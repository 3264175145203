import { type Translation } from "@/types/translation";
import { useTranslation } from "react-i18next";
import { type IconType } from "react-icons";
import { HiOutlineHomeModern, HiOutlineSquare3Stack3D } from "react-icons/hi2";
import { PiBathtub, PiBedDuotone, PiCar } from "react-icons/pi";

export enum PropertyAmenityType {
  Bathrooms = "Bathrooms",
  Parking = "Parking",
  Garage = "Garage",
  Floors = "Floors",
  Land = "Land",
  Rooms = "Rooms",
}

interface PropertyAmenityProps {
  type: PropertyAmenityType;
  quantity: number;
  className?: string;
  size?: number;
  iconClassName?: string;
  disapearOnZero?: boolean;
}

const Icons: Record<PropertyAmenityType, IconType> = {
  [PropertyAmenityType.Bathrooms]: PiBathtub,
  [PropertyAmenityType.Parking]: PiCar,
  [PropertyAmenityType.Garage]: PiCar,
  [PropertyAmenityType.Floors]: HiOutlineHomeModern,
  [PropertyAmenityType.Land]: HiOutlineSquare3Stack3D,
  [PropertyAmenityType.Rooms]: PiBedDuotone,
};

const translations = {
  [PropertyAmenityType.Bathrooms]: "bathrooms_",
  [PropertyAmenityType.Parking]: "parkingLots_",
  [PropertyAmenityType.Garage]: "garageCapacity_",
  [PropertyAmenityType.Floors]: "floors_",
  [PropertyAmenityType.Land]: "landArea_",
  [PropertyAmenityType.Rooms]: "rooms_",
};

export function PropertyAmenity({
  type,
  quantity,
  className,
  iconClassName,
  size = 32,
  disapearOnZero = false,
}: PropertyAmenityProps) {
  const Icon = Icons[type];
  const { t } = useTranslation("translations");

  if (quantity === 0 && disapearOnZero) {
    return null;
  }

  return (
    <div className={`flex flex-col ${className}`}>
      <Icon
        size={size}
        className={`text-primary self-center ${iconClassName}`}
      />
      <div className="text-sm text-center whitespace-nowrap overflow-hidden text-ellipsis">
        {t(
          `${translations[type]}${quantity === 1 ? "one" : "other"}` as keyof Translation,
          {
            count: quantity,
          }
        )}
      </div>
    </div>
  );
}
