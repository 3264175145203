import { useForm } from "react-hook-form";
import { Label } from "../../shared-components/Label";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { postTerritorialReservationsForm } from "@/utils/api";
import { Button } from "@/components/ui/button";
import { toast } from "react-toastify";
export const ReservasForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    watch
  } = useForm();

  const isAccepted = watch("accept", false);

  const {mutate, isLoading} = useMutation("sendReservation", {
    mutationFn: postTerritorialReservationsForm,
    onSuccess: () => {
      toast.success("Reporte enviado correctamente");
      reset();
    },
    onError: () => {
      toast.error("Ocurrió un error al enviar el reporte");
    },
  });


  const { t } = useTranslation("translations");
  return (
    <form
      className="md:w-[800px] mt-5 flex gap-1  justify-center items-center flex-col"
      onSubmit={handleSubmit((data) => {
        delete data.accept;
        mutate(data);
      })}
    >
      <div className="flex mb-1  w-full">
        <Label className="font-bold">{t("personalData")}</Label>
      </div>
      <div className="flex flex-wrap md:flex-nowrap gap-2  w-full">
        <div className="flex flex-col w-full md:w-1/2">
          <Label>{t("names")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("Nombre")}
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2">
          <Label>{t("lastNames")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("Apellido")}
          />
        </div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap gap-2 w-full">
        <div className="flex flex-col w-full md:w-1/2">
          <Label>{t("email")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("Correo")}
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2">
          <Label>{t("phone")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("Telefono")}
          />
        </div>
      </div>

      <div className="flex mt-6 mb-1  w-full">
        <Label className="font-bold">{t("landData")}</Label>
      </div>
      <div className="flex flex-wrap md:flex-nowrap gap-2 w-full">
        <div className="flex flex-col w-full md:w-1/2">
          <Label>{t("Estado")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("Estado")}
          />
        </div>
        <div className="flex flex-col w-full md:w-1/2">
          <Label>{t("squareMeters")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("MetrosCuadrados")}
          />
        </div>
      </div>
      <div className="flex flex-wrap md:flex-nowrap gap-2 w-full mt-2">
        <div className="flex flex-col w-full md:w-1/3">
          <Label>{t("postalCode")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("CodigoPostal")}
          />
        </div>
        <div className="flex flex-col w-full md:w-1/3">
          <Label>{t("hectare")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("Hectareas")}
          />
        </div>
        <div className="flex flex-col w-full md:w-1/3">
          <Label>{t("squareMeterPriceLabel")}</Label>
          <input
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("PrecioPorMetroCuadrado")}
          />
        </div>
      </div>

      <div className="flex flex-col w-full mt-2">
        <Label>{t("description")}</Label>
        <textarea
          rows={10}
          minLength="200"
          className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
          {...register("Descripcion")}
        />
      </div>

      <div className="flex justify-between mt-5 items-center w-full">
        <div className="flex gap-2 justify-center items-center">
          <input
            type="checkbox"
            className="border-gray-100 bg-gray-100 rounded-md px-4 py-2"
            {...register("accept")}
          />
          <Label className="!mb-0">{t("acceptPolicies")}</Label>
        </div>
        <Link className="underline text-neutral-400 text-sm" to="/avisos-de-privacidad" target="_blank" rel="noreferrer">
          {t("checkPrivacyNotices")}
        </Link>
      </div>

      <div className="flex justify-end w-full mt-2">
        <Button
          type="submit"
          className="px-8"
          isLoading={isLoading}
          disabled={
            !isAccepted
          }
        >
          {t("submit")}
        </Button>
      </div>
    </form>
  );
};
