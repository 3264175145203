import i18n from "@/i18n";
import { type Project } from "@/models/project";
import { getResourceUrl } from "@/utils/image.utils";
import { Button } from "@/components/ui/button";
import { HiArrowDown, HiMail } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { LowestPriceProperty } from "@/components/LowestPriceProperty";
import { type Property } from "@/models/property";
import { type PropertySearch } from "@/models/property_search";
import { Link } from "react-scroll";
import { MapIcon } from "@/components/icons/MapIcon";

interface HousingDevelopmentHeaderProps {
  development?: Project;
  properties?: Property | PropertySearch[];
  lowestPrice?: number;
}

export function HousingDevelopmentHeader({
  development,
  lowestPrice,
  properties,
}: HousingDevelopmentHeaderProps) {
  const { t } = useTranslation("translations");
  return (
    <>
      <section
        className={`relative transition-all duration-300 ease-in overflow-hidden ${!development?.video_url?.trim() ? "max-h-96" : "max-h-screen"}`}
      >
        <video
          className="object-cover h-64 lg:h-auto lg:max-h-[70vh] z-0"
          src={getResourceUrl(development?.video_url)}
          autoPlay
          loop
          muted
          playsInline
          width="100%"
        />
        <div className="absolute top-1 left-0 w-full lg:h-full h-64 bg-gradient-to-b from-transparent via-transparent via-40% to-white z-10" />
        <div className="lg:absolute bottom-0 left-0 w-full flex flex-col lg:items-center gap-8 -mt-16 lg:mt-0 [&>*]:z-20">
          <div className="flex flex-col lg:flex-row lg:items-center gap-6 max-lg:container">
            <img
              className="w-32 h-32 bg-white rounded-md object-contain p-2 border"
              src={getResourceUrl(development?.logo_color)}
              alt={development?.name}
            />
            <div className="flex flex-col gap-2">
              <h3>{development?.name}</h3>
              <div className="flex flex-row gap-2 items-center text-foreground-soft text-xl">
                <MapIcon size={40} /> <span>{development?.ciudad}</span>-
                <Link
                  className="underline cursor-pointer"
                  to="location-section"
                  smooth
                >
                  {t("seeMap")}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-4 flex flex-col lg:items-center xl:max-w-5xl lg:mx-auto max-lg:container">
        <div className="mb-4">
          {lowestPrice && lowestPrice > 0 ? (
            <>
              <h6 className="text-foreground-soft">{t("fromPrice")}</h6>
              <div className="flex flex-row gap-4 items-center">
                <h3 className="text-primary">
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(lowestPrice)}
                </h3>
                <Dialog>
                  <DialogTrigger asChild>
                    <Button variant="ghost">{t("viewAllPrices")}</Button>
                  </DialogTrigger>
                  <DialogContent className="max-w-4xl">
                    <LowestPriceProperty
                      properties={properties}
                      project={development}
                    />
                  </DialogContent>
                </Dialog>
              </div>
            </>
          ) : (
            <h3 className="text-primary">{t("contactConsultant")}</h3>
          )}
        </div>
        {development && development.credit_types.length > 0 && (
          <div className="flex flex-col lg:flex-row lg:items-center gap-2 text-foreground-soft">
            <span>{t("creditTypes")}</span>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              {development.credit_types.map((creditName, index) => (
                <>
                  <span key={index} className="font-viga text-sm lg:text-base">
                    {creditName}
                  </span>
                  {index < development.credit_types.length - 1 && (
                    <span className="mx-1 font-semibold">|</span>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col lg:flex-row gap-4 mt-6">
          <Button asChild>
            <Link
              to="models-section"
              smooth
              className="flex items-center cursor-pointer"
            >
              {t("knowTheModels")}
              <HiArrowDown size={24} className="ml-2" />
            </Link>
          </Button>
          <Button variant="outline" asChild>
            <Link
              to="contact-section"
              smooth
              className="flex items-center cursor-pointer"
            >
              {t("contactUs")}
              <HiMail size={24} className="ml-2" />
            </Link>
          </Button>
        </div>
        <div className="mt-8">
          {i18n.language === "es"
            ? development?.long_description
            : development?.long_description_eng}
        </div>
      </section>
    </>
  );
}
