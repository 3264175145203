import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import {
  getInitialDataDesarrollos,
  getProjectByID,
  getProjects,
  getPropertiesSearch,
  getPropertyById,
} from "../../../utils/api";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { toUpperCase, toUrlCase } from "@/utils/common.utils";
import { PropertyFeatures } from "./sections/PropertyFeatures";
import { PropertyHeader } from "./sections/PropertyHeader";
import { Gallery } from "@/components/Gallery";
import { PropertyArchitecturalPlants } from "./sections/PropertyArchitecturalPlants";
import { Interweave } from "interweave";
import { AditionalInfoSection } from "@/components/AditionalInfoSection";
import { HiOutlineClock, HiOutlinePhone } from "react-icons/hi2";
import { DevelopmentContactForm } from "@/components/DevelopmentContactForm";

export const PropertyPage = () => {
  const { t, i18n } = useTranslation("translations");
  const { projectName, name } = useParams();

  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      setTimeout(() => {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 1000);
    }
  }, []);

  const { data: projects } = useQuery(
    "getProjects",
    async () => await getProjects()
  );

  const projectId = useMemo(
    () =>
      projects?.find(
        (project) => project.short_name === toUpperCase(projectName ?? "")
      )?.id,
    [projects, projectName]
  );

  const { data: project } = useQuery(
    ["getProjectByID", projectId],
    async () => await getProjectByID(projectId ?? -1),
    {
      enabled: !!projectId,
    }
  );

  const { data: properties } = useQuery(
    ["getPropertiesSearch", project?.id],
    async () => await getPropertiesSearch({ projectId: project?.id ?? -1 }),
    {
      enabled: !!project?.id,
    }
  );

  const propertyId = useMemo(
    () =>
      properties?.find(
        (property) => property.name.toUpperCase() === toUpperCase(name ?? "")
      )?.id,
    [properties]
  );

  const { data: property } = useQuery(
    ["getPropertyById", propertyId],
    async () => await getPropertyById(propertyId ?? -1),
    {
      enabled: !!propertyId,
    }
  );

  const { data: statesWithProjects } = useQuery(
    "getInitialDataDesarrollos",
    async () => await getInitialDataDesarrollos()
  );

  const state = useMemo(
    () =>
      statesWithProjects?.find((state) =>
        state.ciudades.find((city) =>
          city.proyectos.find((project) => project.id === projectId)
        )
      ),
    [statesWithProjects, projectId]
  );

  const lowestPrice = useMemo(
    () =>
      property?.precios.reduce(
        (acc, price) => (price.price_base < acc ? price.price_base : acc),
        property.precios[0]?.price_base
      ),
    [property]
  );

  return (
    <div>
      <Breadcrumb>
        <BreadcrumbList>
          <BreadcrumbItem>
            <BreadcrumbLink to="/">{t("homeStart")}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink to={`/estados/${toUrlCase(state?.name ?? "")}`}>
              {t("developments")}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink to={`/desarrollos/${projectName}`}>
              {project?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbLink
              to={`/desarrollos/${projectName}/propiedad/${name}`}
              className="text-primary"
            >
              {property?.name}
            </BreadcrumbLink>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>
      <PropertyHeader
        project={project}
        property={property}
        lowestPrice={lowestPrice}
      />
      <PropertyFeatures property={property} />
      {property?.additional_info && (
        <AditionalInfoSection info={property?.additional_info} />
      )}
      <PropertyArchitecturalPlants property={property} />

      {property?.virtual_tour_iframe && (
        <section className="container xl:max-w-5xl mx-auto mt-16">
          <h4>{t("virtualTour")}</h4>
          <hr className="my-6 border-primary" />

          <Interweave
            allowList={["iframe"]}
            className="w-full aspect-video"
            content={property.virtual_tour_iframe}
          />
        </section>
      )}

      <section id="gallery-section" className="pt-8">
        <Gallery
          images={property?.extra_images
            ?.sort((a, b) => a.order - b.order)
            ?.map((image) => image.url)}
        />
      </section>

      <section
        id="contact-section"
        className="container xl:max-w-5xl mx-auto my-16"
      >
        <h4>{t("housingDevelopmentContactTitle")}</h4>
        <h3 className="text-primary mt-1">{property?.name}</h3>
        <p className="text-slate-600 mt-2">
          {t("housingDevelopmentContactDescription")}
        </p>
        <hr className="my-6 border-primary" />
        <div className="lg:px-32">
          <div className="flex flex-col md:flex-row justify-around mb-8 gap-4">
            <div className="flex flex-row gap-4 items-center">
              <HiOutlinePhone className="text-primary" size={28} />
              <div>
                <p>{t("contact")}</p>
                <strong>{project?.contact_form?.phone_number}</strong>
              </div>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <HiOutlineClock className="text-primary" size={28} />
              <div>
                <p>{t("attentionHours")}</p>
                <strong>
                  {i18n.language === "es"
                    ? project?.contact_form?.opening_hours?.es
                    : project?.contact_form?.opening_hours?.en}
                </strong>
              </div>
            </div>
          </div>
          <DevelopmentContactForm development={project} />
        </div>
      </section>
    </div>
  );
};

export default PropertyPage;
