import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
  useRef,
} from "react";

import {
  getInitialDataDesarrollos,
  registerUserAPI,
  loginUserAPI,
  refreshTokenAPI,
  logoutUserAPI,
  getAllFavoritesUser,
  setFavoritesUserAPI,
  getProjects,
  getActiveSections,
  getWebsiteMedia,
} from "../utils/api";

import {
  initialFooterSections,
  initialNavbarSections,
} from "../utils/sectionsData";

import { toast } from "react-toastify";
import { type LatLng } from "../types/lat_lon";
import { ApiError } from "../types/api_error";
import { type GlobalData } from "../types/global_data";
import { type Favorite } from "../models/user_all_favorites";
import { type Timeout, type Timer } from "../types/node_types";
import { type LocationHierarchy } from "../models/location_hierarchy";
import { type AuthorizationResponse } from "../models/authorization";
import { type Footer, type Navbar } from "../models/section";

const DataContext = createContext<GlobalData>({
  dataAuth: {},
  setDataAuth: () => {},
  loginUser: async () => ({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    token: "",
  }),
  navbarSections: [],
  footerSections: [],
  registerUser: async () => ({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    token: "",
  }),
  dataDesarrollos: [],
  favoritesData: { list: [], error: "" },
  getDistance: () => null,
  logoutUser: async () => {},
  lonLat: null,
  openLogin: false,
  setFavoriteProperty: async () => {},
  setLonLat: () => {},
  setOpenLogin: () => {},
  websiteMedia: {
    home_video: "",
    lotes_image: "",
    reservas_image: "",
    nosotros_1_image: "",
    nosotros_2_image: "",
    nosotros_3_image: "",
    certs_image: "",
    decalogos_image: "",
    blog_image: "",
  },
  requestGeoPosition: () => {},
});

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
  const sessionTokenKey = "session_token";
  const timeoutRef = useRef<Timeout | null>(null);
  const intervalRef = useRef<Timer | null>(null);

  const [dataAuth, setDataAuth] = useState<{
    firstName?: string;
    lastName?: string;
    token?: string;
    email?: string;
    phone?: string;
  }>({});

  const [lonLat, setLonLat] = useState<LatLng | null>(
    JSON.parse(localStorage.getItem("lonLat") ?? "null") as LatLng | null
  );

  const [navbarSections, setNavbarSections] = useState<Navbar[]>(
    initialNavbarSections
  );
  const [footerSections, setFooterSections] = useState<Footer[]>([]);
  const [websiteMedia, setWebsiteMedia] = useState({
    home_video: "",
    lotes_image: "",
    reservas_image: "",
    nosotros_1_image: "",
    nosotros_2_image: "",
    nosotros_3_image: "",
    certs_image: "",
    decalogos_image: "",
    blog_image: "g",
  });

  function haversineDistanceBetweenPoints(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number
  ) {
    const R = 6371;
    const p1 = (lat1 * Math.PI) / 180;
    const p2 = (lat2 * Math.PI) / 180;
    const deltaLon = lon2 - lon1;
    const deltaLambda = (deltaLon * Math.PI) / 180;
    return (
      Math.acos(
        Math.sin(p1) * Math.sin(p2) +
          Math.cos(p1) * Math.cos(p2) * Math.cos(deltaLambda)
      ) * R
    );
  }

  const getDistance = (lat2: number, lon2: number) => {
    if (!lonLat) return null;
    const lat1 = lonLat.lat;
    const lon1 = lonLat.lon;

    return haversineDistanceBetweenPoints(lat1, lon1, lat2, lon2);
  };

  const [openLogin, setOpenLogin] = useState(false);
  const [dataDesarrollos, setDataDesarrollos] = useState<LocationHierarchy[]>(
    []
  );
  const [favoritesData, setFavoritesData] = useState<{
    list: Favorite[];
    error?: string;
  }>({
    list: [] as Favorite[],
    error: "",
  });

  const fetchDataFavorites = async (token?: string) => {
    try {
      const data = await getAllFavoritesUser(token ?? "");
      setFavoritesData({ list: data.favorites });
    } catch (error) {
      setFavoritesData({ list: [], error: "Error al obtener favoritos" });
    }
  };

  useEffect(() => {
    if (dataAuth?.token) {
      fetchDataFavorites(dataAuth.token);
      intervalRef.current = setInterval(async () => {
        await fetchDataFavorites(dataAuth.token);
      }, 60000);
    }
    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, [dataAuth]);

  const requestGeoPosition = useCallback(() => {
    if (
      navigator.geolocation &&
      navigator.permissions?.query &&
      localStorage.getItem("lonLat") === null
    ) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then((result) => {
          if (result.state === "granted" || result.state === "prompt") {
            navigator.geolocation.getCurrentPosition((position) => {
              const data = {
                lon: position.coords.longitude,
                lat: position.coords.latitude,
              };
              setLonLat(data);
              localStorage.setItem("lonLat", JSON.stringify(data));
            });
          }
        })
        .catch(() => {
          toast.error("Error al solicitar permiso de ubicación");
        });
    }
  }, []);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      let data: AuthorizationResponse | undefined;
      try {
        const dataRaw = window.localStorage.getItem(sessionTokenKey);
        data = JSON.parse(dataRaw ?? "{}");
      } catch {
        data = undefined;
      }

      if (data?.token) {
        refreshTokenAPI(data.token)
          .then((dataUser) => {
            if (
              dataUser?.token &&
              dataUser?.primary_email &&
              dataUser?.first_name
            ) {
              const parsedDataUser = {
                email: dataUser.primary_email,
                firstName: dataUser.first_name,
                lastName: dataUser.last_name,
                phone: dataUser.primary_phone,
                token: dataUser.token,
              };
              window.localStorage.setItem(
                sessionTokenKey,
                JSON.stringify(parsedDataUser)
              );
              setDataAuth(parsedDataUser);
            } else {
              window.localStorage.removeItem(sessionTokenKey);
            }
          })
          .catch(() => {
            window.localStorage.removeItem(sessionTokenKey);
            setDataAuth({});
          });
      }

      getProjects().then((l) => {
        localStorage.setItem("desarrollos", JSON.stringify(l));
      });

      getInitialDataDesarrollos()
        .then((list) => {
          if (list.length > 0) {
            setDataDesarrollos(list);
          }
        })
        .catch(() => {
          toast.error("Error al obtener los desarrollos");
        });

      getActiveSections()
        .then((data) => {
          setNavbarSections(data?.navbar ?? initialNavbarSections);
          setFooterSections(data?.footer ?? initialFooterSections);
        })
        .catch(() => {
          setNavbarSections(initialNavbarSections);
          setFooterSections(initialFooterSections);
        });

      getWebsiteMedia()
        .then((listMedia) => {
          const obj: Record<string, string> = {};
          listMedia.forEach((item) => {
            obj[item.key] = item.value;
          });
          setWebsiteMedia(obj as typeof websiteMedia);
        })
        .catch(() => toast.error("Error al obtener la media del sitio."));

      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }, 10);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, []);

  const loginUser = useCallback(
    async (email: string, password: string) => {
      try {
        const dataUser = await loginUserAPI(email, password);
        if (
          dataUser?.token &&
          dataUser?.primary_email &&
          dataUser?.first_name
        ) {
          const parsedDataUser = {
            email: dataUser.primary_email,
            firstName: dataUser.first_name,
            lastName: dataUser.last_name,
            phone: dataUser.primary_phone,
            token: dataUser.token,
          };
          window.localStorage.setItem(
            sessionTokenKey,
            JSON.stringify(parsedDataUser)
          );
          setDataAuth(parsedDataUser);
          return parsedDataUser;
        } else {
          setDataAuth({});
          window.localStorage.removeItem(sessionTokenKey);
          return { error: "Data no recibida" };
        }
      } catch (e) {
        const error =
          e instanceof ApiError
            ? e.message
            : "Error al conectar con el servidor";

        setDataAuth({});
        window.localStorage.removeItem(sessionTokenKey);
        return { error };
      }
    },
    [setDataAuth]
  );

  const registerUser = useCallback(
    async (
      firstName: string,
      lastName: string,
      password: string,
      primaryEmail: string,
      primaryPhone: string
    ) => {
      try {
        const dataUser = await registerUserAPI(
          firstName,
          lastName,
          password,
          primaryEmail,
          primaryPhone !== "" ? primaryPhone : undefined
        );

        if (
          dataUser?.token &&
          dataUser?.primary_email &&
          dataUser?.first_name
        ) {
          const parsedDataUser = {
            email: dataUser.primary_email,
            firstName: dataUser.first_name,
            lastName: dataUser.last_name,
            phone: dataUser.primary_phone,
            token: dataUser.token,
          };
          window.localStorage.setItem(
            sessionTokenKey,
            JSON.stringify(parsedDataUser)
          );
          setDataAuth(parsedDataUser);
          return parsedDataUser;
        } else {
          setDataAuth({});
          window.localStorage.removeItem(sessionTokenKey);
          return { error: "Data no recibida" };
        }
      } catch (e) {
        setDataAuth({});
        window.localStorage.removeItem(sessionTokenKey);
        const error =
          e instanceof ApiError ? e.message : "Error al registrar usuario";
        return { error };
      }
    },
    [setDataAuth]
  );

  const logoutUser = useCallback(async () => {
    try {
      if (dataAuth.token) {
        await logoutUserAPI(dataAuth.token);
      }
    } finally {
      window.localStorage.removeItem(sessionTokenKey);
      setDataAuth({});
    }
  }, [setDataAuth, dataAuth]);

  const setFavoriteProperty = useCallback(
    async (propertyId: number) => {
      try {
        if (dataAuth.token) {
          const data = await setFavoritesUserAPI(dataAuth.token, propertyId);
          setFavoritesData({ list: data.favorites });
        }
      } catch {
        setFavoritesData({
          list: [],
          error: "Error al actualizar un favorito. Recargue la pagina",
        });
      }
    },
    [dataAuth]
  );

  const globalData = {
    dataAuth,
    setDataAuth,
    loginUser,
    navbarSections,
    footerSections,
    registerUser,
    logoutUser,
    setFavoriteProperty,
    favoritesData,
    dataDesarrollos,
    websiteMedia,
    openLogin,
    setOpenLogin,
    lonLat,
    setLonLat,
    getDistance,
    requestGeoPosition,
  };

  return (
    <DataContext.Provider value={globalData}>{children}</DataContext.Provider>
  );
};

export const useAppContext = () => useContext(DataContext);
