import { getResourceUrl } from "@/utils/image.utils";
import { Button } from "@/components/ui/button";
import { HiArrowDown, HiCamera, HiHeart, HiOutlineHeart } from "react-icons/hi";
import { useTranslation } from "react-i18next";
import { type Property } from "@/models/property";
import { type Project } from "@/models/project";
import { EdgeLogo } from "@/components/icons/EdgeLogo";
import { useAppContext } from "@/data/DataProvider";
import { useMemo } from "react";
import { useMutation } from "react-query";
import { Loader } from "@/components/Loader";
import { LowestPriceProperty } from "@/components/LowestPriceProperty";
import { Dialog, DialogContent, DialogTrigger } from "@/components/ui/dialog";
import { Link } from "react-scroll";

interface PropertyHeaderProps {
  project?: Project;
  property?: Property;
  lowestPrice?: number;
}

export function PropertyHeader({
  project,
  property,
  lowestPrice,
}: PropertyHeaderProps) {
  const { t } = useTranslation("translations");
  const { setFavoriteProperty, favoritesData, dataAuth, setOpenLogin } =
    useAppContext();

  const { isLoading, mutate } = useMutation({
    mutationFn: setFavoriteProperty,
  });

  const isFavorite = useMemo(
    () => favoritesData.list.find((fav) => fav.id === property?.id),
    [favoritesData, property]
  );

  return (
    <>
      <section
        className={`relative transition-all duration-300 ease-in overflow-hidden ${!property?.main_image?.trim() ? "max-h-96" : "max-h-screen"}`}
      >
        <img
          className="object-cover h-64 lg:h-auto lg:max-h-[70vh] z-0"
          src={getResourceUrl(property?.main_image)}
          width="100%"
        />
        <div className="absolute top-1 left-0 w-full lg:h-full h-64 bg-gradient-to-b from-transparent via-transparent via-40% to-white z-10" />
        <div className="lg:absolute bottom-0 left-[50%] lg:translate-x-[-50%] w-full flex flex-col gap-8 -mt-16 lg:mt-0 z-20 [&>*]:z-20 xl:max-w-5xl container">
          <div className="flex flex-col gap-6">
            <img
              className="w-32 h-32 bg-white rounded-md object-contain p-2 border"
              src={getResourceUrl(project?.logo_color)}
              alt={property?.name}
            />
            <div className="flex flex-col gap-4 lg:gap-8">
              <h3>{property?.name}</h3>
            </div>
          </div>
          <div>
            {lowestPrice && lowestPrice > 0 ? (
              <>
                <h6 className="text-foreground/70">{t("fromPrice")}</h6>
                <div className="flex flex-row gap-4 items-center">
                  <h3 className="text-primary">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                    }).format(lowestPrice)}
                  </h3>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant="ghost">{t("viewAllPrices")}</Button>
                    </DialogTrigger>
                    <DialogContent className="max-w-4xl">
                      <LowestPriceProperty
                        properties={property}
                        project={project}
                      />
                    </DialogContent>
                  </Dialog>
                </div>
              </>
            ) : (
              <h3 className="text-primary">{t("contactConsultant")}</h3>
            )}
          </div>
        </div>
      </section>
      <section className="mt-8 flex flex-col xl:max-w-5xl lg:mx-auto container">
        {project && project.credit_types.length > 0 && (
          <div className="flex flex-col lg:flex-row gap-4">
            <span className="whitespace-nowrap">{t("creditTypes")}</span>
            <div className="flex flex-row items-center gap-2 flex-wrap">
              {project.credit_types.map((creditName, index) => (
                <>
                  <span key={index} className="font-viga text-sm lg:text-base">
                    {creditName}
                  </span>
                  {index < project.credit_types.length - 1 && (
                    <span className="mx-1 font-semibold">|</span>
                  )}
                </>
              ))}
            </div>
          </div>
        )}
        <div className="mt-6 flex flex-col-reverse md:flex-row gap-4 justify-between md:items-end">
          <div className="flex flex-col lg:flex-row gap-4">
            <Button asChild>
              <Link
                to="features-section"
                className="flex items-center cursor-pointer"
                smooth
              >
                {t("knowMore")}
                <HiArrowDown size={24} className="ml-2" />
              </Link>
            </Button>
            <Button variant="outline" asChild>
              <Link
                to="gallery-section"
                className="flex items-center cursor-pointer"
                smooth
              >
                {t("photoGallery")}
                <HiCamera size={24} className="ml-2" />
              </Link>
            </Button>
            <Button
              variant="ghost"
              disabled={isLoading}
              onClick={() => {
                if (dataAuth.token) {
                  mutate(property?.id ?? 0);
                } else {
                  setOpenLogin(true);
                }
              }}
            >
              {t("save")}
              {isLoading ? (
                <Loader className="ml-2" />
              ) : isFavorite ? (
                <HiHeart size={24} className="ml-2" />
              ) : (
                <HiOutlineHeart size={24} className="ml-2" />
              )}
            </Button>
          </div>
          {!!property?.isEdgeCertified && <EdgeLogo />}
        </div>
      </section>
    </>
  );
}
