import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import es from "./i18n/es";
import en from "./i18n/en";

export const defaultNS = "es";
export const resources = {
  es: {
    translations: es,
  },
  en: {
    translations: en,
  },
};

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    defaultNS,
    resources,

    fallbackLng: "es",
    debug: true,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
