import { Link } from "react-router-dom";
import { formatDate } from "../../utils/formatDate";
import { Img } from "../Img";
import { Text } from "../Text";

export const BlogRecentArticles = ({ articles }) => {
  return (
    <div className="gap-[30px] grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  items-center justify-between w-full">
      {articles.map((v) => (
        <Link
          to={"/blog/" + v.id}
          key={v.title}
          className=" h-[500px] relative shadow-md w-full"
        >
          <Img
            className="h-[500px] brightness-50 m-auto object-cover w-full"
            src={v.image}
            alt={v.title}
          />
          <div className="absolute bottom-[11%] flex flex-col inset-x-[0] items-center justify-start mx-auto w-[83%]">
            <Text
              className="sm:text-2xl md:text-[26px] text-[28px] text-gray-50 w-full"
              size="txtRobotoRomanSemiBold28"
            >
              {v.title}
            </Text>
            <Text
              className="mt-[29px] text-sm text-gray-50 w-full"
              size="txtRobotoRomanRegular14"
            >
              {v.description}
            </Text>
            <div className="flex flex-row items-center justify-between mt-[49px] w-[99%] md:w-full">
              <Text
                className="italic text-gray-50 text-xs"
                size="txtOpenSansItalicRegular12"
              >
                {v.author?.length <= 1 ? "Por Javer" : v.author}
              </Text>
              <Text
                className="text-right text-gray-50 text-xs"
                size="txtOpenSansSemiBold12"
              >
                {formatDate(v.date)}
              </Text>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};
