import { NextButton, PreviousButton } from "@/components/ui/carousel";
import { type Property } from "@/models/property";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import ImageZoom from "react-image-zooom";

interface PropertyArchitecturalPlantsProps {
  property?: Property;
}

export function PropertyArchitecturalPlants({
  property,
}: PropertyArchitecturalPlantsProps) {
  const { t, i18n } = useTranslation("translations");
  const [selectedBlueprint, setSelectedBlueprint] = useState(0);
  const bluePrint = useMemo(
    () => property?.blueprints?.[selectedBlueprint],
    [property, selectedBlueprint]
  );

  if (!property?.blueprints || property.blueprints.length === 0) {
    return <></>;
  }

  return (
    <section
      className="container xl:max-w-5xl mx-auto mt-16"
      id="features-section"
    >
      <div className="flex flex-row justify-between">
        <h4>{t("architecturalPlans")}</h4>
        <div className="flex flex-row gap-4">
          <PreviousButton
            onClick={() => {
              setSelectedBlueprint(
                (selectedBlueprint - 1 + (property.blueprints?.length ?? 0)) %
                  (property.blueprints?.length ?? 0)
              );
            }}
          />
          <NextButton
            onClick={() => {
              setSelectedBlueprint(
                (selectedBlueprint + 1) % (property.blueprints?.length ?? 0)
              );
            }}
          />
        </div>
      </div>
      <hr className="my-6 border-primary" />

      <div className="flex flex-col md:flex-row gap-4">
        <ul className="grow">
          <h6 className="mb-2 text-primary">
            {bluePrint?.title?.[i18n.language as "en" | "es"]}
          </h6>
          {bluePrint?.characteristics_architectural_plans?.map(
            (plant, index) => (
              <li
                key={index}
                className="px-4 py-1 flex flex-row items-center gap-4"
              >
                <div>•</div>
                <div>{plant[i18n.language as "en" | "es"]}</div>
              </li>
            )
          )}
        </ul>

        <ImageZoom
          src={bluePrint?.image_url ?? ""}
          zoom={200}
          className="!max-h-[1000px] md:!w-[50%] [&>*]:!max-h-[1000px] [&>*]:object-contain !bg-background"
        />
      </div>
    </section>
  );
}
