import { useEffect, useState } from "react";
import { IoIosEye, IoIosEyeOff } from "react-icons/io";
import { useAppContext } from "../data/DataProvider";
import { capitalizeString } from "../utils/common.utils";
import "./LoginModal.css";
import { toast } from "react-toastify";

const initialLoginData = {
  email: "",
  validEmail: false,
  password: "",
  validPassword: false,
  passwordVisibility: false,
  error: "",
};
const initialSignUpData = {
  firstName: "",
  validFirstName: false,
  lastName: "",
  validLastName: false,
  email: "",
  validEmail: false,
  password: "",
  validPassword: false,
  passwordVisibility: false,
  continue: false,
  confirmPassword: "",
  confirmPasswordVisibility: false,
  phone: "",
  validPhone: true,
  showPrefixPhone: false,
  acceptPolicy: false,
  error: "",
};

function validateEmail(email) {
  const re =
    /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
  return re.test(email);
}

function validatePassword(password) {
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
  return re.test(password);
}

function validateName(name) {
  const re = /^[a-zA-Z\s]{3,}$/;
  try {
    return re.test(name.trim());
  } catch {
    return false;
  }
}

function validatePhone(phone) {
  const re = /^[0-9]{9,11}$/;
  return re.test(phone);
}

function LoginModal() {
  const {
    openLogin,
    setOpenLogin,
    dataAuth,
    loginUser,
    logoutUser,
    registerUser,
  } = useAppContext();

  const [formLoginData, setFormLoginData] = useState(initialLoginData);

  const [formSignUpData, setFormSignUpData] = useState(initialSignUpData);

  const resetAndClose = () => {
    setOpenLogin(false);
    setFormLoginData(initialLoginData);
    setFormSignUpData(initialSignUpData);
  };

  const [pageLogin, setPageLogin] = useState("login"); // login, register, already_in
  const [privacyAlert, setPrivacyAlert] = useState(false);

  useEffect(() => {
    if (dataAuth.token && !privacyAlert) setPageLogin("already_in");
  }, [dataAuth, privacyAlert]);

  useEffect(() => {
    if (
      pageLogin === "already_in" &&
      !privacyAlert &&
      JSON.parse(localStorage.getItem("welcome") ?? "true")
    ) {
      toast(`Bienvenido, ${capitalizeString(dataAuth?.firstName ?? "")}
              ${capitalizeString(dataAuth?.lastName ?? "")}!`);
      localStorage.setItem("welcome", false);
    }
  }, [pageLogin, privacyAlert, dataAuth]);

  return (
    <div
      style={{
        display: openLogin ? "flex" : "none",
      }}
      className="login-modal-outer"
    >
      <div className="login-modal-box">
        {pageLogin === "login" && (
          <>
            <div className="login-close" onClick={resetAndClose}></div>
            <h6 className="mb-2">¡TE DAMOS LA BIENVENIDA!</h6>
            <form>
              <div className="container-input-login">
                <p>Correo</p>
                <input
                  type="email"
                  required
                  value={formLoginData.email}
                  onChange={(e) => {
                    setFormLoginData((prevState) => ({
                      ...prevState,
                      email: e.target.value.trim().toLowerCase(),
                      validEmail: validateEmail(
                        e.target.value.trim().toLowerCase()
                      ),
                      error: "",
                    }));
                  }}
                />
              </div>
              <div className="container-input-login">
                <p>Contraseña</p>
                <input
                  type={formLoginData.passwordVisibility ? "text" : "password"}
                  value={formLoginData.password}
                  onChange={(e) => {
                    setFormLoginData((prevState) => ({
                      ...prevState,
                      password: e.target.value,
                      validPassword: validatePassword(e.target.value.trim()),
                      error: "",
                    }));
                  }}
                />
                <div
                  onClick={() => {
                    setFormLoginData((prevState) => ({
                      ...prevState,
                      passwordVisibility: !prevState.passwordVisibility,
                    }));
                  }}
                >
                  {formLoginData.passwordVisibility ? (
                    <IoIosEyeOff />
                  ) : (
                    <IoIosEye />
                  )}
                </div>
              </div>
            </form>
            {!formLoginData.validEmail && formLoginData.email !== "" ? (
              <div className="email-valid-prompt">
                El formato de email es invalido
              </div>
            ) : null}
            {!formLoginData.validPassword && formLoginData.password !== "" ? (
              <div className="password-valid-prompt">
                La contraseña debe ser de al menos 8 caracteres, contener un
                numero, una mayuscula, una minuscula y un caracter especial
              </div>
            ) : null}
            {formLoginData.error ? (
              <div className="password-valid-prompt">{formLoginData.error}</div>
            ) : null}
            <button
              className={`login-button ${
                formLoginData.validEmail && formLoginData.validPassword
                  ? "active"
                  : ""
              }`}
              onClick={async () => {
                if (formLoginData.validEmail && formLoginData.validPassword) {
                  try {
                    const userData = await loginUser(
                      formLoginData.email,
                      formLoginData.password
                    );
                    if (userData?.error) {
                      setFormLoginData({
                        ...initialLoginData,
                        error: userData.error,
                      });
                      return;
                    }
                    resetAndClose();
                    setPageLogin("already_in");
                  } catch {
                    setFormLoginData((prevState) => ({
                      ...prevState,
                      error: "Error al iniciar sesion ",
                    }));
                  }
                }
              }}
            >
              CONTINUAR
            </button>
            <a
              onClick={() => {
                setPageLogin("register");
              }}
            >
              Registrarse
            </a>
            {/* <sub>O inicia sesion con</sub>
            <div className="login-sso-row">
              <div className="sso-icon-google"></div>
              <div className="sso-icon-facebook"></div>
            </div> */}
          </>
        )}
        {pageLogin === "register" && (
          <>
            <div
              className="login-close"
              onClick={() => {
                resetAndClose();
                setPageLogin("login");
              }}
            ></div>
            <h6>
              {formSignUpData.continue
                ? "¡SOLO UNOS PASOS MÁS!"
                : "¡TE DAMOS LA BIENVENIDA!"}
            </h6>
            <p className="register-text">
              {!formSignUpData.continue ? (
                <>
                  Crear tu cuenta es muy sencillo. Agrega propiedades de tu
                  interés a favoritos, da seguimiento con tu agente inmobiliario
                  y mantente pendiente de las noticias de <span>JAVER</span> en
                  el newsletter.
                </>
              ) : (
                ""
              )}
            </p>
            <div className="flex-grow-port-only"></div>
            <div className="font-bold">Registrate aquí.</div>

            <form>
              <div className="container-input-login">
                <p>Nombre</p>
                <input
                  disabled={formSignUpData.continue}
                  type="text"
                  value={formSignUpData.firstName}
                  onChange={(e) => {
                    setFormSignUpData((prevState) => ({
                      ...prevState,
                      firstName: e.target.value.trim(),
                      validFirstName: validateName(e.target.value.trim()),
                    }));
                  }}
                />
              </div>
              <div className="container-input-login">
                <p>Apellido</p>
                <input
                  disabled={formSignUpData.continue}
                  type="text"
                  value={formSignUpData.lastName}
                  onChange={(e) => {
                    setFormSignUpData((prevState) => ({
                      ...prevState,
                      lastName: e.target.value.trim(),
                      validLastName: validateName(e.target.value.trim()),
                    }));
                  }}
                />
              </div>
              <div className="container-input-login">
                <p>Correo</p>
                <input
                  disabled={formSignUpData.continue}
                  type="email"
                  value={formSignUpData.email}
                  onChange={(e) => {
                    setFormSignUpData((prevState) => ({
                      ...prevState,
                      email: e.target.value.trim().toLowerCase(),
                      validEmail: validateEmail(
                        e.target.value.trim().toLowerCase()
                      ),
                    }));
                  }}
                />
              </div>
              <div className="container-input-login">
                <p>Teléfono (opcional)</p>
                {formSignUpData.phone !== "" ||
                formSignUpData.showPrefixPhone ? (
                  <p className="prefix-tel-mx">+52</p>
                ) : null}
                <input
                  className="input-tel"
                  disabled={formSignUpData.continue}
                  type="number"
                  onFocus={() => {
                    setFormSignUpData((prevState) => ({
                      ...prevState,
                      showPrefixPhone: true,
                    }));
                  }}
                  onBlur={() => {
                    setFormSignUpData((prevState) => ({
                      ...prevState,
                      showPrefixPhone: false,
                    }));
                  }}
                  value={formSignUpData.phone}
                  onChange={(e) => {
                    setFormSignUpData((prevState) => ({
                      ...prevState,
                      phone: e.target.value.trim(),
                      validPhone:
                        e.target.value.trim() === "" ||
                        validatePhone(e.target.value.trim()),
                    }));
                  }}
                />
              </div>
              {formSignUpData.continue ? (
                <>
                  <div className="container-input-login">
                    <p>Contraseña</p>
                    <input
                      type={
                        formSignUpData.passwordVisibility ? "text" : "password"
                      }
                      value={formSignUpData.password}
                      onChange={(e) => {
                        setFormSignUpData((prevState) => ({
                          ...prevState,
                          password: e.target.value.trim(),
                          validPassword: validatePassword(
                            e.target.value.trim()
                          ),
                        }));
                      }}
                    />
                    <div
                      onClick={() => {
                        setFormSignUpData((prevState) => ({
                          ...prevState,
                          passwordVisibility: !prevState.passwordVisibility,
                        }));
                      }}
                    >
                      {formSignUpData.passwordVisibility ? (
                        <IoIosEyeOff />
                      ) : (
                        <IoIosEye />
                      )}
                    </div>
                  </div>
                  <div className="container-input-login">
                    <p>Confirmar Contraseña</p>
                    <input
                      type={
                        formSignUpData.confirmPasswordVisibility
                          ? "text"
                          : "password"
                      }
                      value={formSignUpData.confirmPassword}
                      onChange={(e) => {
                        setFormSignUpData((prevState) => ({
                          ...prevState,
                          confirmPassword: e.target.value,
                        }));
                      }}
                    />
                    <div
                      onClick={() => {
                        setFormSignUpData((prevState) => ({
                          ...prevState,
                          confirmPasswordVisibility:
                            !prevState.confirmPasswordVisibility,
                        }));
                      }}
                    >
                      {formSignUpData.confirmPasswordVisibility ? (
                        <IoIosEyeOff />
                      ) : (
                        <IoIosEye />
                      )}
                    </div>
                  </div>
                  <div className="container-accept-policy">
                    <input
                      id="accept-policy-check"
                      type="checkbox"
                      checked={formSignUpData.acceptPolicy}
                      onChange={(e) => {
                        setFormSignUpData((prevState) => ({
                          ...prevState,
                          acceptPolicy: e.target.checked,
                        }));
                      }}
                    />
                    <p>
                      Al marcar esta casilla acepto haber leído y estar de
                      acuerdo con los terminos y condiciones esclarecidos por
                      javer.com
                    </p>
                  </div>
                </>
              ) : null}
            </form>
            {!formSignUpData.validLastName && formSignUpData.lastName !== "" ? (
              <div className="email-valid-prompt">El apellido es invalido</div>
            ) : null}
            {!formSignUpData.validFirstName &&
            formSignUpData.firstName !== "" ? (
              <div className="email-valid-prompt">El nombre es invalido</div>
            ) : null}
            {!formSignUpData.validPhone && formSignUpData.phone !== "" ? (
              <div className="email-valid-prompt">
                El formato de telefono es invalido
              </div>
            ) : null}
            {!formSignUpData.validEmail && formSignUpData.email !== "" ? (
              <div className="email-valid-prompt">
                El formato de email es invalido
              </div>
            ) : null}
            {formSignUpData.confirmPassword !== formSignUpData.password &&
            formSignUpData.confirmPassword !== "" ? (
              <div className="email-valid-prompt">
                Las contraseñas no coinciden
              </div>
            ) : null}
            {!formSignUpData.validPassword && formSignUpData.password !== "" ? (
              <div className="email-valid-prompt">
                La contraseña debe ser de al menos 8 caracteres, contener un
                numero, una mayuscula, una minuscula y un caracter especial.
              </div>
            ) : null}
            {formSignUpData.error !== "" ? (
              <div className="email-valid-prompt">{formSignUpData.error}</div>
            ) : null}
            <div className="login-row">
              <button
                onClick={async () => {
                  if (
                    !formSignUpData.continue &&
                    formSignUpData.validEmail &&
                    formSignUpData.validFirstName &&
                    formSignUpData.validLastName &&
                    formSignUpData.validPhone
                  ) {
                    setFormSignUpData((prevState) => ({
                      ...prevState,
                      continue: true,
                    }));
                  } else if (
                    formSignUpData.validEmail &&
                    formSignUpData.validFirstName &&
                    formSignUpData.validLastName &&
                    formSignUpData.validPassword
                  ) {
                    try {
                      const userData = await registerUser(
                        formSignUpData.firstName,
                        formSignUpData.lastName,
                        formSignUpData.password,
                        formSignUpData.email,
                        formSignUpData.phone
                      );

                      if (userData?.error) {
                        setFormSignUpData({
                          ...initialSignUpData,
                          error: userData.error,
                        });
                        return;
                      }
                      setPrivacyAlert(true);
                      setPageLogin("already_in");
                    } catch {
                      setFormSignUpData((prevState) => ({
                        ...prevState,
                        error: "Error al realizar el registro",
                      }));
                    }
                  }
                }}
                className={`login-button ${
                  (!formSignUpData.continue &&
                    formSignUpData.validEmail &&
                    formSignUpData.validFirstName &&
                    formSignUpData.validLastName) ||
                  (formSignUpData.validEmail &&
                    formSignUpData.firstName &&
                    formSignUpData.validPassword &&
                    formSignUpData.password ===
                      formSignUpData.confirmPassword &&
                    formSignUpData.acceptPolicy)
                    ? "active"
                    : ""
                }`}
              >
                {formSignUpData.continue ? "FINALIZAR" : "CONTINUAR"}
              </button>
            </div>
          </>
        )}
        {privacyAlert && (
          <>
            <div
              className="login-close"
              onClick={() => {
                resetAndClose();
                setPrivacyAlert(false);
              }}
            ></div>
            <h6>¡GRACIAS POR TU REGISTRO!</h6>
            <p className="text-successful-register">
              Hola <span>{capitalizeString(dataAuth?.firstName ?? "")}</span>,
              somos concientes de que las suscripciones pueden crear una
              cantidad de correos innecesarios en nuestra bandeja. Es por eso
              que en JAVER sólo enviamos información que consideramos relevante
              para tu experiencia. Queremos estar cerca de ti y ayudarte a
              encontrar tu nuevo hogar.
            </p>
            <div className="divider-login"></div>
            <div className="javer-logo-login"></div>
          </>
        )}
        {pageLogin === "already_in" && !privacyAlert && (
          <>
            <div className="login-close" onClick={resetAndClose}></div>
            <h6>
              {`Hola ${capitalizeString(dataAuth?.firstName ?? "")}
              ${capitalizeString(dataAuth?.lastName ?? "")} ¡Ya has iniciado
              sesión!`}
            </h6>
            <button
              className="login-button active"
              onClick={() => {
                resetAndClose();
                logoutUser();
                setPageLogin("login");
                localStorage.setItem("welcome", true);
              }}
            >
              Cerrar sesión
            </button>
          </>
        )}
      </div>
    </div>
  );
}

export default LoginModal;
