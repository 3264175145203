import { useTranslation } from "react-i18next";
import { Card, CardContent, CardHeader } from "./ui/card";
import { getInitialDataDesarrollos } from "@/utils/api";
import { useQuery } from "react-query";
import { useAppContext } from "@/data/DataProvider";
import { useEffect, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "./ui/select";
import { Button } from "./ui/button";
import { sortPrices } from "@/models/sort_order";
import { toUrlCase } from "@/utils/common.utils";
import { Link } from "react-router-dom";

interface HomeSearchBarProps {
  selectedState?: string;
}

export function HomeSearchBar({ selectedState }: HomeSearchBarProps) {
  const { t } = useTranslation("translations");
  const { dataDesarrollos } = useAppContext();
  const [state, setState] = useState<string | undefined>();
  const [zone, setZone] = useState<string | undefined>();
  const [price, setPrice] = useState<number | undefined>();

  useEffect(() => {
    if (selectedState) {
      setState(selectedState);
    }
  }, [selectedState]);

  const { data: developments } = useQuery(
    "getInitialDataDesarrollos",
    async () => await getInitialDataDesarrollos(),
    {
      initialData: dataDesarrollos,
    }
  );

  return (
    <Card className="w-full 2xl:w-3/4 bg-card/80 rounded-lg">
      <CardHeader className="px-8 py-6">
        <h3>{t("findYourNewHome")}</h3>
      </CardHeader>
      <CardContent className="px-8 pb-6 text-lg">
        <p>{t("findYourNewHomeDescription")}</p>
        <div className="flex flex-col lg:flex-row gap-6 mt-6">
          <Select
            value={state ?? undefined}
            onValueChange={(value) => {
              setState(value);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder={t("selectState")} />
            </SelectTrigger>
            <SelectContent>
              {developments?.map(({ name, id }) => (
                <SelectItem key={id} value={name}>
                  {name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Select
            value={zone ?? undefined}
            onValueChange={(value) => {
              setZone(value);
            }}
          >
            <SelectTrigger>
              <SelectValue placeholder={t("selectZone")} />
            </SelectTrigger>
            <SelectContent>
              {developments
                ?.find((development) => development.name === state)
                ?.ciudades?.map(({ name, id }) => (
                  <SelectItem key={id} value={name}>
                    {name}
                  </SelectItem>
                ))}
            </SelectContent>
          </Select>
          <Select
            value={price?.toString()}
            onValueChange={(value) => {
              const priceIndex = Number(value);

              if (value === undefined) {
                setPrice(undefined);
              } else {
                setPrice(priceIndex);
              }
            }}
          >
            <SelectTrigger className="hidden">
              <SelectValue placeholder={t("selectPrice")} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem key="-1" value="-1">
                {t("allPrices")}
              </SelectItem>
              {sortPrices.map((zone, index) => (
                <SelectItem key={index} value={index.toString()}>
                  {new Intl.NumberFormat("es-MX", {
                    style: "currency",
                    currency: "MXN",
                  }).format(zone.min)}{" "}
                  -{" "}
                  {zone.max
                    ? new Intl.NumberFormat("es-MX", {
                        style: "currency",
                        currency: "MXN",
                      }).format(zone.max)
                    : "Más"}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Button asChild>
            <Link
              className={!state ? "pointer-events-none opacity-50" : ""}
              to={`/estados/${toUrlCase(state ?? "")}?zona=${toUrlCase(zone ?? "all")}&price=${price ?? "-1"}`}
            >
              {t("search")}
            </Link>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
