import { Text } from "../Text";
import BlogHeroScroll from "./BlogHeroScroll";
import { getBlogArticles } from "../../utils/api";
import "../../styles/tailwind.css";
import { useQuery } from "react-query";
import { Spinner } from "../../root-components/Spinner";

const Blog = () => {
  const { data, isLoading } = useQuery("blogs", getBlogArticles);

  if (isLoading) {
    return (
      <div className="grid place-items-center min-h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="blog flex flex-col font-roboto items-center justify-start mx-auto w-full">
      <div className="bg-white-A700 flex flex-col gap-[17px] items-center justify-start pb-[5px] px-[5px] w-full">
        <BlogHeroScroll
          articles={data
            ?.sort(
              (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
            )
            ?.slice(0, 10)}
          className="flex flex-col font-viga gap-7 items-center justify-start max-w-[1270px] mb-[30px] mx-auto md:px-5 w-full"
          blogTitleText={
            <Text className="md:text-5xl text-6xl text-gray-50 uppercase">
              <span className="text-gray-50 font-viga text-left font-normal">
                BLOG{" "}
              </span>
              <span className="text-primary font-viga text-left font-normal">
                JAVER
              </span>
            </Text>
          }
          description1={
            <Text className="mt-[27px] text-sm text-gray-50 w-full">
              <span className="text-gray-50 font-roboto text-left font-normal">
                El pasado 23 de junio se llevó a cabo la inauguración de nuestro
                primer vivero{" "}
              </span>
              <a
                href="javascript:"
                className="text-gray-50 font-roboto text-left font-normal underline"
              >
                Javer{" "}
              </a>
              <span className="text-gray-50 font-roboto text-left font-normal">
                en Jalisco, el quinto a nivel nacional.
              </span>
            </Text>
          }
          languagetext={
            <Text className="mt-[29px] text-sm text-gray-50 w-full">
              <span className="text-gray-50 font-roboto text-left font-normal">
                Año con año buscamos posicionarnos y dar a conocer los programas
                que hacen de Javer una{" "}
              </span>
              <a
                href="javascript:"
                className="text-gray-50 font-roboto text-left font-normal underline"
              >
                Empresa Responsable
              </a>
              <span className="text-gray-50 font-roboto text-left font-normal">
                .{" "}
              </span>
            </Text>
          }
        />
      </div>
    </div>
  );
};

export default Blog;
