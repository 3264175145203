import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";
import { Form, Formik } from "formik";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useQuery } from "react-query";
import { getEstados } from "@/utils/api";
import { SALESFORCE_ORG_ID, SALESFORCE_URL } from "@/constants";

interface HomeFormProps {
  className?: string;
}

export const HomeForm = ({ className }: HomeFormProps) => {
  const { t } = useTranslation("translations");
  const { data: states } = useQuery("states", getEstados);
  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("Este campo es requerido"),
    lastName: yup.string().required("Este campo es requerido"),
    email: yup
      .string()
      .email("Correo inválido")
      .required("Este campo es requerido"),
    phone: yup.string().required("Este campo es requerido"),
    message: yup.string().required("Este campo es requerido"),
    recaptcha: yup.string().required("Este campo es requerido"),
    acceptPolicy: yup
      .boolean()
      .required("Este campo es requerido")
      .isTrue("Este campo es requerido"),
  });

  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        state: undefined as string | undefined,
        message: "",
        recaptcha: undefined as string | undefined,
        acceptPolicy: false,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { resetForm }) => {
        await fetch(
          `${SALESFORCE_URL}&${new URLSearchParams({
            captcha_settings: `{"keyname":"CAPTCHAJAVER","fallback":"true","orgId":"${SALESFORCE_ORG_ID}","ts":"${JSON.stringify(new Date().getTime())}"}`,
            oid: SALESFORCE_ORG_ID,
            retURL: "https://www.javer.com.mx/gracias",
            "g-recaptcha-response": values.recaptcha ?? "",
            first_name: values.firstName,
            last_name: values.lastName,
            phone: values.phone,
            email: values.email,
            "00N3l00000Q7A5b": values.state ?? "",
            "00N3l00000Q7A57": "Sitio_Javer",
            "00N3l00000Q7A4n": "Medios Digitales",
            "00N3l00000Q7A5S": "Pagina web Javer",
            message: values.message,
            acceptPolicy: "on",
          }).toString()}`,
          {
            method: "POST",
            mode: "no-cors",
          }
        )
          .then((response) => {
            resetForm();
            navigate("/gracias");
          })
          .catch(() => {
            toast.error("Error en el envío de la información");
          });
      }}
      validateOnChange
      validateOnMount
    >
      {({ getFieldProps, setFieldValue, values, isValid, isSubmitting }) => (
        <Form
          className={`grid grid-cols-1 sm:grid-cols-2 gap-x-2 gap-y-4 w-full ${className}`}
        >
          <div>
            <Label htmlFor="firstName">{t("names")}</Label>
            <Input {...getFieldProps("firstName")} />
          </div>
          <div>
            <Label htmlFor="lastName">{t("lastNames")}</Label>
            <Input {...getFieldProps("lastName")} />
          </div>
          <div>
            <Label htmlFor="email">{t("email")}</Label>
            <Input {...getFieldProps("email")} />
          </div>
          <div>
            <Label htmlFor="phone">{t("phone")}</Label>
            <Input {...getFieldProps("phone")} />
          </div>
          <div className="col-span-1 sm:col-span-2">
            <Label htmlFor="state">{t("stateOfInterest")}</Label>
            <Select
              name="state"
              onValueChange={(value) => {
                setFieldValue("state", value);
              }}
            >
              <SelectTrigger>
                <SelectValue placeholder={t("selectOption")} />
              </SelectTrigger>
              <SelectContent>
                {states?.map((state) => (
                  <SelectItem key={state.id} value={state.name.toUpperCase()}>
                    {state.name}
                  </SelectItem>
                )) ?? []}
              </SelectContent>
            </Select>
          </div>
          <div className="col-span-1 sm:col-span-2">
            <Label htmlFor="message">{t("message")}</Label>
            <Textarea
              placeholder={t("messageExample")}
              {...getFieldProps("message")}
            />
          </div>
          <div className="col-span-1 sm:col-span-2 flex items-center gap-2">
            <Checkbox
              id="terms"
              checked={values.acceptPolicy}
              onCheckedChange={() => {
                setFieldValue("acceptPolicy", !values.acceptPolicy);
              }}
            />
            <label htmlFor="terms">
              <Link to="/avisos-de-privacidad" className="underline">
                {t("acceptPolicies")}
              </Link>
            </label>
          </div>
          <div className="col-span-1 sm:col-span-2">
            <ReCAPTCHA
              sitekey="6Lcg2vQZAAAAAHpqmf6Pj_-t-doxKr1iwLWfQuWQ"
              onChange={async (e) => {
                await setFieldValue("recaptcha", e);
              }}
            />
          </div>
          <div className="col-span-1 sm:col-span-2">
            <Button
              size="lg"
              type="submit"
              className="w-full md:w-fit"
              disabled={!isValid}
              isLoading={isSubmitting}
            >
              {t("submit")}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};
