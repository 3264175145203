export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const GOOGLE_MAPS_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;
export const S3_URL = process.env.REACT_APP_S3_URL;
export const PHONE_NUMBER =
  process.env.REACT_APP_PHONE_NUMBER ?? "81 1133-6614\n81 1133-6611";
export const SALESFORCE_URL_PREFIX =
  process.env.NODE_ENV !== "production" ? "test" : "webto";
export const SALESFORCE_ORG_ID =
  process.env.NODE_ENV !== "production" ? "00DEm000002Htjh" : "00Do0000000b6Io";
export const SALESFORCE_URL = `https://${SALESFORCE_URL_PREFIX}.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&orgId=${SALESFORCE_ORG_ID}`;
