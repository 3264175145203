import { RiArrowGoBackLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import "./error-pages.css";

function Error500Page() {
  return (
    <div className="error-page">
      <p className="error-title">¡Tenemos un problema!</p>
      <p className="error-description">
        Nuestros servidores no se encuentra disponibles
      </p>
      <img
        className="error-img"
        src="/images/error/500.png"
        alt="http 500 error"
      />
      <Link to="/" className="error-home-button">
        <RiArrowGoBackLine />
        <p>Regresar</p>
      </Link>
    </div>
  );
}

export default Error500Page;
